<template>
  <v-expansion-panels hover v-model="expansionPanels">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div>
          <v-icon>mdi-filter-variant</v-icon> {{ $t('purchasesFiltersTitle') }}
        </div>
        <v-spacer></v-spacer>
      </v-expansion-panel-header>

      <v-expansion-panel-content class="pb-2">
        <v-form ref="form" v-model="valid" @submit.prevent="updateFilters" lazy-validation>
          <v-row cols="12">
            <v-col cols="3">
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedMinDate"
                    :label="$t('purchasesFiltersStartDate')"
                    v-bind="attrs"
                    v-on="on"
                    prepend-icon="mdi-calendar-range"
                    :rules="[rules.required]"
                    lazy-validation
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.minDate"
                  no-title
                  scrollable
                  actions
                  locale="pt-br"
                >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="form.minDate = null">{{ $t('purchasesFiltersBtnClear') }}</v-btn>
                  </v-card-actions>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedMaxDate"
                    :label="$t('purchasesFiltersEndDate')"
                    v-bind="attrs"
                    v-on="on"
                    prepend-icon="mdi-calendar-check"
                    :rules="[rules.required]"
                    lazy-validation
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.maxDate"
                  no-title
                  scrollable
                  actions
                  locale="pt-br"
                >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="form.maxDate = null">{{ $t('purchasesFiltersBtnClear') }}</v-btn>
                  </v-card-actions>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3">
              <inventories-drop-down
                ref="inventoriesDropDown"
                @input="changeInventoryId"
                :multiple="false"  
              ></inventories-drop-down>
            </v-col>
            <v-col cols="3">
              <products-filter
                icon="shopping_cart"
                :value="form.productId"
                @input="changeProductId"
              ></products-filter>
            </v-col>
          </v-row>
          <v-row justify="end" class="pa-3">
            <v-btn text color="error" @click="clearFilters()" class="mr-2">
              <v-icon>clear</v-icon>{{ $t('purchasesFiltersBtnClear') }}
            </v-btn>
            
            <v-menu :close-on-content-click="false" v-model="menuOpen" offset-y bottom  v-if="importedPurchaseXmlEnable">
              <template #activator="{ on }">
                
                <v-btn v-on="on" color="primary" class="mx-2" >
                {{ $t('purchasesFiltersAddNote') }} <v-icon right>{{ menuOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>


              </template>
              <v-list>
                <v-list-item @click="dialogs.xml = true">
                  <v-icon left> mdi-xml</v-icon>{{ $t('purchasesFiltersXml') }}
                </v-list-item>
                <v-list-item @click="dialogs.manual = true">
                  <v-icon left> mdi-file-document-edit-outline</v-icon> {{ $t('purchasesFiltersManual') }}
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn color="success" type="submit" class="ml-2" :disabled="!valid">
              <v-icon>mdi-refresh</v-icon>{{ $t('purchasesFiltersRefresh') }}
            </v-btn>
          </v-row>

          <steps-dialog-master v-model="dialogs.xml" @close="closeXmlImported"></steps-dialog-master>
          <steps-dialog-manual v-model="dialogs.manual" @close="closeManualImported"></steps-dialog-manual>


        </v-form>
      </v-expansion-panel-content>
      
    </v-expansion-panel>

  </v-expansion-panels>


</template>

<script>
import InventoriesDropDown from '@/components/filters/InventoriesDropDown.vue';
import ProductsFilter from '@/components/filters/ProductsFilter.vue';
import moment from 'moment';
import StepsDialogMaster from './Componentes/XmlPurchase/stepsDialogMaster';
import StepsDialogManual from './Componentes/ManualPurchase/stepsDialogManual';
import agent from '@/api/agent';


export default {
  components: { 
    InventoriesDropDown,
    ProductsFilter,
    StepsDialogMaster,
    StepsDialogManual
  },
  mounted() {
    const maxDate = new Date();
    const minDate = new Date(maxDate.getTime());
    minDate.setDate(minDate.getDate() - 30);
    this.form.maxDate = moment(maxDate).format("YYYY-MM-DD");
    this.form.minDate = moment(minDate).format("YYYY-MM-DD");
    this.verifyImportedXmlEnable();
  },
  computed: {
    formattedMinDate() {
      return this.formatDate(this.form.minDate);
    },
    formattedMaxDate() {
      return this.formatDate(this.form.maxDate);
    },

  },
  data() {
    return {
      resetComponents: false,
      menuOpen: false,
      reset: {
        products: false,
        inventories: false
      },
      valid: false,
      form: {
        minDate: null,
        maxDate: null,
        inventoryId: null,
        productId: null,
      },
      expansionPanels: 0,
      rules: {
        required: (v) => !!v && v != this.$t('purchasesFiltersRulesDate') || this.$t('purchasesFiltersRulesRequired'),
      },
      dialogs: {
        xml: false,
        manual: false,
        },
      importedPurchaseXmlEnable : false,
    }
  },
  methods: {
    formatDate(date) {
      return date != null ? moment.utc(date).format("DD/MM/YYYY") : this.$t('purchasesFiltersRulesDate');
    },
    updateFilters() {
      if (this.$refs.form.validate()) {
        this.$emit('modify', this.form);
      }
    },
    clearFilters() {
      this.$refs.inventoriesDropDown.resetDropDown();
      this.form.productId = null;
    },
    changeInventoryId(inventoryId) {
      this.form.inventoryId = inventoryId;
    },
    changeProductId(productId) {
      this.form.productId = productId;
    },
    closeXmlImported(value) {
      this.dialogs.xml = value;
      this.$emit('modify', this.form);
    },
    closeManualImported(value) {
      this.dialogs.manual = value;
      this.$emit('modify', this.form);
    },
    verifyImportedXmlEnable(){
      const type = 'ImportedPurchaseXml';
      agent.Features.VerifyFeatureIsEnable(type).then((response) =>{
        this.importedPurchaseXmlEnable = response;
      }).catch(() => {
        this.importedPurchaseXmlEnable = false;
      });

    },

   
  }

}
</script>

<style>

</style>