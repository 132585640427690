import {PriceTable, PriceTableItem} from "@/types/PriceTable";
import axios, {AxiosResponse, AxiosInstance} from "axios";
import {PaginatedList} from "@/types/common/PaginatedList";
import {PurchaseSuggestion} from "@/types/PurchaseSuggestions";
import {PagePointOfSaleConnectivity, PointOfSaleConnectivityDashboard} from "@/types/Connectivity";
import {AverageTicket, PosMostSales, TotalPurchase, TotalBilling, RevenueDto, SalesSummary, MostSoldProduct} from "@/types/SalesDashboard";
import {Inventories, LastSupplies} from "@/types/Inventory";
import {ProductPosLosses} from "@/types/OperationalReports";
import {PaginatedReportSales} from "@/types/ReportSales";
import {SlugResponse} from "@/types/MobileActivation";
import {Image} from "@/types/Image";
import {TenantGroup} from "@/types/Customers";
import { Invoice, DigitalCertificateRequest, Company } from "@/types/Invoices";
import { TaxGroupProducts, UsaTaxGroup } from "@/types/Taxing";
import { PageFranchise } from "@/types/Franchise";
import { BillingByResidents, MarketAverageTicket, ProductByRegion, ProductSalesInfo } from "@/types/DashboardMarketIntelligence";
import { MarketIntelUserAccess, MarketIntelPrivacyPolicy } from "@/types/MarketIntelUserAccess";
import { TenantFeedbackDto } from "@/types/TenantFeedback";
import { InvoicePurchase} from "@/types/Purchases";
import { Vue } from "vue-property-decorator";
import {Bills, CheckIfCustomerIsDebtFreeDto} from "@/types/Bill";
import {unlockResult} from "@/types/UnlockResult";
import {ProductUnusedDto} from "@/types/PlanogramTypes";
import { RuptureProduct } from "@/types/Rupture";
import { AbcCurve } from "@/types/AbcCurve";
import {PaymentMethod } from "@/types/PaymentMethod";
import { SalesByWeekday } from "@/types/SalesByWeekday";
import { NpsFeedbackRequest } from "@/types/NpsFeedbackRequest";
import {CashlessOperations} from '@/types/Cashless';
import { PaginatedQuery } from "@/types/common/PaginatedQuery";
import {ChangeOwnershipNewRequest, PointOfSaleSimplifiedDto, QuantityOfEnvironmentByCnpjResponse, ChangeOwnershipDto, ApprovedOwnership} from "@/types/ChangeOwnershipType";
import { ConsolidatedCatalog, CustomerFullInfoDto, AcquisitionOrderRequestDto } from "@/types/MidasProductsOrders";
import { Users } from "@/types/Users";
import { ContractBillInfoDto } from "@/types/Contract";
import { TaxGroupProduct } from '@/types/Taxing';
import {Group, Tenant} from "@/types/Tenant";
import {PickListExcelRequest} from "@/types/PickLists";
import { Product } from '@/types/ProductsTypes';
import { DiscountProduct } from '@/types/DiscountTypes';
import { PointOfSale } from '@/types/PointOfSale';


const responseBody = (response: AxiosResponse) => response.data;
const responseBlob = (response: AxiosResponse) => response;

// Used to emulate long loadings to test loading components, place holders and experience with async components.
//const sleep = (ms: number) => (response: AxiosResponse) => new Promise<AxiosResponse>((resolve) => setTimeout(() => resolve(response), ms));
//let time = 2000;
// Example: get: (url: string, params?: {}) => axios.get(url, {params: params}).then(sleep(time)).then(responseBody),

const requests = {
  get: (url: string, params?: {}) => axios.get(url, {params: params}).then(responseBody),
  getBlob: (url: string, params?: {}) => axios.get(url, {params: params, responseType: 'blob'}).then(responseBlob),
  post: (url: string, body?: {}, params?: {}) => axios.post(url, body, {params: params}).then(responseBody),
  put: (url: string, body?: {}, params?: {}) => axios.put(url, body, {params: params}).then(responseBody),
  patch: (url: string, body?: {} | null, params?: {}) => axios.patch(url, body, {params: params}).then(responseBody),
  delete: (url: string, params?: {}) => axios.delete(url, {params: params}).then(responseBody),
};

const PriceTables = {
  list: (params: {}): Promise<PaginatedList<PriceTable>> => requests.get("api/PriceTables", params),
  details: (id: number): Promise<PriceTable> => requests.get(`api/PriceTables/${id}`),
  changePriceTableName: (id: number, name: string) => requests.patch(`api/PriceTables/${id}/name`, {name: name}),
  changePriceTableType: (id: number, type: string) => requests.patch(`api/PriceTables/${id}/type`, {type: type}),
  delete: (id: number) => requests.delete(`api/PriceTables/${id}`),
  listItems: (id: number, params: {}): Promise<PaginatedList<PriceTableItem>> => requests.get(`api/PriceTables/${id}/items`, params),
  createItem: (id: number, form: any) => requests.post(`api/PriceTables/${id}/items/${form.productId}`, form),
  updateItem: (id: number, item: PriceTableItem) => requests.put(`api/PriceTables/${id}/items/${item.productId}`, item),
  updateMarkupByCategory: (id: number, categoryId: number, markup: number) => requests.put(`api/PriceTables/${id}/category/${categoryId}/markup/${markup}`),
  deleteItem: (id: number, productId: number) => requests.delete(`api/PriceTables/${id}/items/${productId}`),
  syncDistributionCenter: (id: number, inventoryId: number, params: {}) => requests.put(`api/PriceTables/${id}/distribution-center/${inventoryId}`, {}, params),
  bulkApply: (id: number, params: {}) => requests.post(`api/PriceTables/bulk/apply/${id}`, {}, params),
};

const PurchaseSuggestions = {
  list: (params: {}): Promise<PurchaseSuggestion[]> => requests.get("api/PurchaseSuggestions", params),
};

const Connectivity = {
  list: (params: {}): Promise<PagePointOfSaleConnectivity> => requests.get("api/PointsOfSaleConnectivity/midas-telemetry-connectivity", params),
  excel: (params: {}) => requests.get(`api/PointsOfSaleConnectivity/midas-telemetry-excel`, params),
  percentList: (): Promise<PointOfSaleConnectivityDashboard> => requests.get("api/PointsOfSaleConnectivity/pos-quantity-connected"),
};

const PickLists = {
  distributeProduct: (id: number) => requests.put(`api/PickLists/DistributeProduct/${id}`),
  excel: (params: PickListExcelRequest) => requests.get(`api/PickLists/dynamic-excel`, params),
};

const Purchases = {
  list: (params: {}) => requests.get("api/web/importedPurchases", params),
  details: (id: number, params: {}) => requests.get(`api/web/importedPurchases/${id}`, params),
  delete: (id: number) => requests.delete(`api/web/importedPurchases/Undo/${id}`),
  readXml: (params: {}) : Promise<InvoicePurchase>  => requests.get(`api/imported-purchases/xml-converter`, params),
  createPurchase: (body: {})  => requests.post(`api/imported-purchases`, body),
};

export const SalesDashboard = {
  getSalesTotal: () : Promise<TotalPurchase> => requests.get("api/salesDashboard/total-sales"),
  getAverageTicket: (params?: {}) : Promise<AverageTicket> => requests.get("api/salesDashboard/average-ticket", params),
  getMixProduct: (params: {}) : Promise<number> => requests.get("api/salesDashboard/product-mix", params),
  getPosMostSales: (params?: {}) : Promise<PosMostSales[]> => requests.get("api/salesDashboard/pos-most-sales", params),
  getTotalBilling: (params?: {}) : Promise<TotalBilling> => requests.get("api/salesDashboard/total-billing", params),
  getPosMinoritySales: (params?: {}) : Promise<PosMostSales[]> => requests.get("api/salesDashboard/pos-minority-sales", params),
  getAbcCurve: (params: {}): Promise<AbcCurve> => requests.get('api/dashboard/abc-curve', params),
  getMostSoldCategories: (params: {}) => requests.get('api/Dashboard/sales-by-category', params),
  getSalesByWeekday: (params: {}): Promise<SalesByWeekday> => requests.get('api/dashboard/sales-by-weekday', params),
  getRevenue: (params: {}): Promise<RevenueDto[]> => requests.get('api/salesDashboard/revenue-month', params),
  getSalesSummary: (params: {}): Promise<SalesSummary> => requests.get('api/salesDashboard/sales-summary', params),
  getMostProductsSold: (params:{}): Promise<MostSoldProduct[]> => requests.get("api/salesDashboard/most-sold-products", params),
};

export const Inventory = {
  getLastSupplies: (params?: {}): Promise<LastSupplies[]> => requests.get(`api/Inventory/last-supplies`, params),
  getInventories: (params:{}): Promise<Inventories[]> => requests.get(`api/web/inventory/`, params),
  RemoveInventoryItemsUseless: (id: number) : Promise<number> => requests.delete(`api/web/inventory/center/${id}/remove-items-useless`),
  AddInventoryItemsDistributionCenter: (id: number) : Promise<number> => requests.post(`api/web/inventory/center/${id}/add-missing-items`),
  AddSpecificItemDistributionCenter: (id: number, params: {}) => requests.post(`api/web/inventory/center/${id}/add-specific-items`, {}, params),
  RemoveSpecificItemDistributionCenter: (id: number, productId: Number) => requests.delete(`api/web/inventory/center/${id}/remove-specific-items/${productId}`),
  getExcelInventoryTransactions:(params: {}) => requests.get(`api/web/inventory/transactions/excel`, params),
};

const OperationalReports = {
  getProductPosLosses: (params?: {}): Promise<ProductPosLosses> => requests.get(`api/OperationalReports/losses-by-product-and-pos`, params),
};

const reportSales = {
  getMostProductsSold: (params:{}): Promise<PaginatedReportSales> => requests.get("api/report/PaginatedProducts", params),
  getSalesByPaymentMethod: (params:{}): Promise<PaymentMethod[]> => requests.get("api/report/SalesByPaymentMethod", params),
}

const Images = {
  listSuggestionLogos: (): Promise<Image[]> => requests.get("api/images/logo/suggestions"),
  bulkLogoImageChange: (body: {}) => requests.put(`api/Images/logo/bulk`, body),
  deleteLogoFromRepository: (body: {}) => requests.post(`api/Images/logo/repository`, body),
}

const Tenants = {
  getTenants: (): Promise<Tenant[]> => requests.get(`api/Tenants`),
  getTenantGroups: (tenantId: number): Promise<Group[]> => requests.get(`api/Tenants/${tenantId}/groups`),
  createGroup: (body: {}) => requests.post(`api/Tenants/groups`, body),
  getNextAvailableGroupId: (): Promise<number> => requests.get(`api/Tenants/groups/next-available-id`),
  getTenantGroupsSimpleInfo: (): Promise<TenantGroup[]> => requests.get(`api/Tenants/groups/simple-info`),
}

const Taxings = {
  removeUnrelatedToProducts: () => requests.delete(`api/Taxings/remove-unrelated`),
  pointOfSaleNfcValidation: (id: number) => requests.post(`api/Taxings/invoice/validation/${id}/point-of-sale`),
  TaxGroupProducts: (id: number, params: {}): Promise<TaxGroupProducts[]> => requests.get(`api/Taxings/${id}/Products`, params),
  removeUnrelatedToProductsUsa: () => requests.delete('api/UsaTaxings/remove-unrelated'),
  getUsaTaxes: (params?: {}): Promise<PaginatedList<UsaTaxGroup>> => requests.get('api/UsaTaxings', params),
  deleteUsaTax: (id: number) => requests.delete('api/UsaTaxings/' + id),
  postUsaTax: (body: {}): Promise<UsaTaxGroup> => requests.post('api/UsaTaxings', body),
  putUsaTax: (id:number, body: {}): Promise<UsaTaxGroup> => requests.put(`api/UsaTaxings/${id}`, body),
  listTaxGroupProducts: (id: number, params: {}): Promise<PaginatedList<TaxGroupProduct>> => requests.get(`api/taxings/${id}/products-tax-group`, params),
  mapProductTaxGroup: (id: number, params: {}) => requests.patch(`api/taxings/${id}/products`, null, params),
  addProductToTaxGroup: (id: number, form: {productId: number, isPrincipal: boolean}) => requests.post(`api/taxings/${id}/products`, form),
  removeProductFromTaxGroup: (id: number, params: {productId: number}) => requests.delete(`api/taxings/${id}/products`, params),
}

const Ruptures = {
  getRupturesOfCollectionOfItems: (params: {}): Promise<RuptureProduct[]> => requests.get('api/Ruptures/products-simple-ruptures', params)
}

const intelAxios = axios.create({ baseURL: process.env.VUE_APP_INTEL_API_URL })
const intelRequests = {
  get: (url: string, params?: {}) => setAuthHeaders(intelAxios).get(url, {params: params}).then(responseBody),
  getBlob: (url: string, params?: {}) => setAuthHeaders(intelAxios).get(url, {params: params, responseType: 'blob'}).then(responseBlob),
  post: (url: string, body?: {}, params?: {}) => setAuthHeaders(intelAxios).post(url, body, {params: params}).then(responseBody),
  put: (url: string, body?: {}, params?: {}) => setAuthHeaders(intelAxios).put(url, body, {params: params}).then(responseBody),
  patch: (url: string, body: {}) => setAuthHeaders(intelAxios).patch(url, body).then(responseBody),
  delete: (url: string) => setAuthHeaders(intelAxios).delete(url).then(responseBody),
};

function setAuthHeaders(axios: AxiosInstance) {
  const auth = Vue.prototype.$auth.token();
  axios.defaults.headers['Authorization'] = `Bearer ${auth}`;
  return axios;
}

export const MarketIntelligenceAccess = {
  getPrivacyPolicy: (): Promise<MarketIntelPrivacyPolicy> => intelRequests.get('intel/user-access/privacy-policy'),
  getUserAccess: (params: {}): Promise<MarketIntelUserAccess> => intelRequests.get('intel/user-access', params),
  putUserAuthorization: (params: {}) => intelRequests.put('intel/user-access/authorize-user', params),
  putAllPosRegistered: (params: {}) => intelRequests.put('intel/user-access/all-pos-registered', params),
  getIncompletePos: (): Promise<number[]> => requests.get('api/PointsOfSale/incompletePos')
}

export const Invoices = {
  getInvoicesPaginated: (params: {}): Promise<PaginatedList<Invoice>> => requests.get('api/invoicesWeb', params ),
  postDigitalCertificate: (body: DigitalCertificateRequest) => requests.post(`api/invoicesWeb/certificate`, body),
  getCompanies: (): Promise<Company[]> => requests.get(`api/invoicesWeb/companies`),
  getExcelXml: (params: {}) => requests.get(`api/invoicesWeb/Excel`, params),
}

export const MobileActivation = {
  getSlug: (): Promise<SlugResponse> => requests.get("api/MobileActivation/slug")
}

export const Products = {
  excel: (params: {}) => requests.get(`api/Products/excel`, params),
  productByBarcode: (params: {}) =>
    requests.get("api/Products/product-by-barcode", params),
  productById: (id: number) => requests.get(`api/Products/${id}`),
  withTaxGroupsMapped: (params: {}): Promise<PaginatedList<TaxGroupProduct>> => requests.get(`api/products/with-tax-groups-mapped`, params),
  activeProductById: (id: number) => requests.put(`api/Products/restore-product/${id}`),
  simpleInfo: (params: PaginatedQuery) : Promise<PaginatedList<Product>> => requests.get(`api/products/productBaseSimpleInfo`, params),
};

export const FranchiseEnvironment  = {
  getFranchise: (params: {}) : Promise<PageFranchise> => requests.get(`api/FranchiseEnvironments/environments`, params),
  getFranchiseeAmbient: (groupId: number) => requests.get(`api/FranchiseEnvironments/environment/${groupId}`),
  getFranchiseeAmbientExcel: (params: {}) => requests.get(`api/FranchiseEnvironments/environments/excel`, params),
}

const DashboradMarketIntelligence = {
  getBillingByResidents: (params: {}): Promise<BillingByResidents[]> => intelRequests.get(`intel/Reports/billing-by-residents`, params),
  getRegionSalesReport: (params: {}): Promise<MarketAverageTicket> => intelRequests.get(`intel/Reports/region-sales`, params),
  getProductsByRegion: (params: {}): Promise<ProductByRegion[]> => intelRequests.get("intel/Reports/products-by-region", params),
  getProductSalesInfo: (params: {}): Promise<ProductSalesInfo> => intelRequests.get('intel/Reports/product-sales-info', params)
}

export const TenantFeedback = {
  getTenantsFeedback: (params: {}): Promise<PaginatedList<TenantFeedbackDto>> => requests.get("api/TenantFeedback", params),
}

export const NpsFeedbacks = {
  postNpsFeedback: (form: NpsFeedbackRequest) => requests.post("api/NpsFeedback", form),
}

const Bills = {
  list: (params: {}): Promise<Bills> => requests.get("api/BillingOmie/bills-information", params),
  billLink: (billId: number) => requests.get(`api/BillingOmie/bills/${billId}`),
  serviceOrderLink: (serviceOrderCode: number) => requests.get(`api/BillingOmie/service-orders/${serviceOrderCode}`),
  getCustomerFinancialIssues: (): Promise<Bills> => requests.get("api/BillingOmie/customer-financial-issues"),
  postBillsExemption: (): Promise<Date> => requests.post("api/BillingOmie/bills-exemption"),
  checkIfCustomerIsDebtFree: (tenantId: number, groupId?: number): Promise<CheckIfCustomerIsDebtFreeDto> => requests.get(`api/BillingOmie/CheckIfCustomerIsDebtFree/${tenantId}/${groupId}`),
  startTrustPeriod: (email: string, hasRequestedTrustPeriod: boolean) => requests.post(`api/Bills/trust-period/${email}/${hasRequestedTrustPeriod}`)
}

export const Contracts = {
  getMidasContract: (tenantId: number, groupId: number | null, referenceDateString: string):
    Promise<ContractBillInfoDto> => requests.get(`api/BillingOmie/contractbill-midas/${tenantId}/${groupId}/${referenceDateString}`)
}

export const BuildingManagerReports = {
  getBuildingManagerSalesReport: (params: {}) => requests.get("api/BuildingManager/sales", params),
  getBuildingManagerExcelReport: (params: {}) => requests.get("api/BuildingManager/sales/excel", params),
  getCategorySalesByPos: (posId: number, params: {}) => requests.get(`api/BuildingManager/sales-by-category/${posId}`, params)
}

const AccessControl = {
  getUnlockResults : (): Promise<unlockResult[]> => requests.get("api/AccessControl/unlock-operation-result"),
}

const Features = {
  VerifyFeatureIsEnable : (type : string): Promise<Boolean> => requests.get(`api/Features/Enabled/${type}`)
}

const Planogram = {
  getProductsUnused : (params : {}): Promise<PaginatedList<ProductUnusedDto>> => requests.get(`api/PlanogramEntries/get-unused-product`, params),
  createPlanogramWithoutUnusedProducts: (params : {}) => requests.post(`api/Planograms/create-planogram-unused-product`, params)
}

export const UsersFeedbacks = {
  getExcelReport: (params: {}) => requests.get(`api/UserFeedbackWeb/excel`, params),
}

export const CashlessTransactions = {
  getCashlessOperations:(params : {}):Promise<PaginatedList<CashlessOperations>> => requests.get("api/cashless-transaction", params),
  getExcel: (params: {}) => requests.get(`api/cashless-transaction/cashless-transactions/excel`, params),
}

export const ChangeOwnership = {
  getChangeRequests: (params: PaginatedQuery): Promise<PaginatedList<ChangeOwnershipDto>> => requests.get(`api/ChangeOwnershipRequest`, params),
}

export const AcquisitionOrders = {
  getConsolidatedCatalog: (): Promise<ConsolidatedCatalog> => requests.get("api/AcquisitionOrders/catalog"),
  getEnvironmentContractInfo: (params: {}): Promise<CustomerFullInfoDto> => requests.get("api/AcquisitionOrders/environment-info", params),
  postAcquisitionOrder: (body: AcquisitionOrderRequestDto) => requests.post("api/AcquisitionOrders", body),
}

export const ChangeOwnershipRequest = {
  quantityOfEnvironmentByCnpj: (cnpj: string): Promise<QuantityOfEnvironmentByCnpjResponse> =>
    requests.get(`api/ChangeOwnershipRequest/quantityOfEnvironmentByCnpj/${cnpj}`),

  getAllPointsOfSale: (groupId: number): Promise<PointOfSaleSimplifiedDto[]> =>
    requests.get(`api/ChangeOwnershipRequest/getAllPointsOfSale/${groupId}`, ),

  createChangeOwnershipRequest: (body: ChangeOwnershipNewRequest) => requests.post(`api/ChangeOwnershipRequest/createChangeOwnershipRequest`, body),

  approvalChangeOwnershipRequest: (body: ApprovedOwnership) => requests.post(`api/ChangeOwnershipRequest/approve`, body),

  hasPendingStatus: (): Promise<Boolean> => requests.get(`api/ChangeOwnershipRequest/HasPendingStatus`),
}

export const User ={
  getUsersEmail: (): Promise<Users[]> => requests.get('account/GetUsersWithEmail'),
  getNewTenantToken: (tenantId: number, groupId: number | null): Promise<string> =>
    requests.get(`account/NewTenantToken`, { tenantId: tenantId, groupId: groupId }),
}
export const MissingProducts = {
  getMissingProducts: (params: {}): Promise<PaginatedList<any>> => requests.get("api/web/MissingProducts", params),
  getExcel: (params: {}): Promise<AxiosResponse> => requests.getBlob("api/web/MissingProducts/excel", params),

};

export const Discounts = {
  productDiscounts: (params: {}): Promise<PaginatedList<DiscountProduct>> => requests.get(`api/discountproducts/paginated`, params),
  create: (body: {}) => requests.post(`api/discountProducts`, body),
  update: (id: number, body: {}) => requests.put(`api/discountProducts/${id}`, body),
  delete: (id: number) => requests.delete(`api/discountProducts/${id}`),
};

export const PointsOfSale = {
  all: (): Promise<PointOfSale[]> => requests.get(`api/pointsOfSale`)
};

export default {
  PriceTables,
  PurchaseSuggestions,
  Connectivity,
  PickLists,
  Purchases,
  SalesDashboard,
  Inventory,
  reportSales,
  Bills,
  Images,
  Tenants,
  Taxings,
  MobileActivation,
  Products,
  Invoices,
  FranchiseEnvironment,
  DashboradMarketIntelligence,
  AccessControl,
  Features,
  Planogram,
  Ruptures,
  OperationalReports,
  AcquisitionOrders,
  CashlessTransactions,
  ChangeOwnership,
  ChangeOwnershipRequest,
  User,
  Discounts,
  PointsOfSale
};
