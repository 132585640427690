import i18n  from "@/locales/i18n"; // Ajuste o caminho conforme necessário

const t = (key: string) => i18n.t(key);

export type PickListExcelRequest = {
    ids: number[];
    pickListColumns: string[];
    pickListItemColumns: string[];
    viewZeroQuantity: boolean;
}

export const pickListColumnsFixed = [
    { text: t('pickListPointOfSaleNameColumn'), value: "PointOfSaleName" },
    { text: t('pickListIdColumn'), value: "Id"},
    { text: t('pickListDateCreateColumn'), value: "DateCreated" },
    { text: t('pickListDateUpdateColumn'), value: "DateUpdated"},
    { text: t('pickListStatusColumn'), value: "Status" },
    { text: t('pickListPlanogramIdColumn'), value: "PlanogramId"},
  ];

  export const pickListColumnsOptional = [
    { text: t('pickListMachineModelColumn'), value: "PointOfSaleMachineMachineModelName" },
    { text: t('pickListManufactureMachineColumn'), value: "PointOfSaleMachineMachineManufacturerName"},
  ];

export const pickListItemsColumnsFixed = [
    { text: t('productIdColumn'), value: "ProductId" },
    { text: t('productBarCodeColumn'), value: "ProductBarCode"},
    { text: t('productDescriptionColumn'), value: "ProductDescription" },
    { text: t('productQuantityColumn'), value: "Quantity" },
  ];

  export const pickListItemsColumnsOptional = [
    { text: t('productCodeColumn'), value: "ProductCode"},
    { text: t('productCategoryNameColumn'), value: "ProductCategoryName"},
    { text: t('productCurrentQuantityColumn'), value: "CurrentQuantity"},
    { text: t('productQuantityToSupplyColumn'), value: "QuantityToSupply" },
    { text: t('productMinimumQuantityColumn'), value: "MinimumQuantity"}
  ];