<template>
  <div>
    <news v-if="isBrazil" :additionalAlertList="additionalAlertList"></news>
    <v-sheet height="100vh" :color="backgroundGray">
      <v-row class="px-4" justify="space-between">
        <v-col cols="auto">
          <v-card class="mt-4" style="overflow-x: hidden; border-radius: 15px" flat outlined>
            <v-tabs v-model="activeTab" hide-slider>
              <v-tab :key="0" v-if="userIsManagerHierarchy">{{ $t('tabStrategicIndicators') }}</v-tab>
              <v-tab :key="1" v-if="userIsOperationHierarchy">{{ $t('tabOperationalIndicators') }}</v-tab>
              <v-tab :key="2" v-if="userIsManagerHierarchy">{{ $t('tabMarketInformations') }}</v-tab>
            </v-tabs>
          </v-card>
        </v-col>

        <v-col cols="auto" v-if="userIsOperationHierarchy">
          <v-row no-gutters id="filters-row" class="pt-md-2">
            <v-col cols="auto">
              <date-filter
                v-model="form.minDate"
                @input="filtersUpdated()"
                @dateInterval="(value) => dateInterval = value"
                :rounded="true"
                :soloInverted="false"
                icon="mdi-calendar-range"
                style="max-width: 220px"
              ></date-filter>
            </v-col>
            <v-col cols="auto">
              <pos-selector
                v-model="form.pointOfSaleId"
                v-if="activeTab != 2"
                @input="filtersUpdated()"
                :rounded="true"
                :shorten-chip-text="true"
                :label="$t('pointsOfSale')"
                style="width: 400px"
                :counter="5"
              ></pos-selector>
              <v-autocomplete
                v-else
                @input="filtersUpdated()"
                v-model="form.state"
                :items="states"
                item-text="name"
                item-value="value"
                :label="$t('topFiveState')"
                rounded
                clearable
                id="state-selector"
                style="width: 150px"
              ></v-autocomplete>
            </v-col>
            <v-col cols="auto" v-if="activeTab == 2">
              <LocalTypeSelector
                v-model="form.posType"
                :disabled="false"
                :rounded="true"
                :prependIcon="null"
                :showHelp="false"
                :clearable="true"
                style="width: 200px"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-tabs-items v-model="activeTab">
        <v-tab-item :key="0" class="px-4 pb-16" v-if="userIsManagerHierarchy">
          <StrategicIndicators
            :mustUpdate="mustUpdate.strategic"
            :form="form"
            :activeTab="activeTab"
            :selectedDateInterval="dateInterval"
            @updated="mustUpdate.strategic = false;"
          />
        </v-tab-item>
        <v-tab-item :key="1" class="px-4 pb-16" v-if="userIsOperationHierarchy">
          <OperationalIndicators
            :mustUpdate="mustUpdate.operational"
            :activeTab="activeTab"
            @updated="mustUpdate.operational = false;"
            :form="form"
          />
        </v-tab-item>
        <v-tab-item :key="2" class="px-4 pb-16" v-if="userIsManagerHierarchy && isBrazil">
          <MarketInformations
            :mustUpdate="mustUpdate.marketInfo"
            :activeTab="activeTab"
            @updated="mustUpdate.marketInfo = false;"
            :form="form"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
    <customer-financial-issues-dialog
     @customerHasBills="onCustomerHasBills"/>
  </div>
</template>

<script>
import { MANAGER_HIERARCHY, OPERATIONAL_DASHBOARD_HIERARCHY} from '@/constants/Roles';
import StrategicIndicators from './StrategicIndicators.vue';
import OperationalIndicators from './OperationalIndicators.vue';
import MarketInformations from './MarketInformations.vue';
import DateIntervalIntelCardFilter from '../dashboard/MarketIntelComponents/DateIntervalIntelCardFilter.vue';
import PointOfSalesMultipleSelector from '@/components/filters/PointOfSalesMultipleSelector.vue';
import BrazilStates from "@/components/BrazilStates";
import LocalTypeSelector from '@/components/filters/LocalTypeSelector.vue';
import CustomerFinancialIssuesDialog from "@/views/dashboard/billingComponents/CustomerFinancialIssuesDialog.vue";
import News from '@/components/News.vue';
import moment from "moment";

export default {
  data() {
    const inicialTab = MANAGER_HIERARCHY.some(role => this.$auth.check(role)) ? 0 : 1;
    return {
      additionalAlertList: [],
      activeTab: inicialTab,
      mustUpdate: {
        strategic: true,
        operational: true,
        marketInfo: true
      },
      form: {
        minDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
        maxDate: moment().format('YYYY-MM-DD'),
        state: null,
        pointOfSaleId: [],
        posType: null
      },
      dateInterval: 30,
      states: BrazilStates.states,
      backgroundGray: this.$vuetify.theme.defaults.dashboard.backgroundGray,
      cardSubtitleGray: this.$vuetify.theme.defaults.dashboard.cardSubtitleGray
    };
  },
  components: {
    StrategicIndicators,
    OperationalIndicators,
    MarketInformations,
    LocalTypeSelector,
    News,
    CustomerFinancialIssuesDialog,
    'date-filter': DateIntervalIntelCardFilter,
    'pos-selector': PointOfSalesMultipleSelector
  },
  computed: {
    userIsManagerHierarchy() {
      return MANAGER_HIERARCHY.some(role => this.$auth.check(role))
    },
    userIsOperationHierarchy() {
      return OPERATIONAL_DASHBOARD_HIERARCHY.some(role => this.$auth.check(role))
    },
    isBrazil() {
      return this.$auth.user().tenantCountry === 'BR';
    },
  },
  methods: {
    filtersUpdated() {
      if (this.form.pointOfSaleId.length > 5) {
        this.mustUpdate.strategic = false
        this.mustUpdate.operational = false
        this.mustUpdate.marketInfo = false
        return ;
      }
      this.mustUpdate.strategic = true
      this.mustUpdate.operational = true
      this.mustUpdate.marketInfo = true
    },
    onCustomerHasBills(messages) {
      this.additionalAlertList = messages;
    },
  }
};
</script>

<style scoped>
* {
  background-color:var(--background-gray);
}

.v-tab {
  text-transform: none;
  background-color: white;
  color: #B1B1B1;
  font-size: 14px;
}

.v-tab--active {
  background-color: var(--deep-blue);
  color: white;
  font-weight: 700;
  font-size: 16px;
}

.v-main__wrap {
  background-color: var(--card-subtitle-gray)
}

#filters-row >>> * {
  color: var(--deep-blue);
  font-weight: 450;
}


* /deep/ .v-card {
  border-radius: 10px;
}

* /deep/ .v-card__title {
  color: var(--card-title-blue);
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

* /deep/ .v-card__subtitle {
  color: var(--card-subtitle-gray) !important;
  font-size: 12px;
  line-height: 15px;
}
</style>
