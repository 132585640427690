<template>
  <v-card>
    <v-card-title>
      <v-row class="px-4">
        <v-col cols="auto">
          <v-form @submit.prevent="getViewFranchisee">
            <v-text-field 
              v-model="search.text" 
              append-icon="search" 
              class="mr-2 pt-0" 
              clearable 
              dense 
              filled
              hide-details 
              label="Pesquisar" 
              rounded 
              single-line 
              @click:clear="search.text = ''; getViewFranchisee();"
              @click:append="options.page = 1; getViewFranchisee()" 
              @input="options.page = 1"
            ></v-text-field>
          </v-form>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <franchisee-dialog 
            v-model="show">
          </franchisee-dialog>
        </v-col>
      </v-row>
    </v-card-title>
    <div>
      <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
      <v-data-table
        :items="franchiseUp.items"
        :headers="headers"
        item-value="name"
        :options.sync="options"
        :server-items-length="totalItems" 
        no-data-text="Nenhum resultado encontrado"
        :footer-props="{ itemsPerPageOptions: [10, 20, 30] }">
        <template v-slot:[`item.cnpj`]="{ item }">
          {{ formatCnpj(item.cnpj) }}
        </template>
        <template v-slot:[`item.billingMonth`]="{ item }">
          {{ $currencyFormatter.format(item.billingMonth) }}
        </template>
        <template v-slot:[`item.averageNps`]="{ item }">
          <v-icon color="warning" style="font-size: 16px;">mdi-star</v-icon>
          {{ item.averageNps }}/5
        </template>
        <template v-slot:[`item.toView`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <router-link :to="`/${$t('path.nameDashboard')}`">
                <v-btn icon v-on="on" @click="redirectToExternalSystem(item.groupId)">
                  <v-icon color="primary">mdi-eye-outline</v-icon>
                </v-btn>
              </router-link>
            </template>
            <span>Visualizar Ambiente</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <div style="margin-bottom: 76px;"></div>
    </div>
    <feedback ref="feedback"></feedback>
  </v-card>
</template>

<script>
import Vue from 'vue';
import { formatCnpj } from '@/utility/TextFormatting';
import agent from '@/api/agent';
import { shouldRefreshData, toApiPagination } from '@/components/TableOptionsComparator';
import FranchiseeDialog from '@/views/franchisee/components/FranchiseDialog.vue';


export default Vue.extend({
  components: {
    FranchiseeDialog,
  },
  data() {
    return {
      search: {
        text: '' || null,
        valid: true
      },
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['groupId'],
        sortDesc: [false],
        mustSort: true
      },
      headers: [
        { text: 'ID Sub Ambiente', align: 'left', sortable: true, value: 'groupId' },
        { text: 'Sub Ambiente', align: 'left', sortable: true, value: 'groupName' },
        { text: 'CNPJ', align: 'left', sortable: true, value: 'cnpj' },
        { text: 'Faturamento (últimos 30 dias)', align: 'left', sortable: false, value: 'billingMonth' },
        { text: 'Qtd. de máquinas', align: 'left', sortable: false, value: 'machineQuantity' },
        { text: 'Qtd. de lojas', align: 'left', sortable: false, value: 'localQuantity' },
        { text: 'Média de NPS', align: 'left', sortable: false, value: 'averageNps' },
        { text: 'Ações', align: 'left', sortable: false, value: 'toView' },
      ],
      franchiseUp: {},
      loading: true,
    }
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getViewFranchisee();
        }
      },
    },
  },
  methods: {
    getViewFranchisee() {
      this.loading = true
      let params = toApiPagination(this.options, this.search.text);
      agent.FranchiseEnvironment.getFranchise(params)
        .then((response) => {
          this.franchiseUp = response
          this.totalItems = response.totalItems

          if (response.items.length === 0 || response.totalItems === 0) {
            this.franchiseUp.items = [];
          }
          this.loading = false
        }).catch(
          (error) => this.$refs.feedback.handleError('Erro ao buscar grupos', error)
        )
        .finally(() => {
          this.loading = false
        })
    },
    redirectToExternalSystem(id) {
      let token = this.$auth.token()
      localStorage.setItem('oldToken', token)

      agent.FranchiseEnvironment.getFranchiseeAmbient(id).then()
        .catch(
          (error) => this.$refs.feedback.handleError('Erro ao acessar grupos', error)
        );
      setTimeout(() => {
        window.location.reload()
      }, 300);
    },
    formatCnpj,
  },
  mounted() {
    this.getViewFranchisee();
  },
})
</script>