
import Vue from 'vue';
// eslint-disable-next-line no-unused-vars
import {PointOfSale} from "@/types/PointOfSale";
import {shouldRefreshData, toApiPagination} from "@/components/TableOptionsComparator";

export default Vue.extend({
  props: {
    value: {type: Boolean, required: true},
    finishActionName: {type: String, required: true}
  },
  data() {
    return {
      pointOfSales: [] as PointOfSale[] | undefined,
      pointOfSalesToAdd: [] as PointOfSale[] | undefined,
      headers: [
        {text: 'ID PDV', align: 'left', value: 'id'},
        {text: 'Cliente', align: 'left', value: 'localCustomerName'},
        {text: 'Local', align: 'left', value: 'localName'},
        {text: 'Local específico', align: 'left', value: 'specificLocation'},
      ],
      loading: {
        pointOfSales: true
      },
      search: {
        valid: true,
        text: '' || null,
      },
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['id'],
        sortDesc: [false],
        mustSort: true
      },
    };
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    dialog(value: boolean) {
      if (value) {
        this.pointOfSalesToAdd = [];
        this.getPointOfSales();
      }
    },
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getPointOfSales();
        }
      },
      deep: true,
    },
    pointOfSalesToAdd(value) {
      this.$emit("pointOfSaleToAddChange", value)
    }
  },
  methods: {
    getPointOfSales() {
      this.loading.pointOfSales = true;
      let params = toApiPagination(this.options, this.search.text);
      this.axios
          .get('api/PointsOfSale/paginated', {params: params})
          .then((response) => {
            this.loading.pointOfSales = false;
            this.pointOfSales = response.data.items;
            this.totalItems = response.data.totalItems;
          });
    },
    closeDialog() {
      this.dialog = false;
      this.pointOfSalesToAdd = [];
    },
    addPointOfSales() {
      this.dialog = false;
      this.$emit("pointOfSalesToApply", this.pointOfSalesToAdd);
    }
  }
});
