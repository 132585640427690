export default {
  tenantGroupDialogNewGroup: 'Novo Grupo',
  tenantGroupDialogCardTitle: 'Cadastrar Novo Grupo',
  tenantGroupDialogGroupIdLabel: 'ID do Grupo',
  tenantGroupDialogCnpjLabel: 'CNPJ',
  tenantGroupDialogGroupNameLabel: 'Nome do Grupo',
  tenantGroupDialogCancel: 'Cancelar',
  tenantGroupDialogCreate: 'Criar',
  tenantGroupDialogHandleErrorMessage: 'Não foi possível',
  tenantGroupDialogEmitSuccessMessage: 'Grupo id {groupId} criado com com sucesso!'
}