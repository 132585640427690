export default {
    Administrador: 'Administrator',
    'Administrador App': 'App Administrator',
    'Administrador PrivateLabel': 'PrivateLabel Administrator',
    Cadastros: 'Registrations',
    Contabilidade: 'Accounting',
    'Edição Preços': 'Prices Edition',
    Gerente: 'Manager',
    'Inventário às cegas e abastecimento': 'Blind inventory and Replenishment',
    Logistica: 'Logistics',
    Manager: 'Manager',
    'Notas Fiscais': 'Invoices',
    Segurança: 'Security',
    Síndico: 'Receiver',
    Suporte: 'Support',
    Telemetria: 'Telemetry',
    'TouchPay Abastecedor': 'TouchPay Replenishment',
    Usuário: 'User',
    Vendas: 'Sales',
    'Visualização Planogramas': 'Planograms View',
    'Visualização PrivateLabel': 'PrivateLabel View',
    'Visualização Transações': 'Transactions View',
    Mercurio: 'Mercurio',
    PushNotification: 'PushNotification',
    Video: 'Video',

    routes: {
        nameTransactions: 'Transactions',
        nameTelemetry: 'Telemetry',
        nameConnectivityMaster: 'Connectivity',
        nameManagePos: 'Manage Points Of Sale',
        nameProductsMaster: 'Products',
        nameInventoryMaster: 'Stocks',
        namePurchases: 'Purchases',
        nameOrders: 'Orders',
        nameNewPointOfSale: 'New Point Of Sale',
        nameToEditPointOfSale: 'Edit Point Of Sale',
        namePriceTables: 'Price Tables',
        namePriceTableItems: 'Price Table Products',
        namePlanogramMaster: 'Planograms',
        nameCharts: 'Sales Reports',
        nameTransactionsReports: 'Dynamic Sales Summary',
        nameInventoryReports: 'Dynamic Stock Summary',
        nameRuptures: 'Rupture Reports',
        nameLosses: 'Operational Reports',
        namePickLists: 'Pick Lists',
        nameReplenishment: 'Replenishment',
        nameAccessControl: 'Access Control',
        namePrivateLabel: 'Private Label',
        nameDiscountsMaster: 'Discounts',
        nameNewBox: 'Activate New TouchPay',
        nameAlerts: 'Alerts',
        nameDownloads: 'Downloads',
        nameContracts: 'Activations',
        nameSupport: 'Help',
        nameReleaseNotes: 'Release Notes',
        nameAudits: 'Audit',
        nameTaxScenarios: 'Tax Scenarios',
        namePagSeguro: 'PagSeguro',
        nameSwagger: 'Swagger',
        nameSuspiciousMaster: 'Deviation Alerts',
        nameUsers: 'Users',
        nameUsersFeedbacks: 'Reviews',
        nameMobileUsers: 'App Users',
        nameNotifications: 'Notifications',
        nameCustomersMaster: 'Customers',
        namePurchaseSuggestions: 'Purchase Suggestions',
        nameDashboard: 'Dashboard',
        nameMyBills: 'My Bills',
        namePromotionalLogos: 'Promotional Logos',
        nameInvoicesMaster: 'Invoices',
        nameViewFranchiseeWeb: 'Environments',
        nameTenantsFeedbacks: 'Feedbacks',
        nameCondominiumReports: 'Condominium Reports',
        nameMidasOrders:'New Order',
        nameChangeOfOwnership: 'Approve Transfers',
        nameChangeOwnershipNewRequest: 'Transfer Point of Sale',
        nameViewTransferRequests: 'View Change Ownership Requests',
        redirect: 'dashboard',
        nameMissingProducts: 'Products not found'
    },

    path: {
        nameTransactions: 'transactions',
        nameTelemetry: 'telemetry',
        nameConnectivityMaster: 'connectivity',
        nameManagePos: 'points-of-sale',
        nameProductsMaster: 'products',
        nameInventoryMaster: 'stocks',
        namePurchases: 'purchases',
        nameOrders: 'orders',
        nameNewPointOfSale: 'pos',
        nameToEditPointOfSale: 'pos',
        namePriceTables: 'price-tables',
        namePlanogramMaster: 'planogram',
        nameCharts: 'reports',
        nameTransactionsReports: 'sales-summary',
        nameInventoryReports: 'stock-summary',
        nameRuptures: 'rupture-reports',
        nameLosses: 'operational-reports',
        namePickLists: 'picklist',
        nameReplenishment: 'replenishments',
        nameAccessControl: 'access-control',
        namePrivateLabel: 'private-label',
        nameDiscountsMaster: 'discounts',
        nameNewBox: 'activate',
        nameAlerts: 'alerts',
        nameDownloads: 'downloads',
        nameContracts: 'activations',
        nameSupport: 'help',
        nameReleaseNotes: 'release-notes',
        nameAudits: 'audit',
        nameTaxScenarios: 'tax-scenarios',
        namePagSeguro: 'pagseguro',
        nameSwagger: 'swagger',
        nameSuspiciousMaster: 'deviation-alerts',
        nameUsers: 'users',
        nameUsersFeedbacks: 'feedback-users',
        nameMobileUsers: 'app-users',
        nameNotifications: 'push-notifications',
        nameCustomersMaster: 'Customers',
        namePurchaseSuggestions: 'purchase-suggestions',
        nameDashboard: 'dashboard',
        nameMyBills: 'my-Bills',
        namePromotionalLogos: 'promotional-logos',
        nameInvoicesMaster: 'invoices',
        nameViewFranchiseeWeb: 'view-environments',
        nameTenantsFeedbacks: 'feedbacks',
        nameCondominiumReports: 'condominium-reports',
        nameMidasOrders:'new-order',
        nameChangeOfOwnership: 'approve-transfers',
        nameChangeOwnershipNewRequest: 'transfer-point-sale',
        nameMissingProducts: 'missing-products',
    }
}
