export default {
    titlePosWithoutPickList: 'Todos los puntos de venta',
    titleWithoutPickList: 'Puntos de venta sin lista de selección pendiente',
    showAllPosPosWithoutPickList: 'Mostrar todos los PDVs',
    selectAtLeastOnePosWithoutPickList: 'Selecciona al menos un PDV',
    generatePickLists: 'Generar listas de selección',
    searchPosWithoutPickList: 'Buscar',
    noResultsPosWithoutPickList: 'Sin resultados',
    machineModelColumnPosWithoutPickList: 'Máquina',
    codeColumnPosWithoutPickList: 'Código PDV',
    customerColumnPosWithoutPickList: 'Cliente',
    locationColumnPosWithoutPickList: 'Ubicación',
    specificLocationColumnPosWithoutPickList: 'Ubicación específica',
    idColumnPosWithoutPickList: 'ID PDV',
};
