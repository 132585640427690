export default {
    editLockLockForm: 'Editar trava',
    deviceNameLockForm: 'Nome do dispositivo',
    deviceNameLockErrorForm: 'Padrão incorreto! Exemplos com o padrão correto: "TPLockAA00", "MMD-1234"',
    deviceNameHintLockForm: 'O nome deve ser exatamente igual ao do dispositivo Bluetooth',
    lockNameLockForm: 'Nome da trava',
    lockNameHintLockForm: 'Nome para você identificar a trava, ex: Geladeira bebidas, Porta principal',
    ageRestrictedLockForm: 'Acesso restrito +18',
    ageRestrictedHint: 'Desbloqueável somente por maiores de 18 anos',
    closeLockForm: 'Fechar',
    saveLockForm: 'Salvar',
    registerLockForm: 'Cadastrar',
    successEditMessage: 'Cadastro da trava editado!',
    successAddMessage: 'Trava cadastrada!',
    errorMessageEdit: 'Não foi possível atualizar o cadastro da trava',
    errorMessageAdd: 'Não foi possível cadastrar a trava',
    addLockLockForm: 'Cadastrar trava',
    deviceUnlockTime: 'Tempo de abertura do dispositivo',
    deviceUnlockTimeSeconds: '{time} segundos',
    deviceUnlockTimeMinute: '{time} minuto'
  }
