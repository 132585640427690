export enum DiscountType {
  Fixed = "Fixed",
  Percentage = "Percentage"
}

export type DiscountProductRuleResponse = {
  id: number,
  productId:number,
  productCode: string,
  productDescription: string,
  productCategory: string,
  productDefaultPrice?: number,
  quantity: number,
  amount: number,
}

export type DiscountPointOfSaleRuleResponse = {
  id: number,
  pointOfSaleId: number,
  customerName: string,
  localName: string,
  specificLocation: string,
}

export type DiscountProduct = {
  id: number,
  type: DiscountType,
  expiresOn?: Date,
  isValid: boolean,
  description: string,
  usage: number,
  discountPointOfSalesRules: DiscountPointOfSaleRuleResponse[]
  discountProductRules: DiscountProductRuleResponse[]
};