<template>
  <v-container fluid class="pa-0">
    <portal to="toolbarTitle">
      <help>
        <template #title>
          Controle de acesso
        </template>
        <p>
          Aqui são exibidas todos os acessos de cada trava e também a telemetria de cada dispositivo, tudo em abas separadas.
        </p>
        <p>
          Começando pela aba <strong>Lista de acessos</strong> você irá visualizar uma lista com os acessos
          realizados, junto com o resultado da ação, que pode ser <strong style='color: red'>Erro</strong> ou <strong style='color: green'>Sucesso</strong>.
          Ao expandir a linha clicando em <v-icon>keyboard_arrow_down</v-icon>, você irá visualizar uma listagem
          com informações referente ao responsável pela abertura da trava, o nome da trava e do dispositivo e
          também o erro específico que ocorreu na abertura, se houver.
        </p>
        <p>
          Partindo para a aba de <strong>Telemetria</strong> você irá visualizar dados pertinentes a telemetria com informações sobre o cliente,
          dispositivo e a situação dele.
        </p>

        <help-video  help-code="AccessControl"/>
      </help>
    </portal>
    <v-layout>
      <v-flex xs-12>
        <v-card class="mb-16">
          <v-tabs grow background-color="grey lighten-4">
            <v-tab key="accessList">Lista de acessos</v-tab>
            <v-tab key="telemetry">Telemetria</v-tab>
            <v-tab-item key="accessList">
            <access-control-filter v-on:modify="updateFilters">
            <template v-slot:excelButton>
              <download-button
                url="api/AccessControl/excel"
                :query-params="insertFiltersFromForm({})"
                filename="Acessos.xlsx"
                #default="{ loading, download }">
                <v-btn
                  class="mt-2 mr-2"
                  color="info"
                  @click.native="download"
                  :loading="loading"
                >
                  <v-icon left>mdi-file-excel</v-icon>Excel
                </v-btn>
              </download-button>
            </template>
          </access-control-filter>
              <v-card>
                <v-data-table
                  :items="accessControl"
                  :headers="accessList.headers"
                  :loading="accessList.loading"
                  :server-items-length="totalItems"
                  :options.sync="options"
                  :single-expand=true
                  :expanded.sync="expanded"
                  item-key="uuid"
                  show-expand
                  no-data-text="Sem informações para listar"
                  :footer-props="{ itemsPerPageOptions: [10, 20, 30] }"
                >
                <template v-slot:item.date="{ item }">
                  <span>{{ formatDate(item.date) }}</span>
                </template>
                <template v-slot:item.result="{ item }">
                  <v-chip :color="getColorAccess(item.result)" dark>{{ formatResultAccess(item.result) }}</v-chip>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="ma-0 pa-0">
                      <v-simple-table v-if="item.user != null">
                        <template v-slot:default>
                          <thead class="grey lighten-4 elevation-0">
                            <tr>
                              <th class="grey lighten-4 elevation-0">
                                <strong>Responsável pela abertura:</strong> {{ item.user.fullName }}
                              </th>
                              <th class="grey lighten-4 elevation-0">
                                <strong>CPF:</strong> {{ item.user.cpf }}
                              </th>
                              <th class="grey lighten-4 elevation-0">
                                <v-btn icon :href="`http://wa.me/55${item.user.phone}`" target="_blank"><v-icon color="info">mdi-whatsapp</v-icon></v-btn>
                                {{ item.user.phone }}
                              </th>
                              <th class="grey lighten-4 elevation-0">
                                <v-btn icon :href="`mailto:${item.user.email}`"><v-icon color="info">mdi-email-outline</v-icon></v-btn>
                                {{ item.user.email }}
                              </th>
                              <th class="grey lighten-4 elevation-0">
                                <block-user-icon
                                  :is-blocked="item.user.isBlocked"
                                  :user="item.user"
                                  @success="handleSuccess"
                                  @error="handleError"
                                ></block-user-icon>
                              </th>
                            </tr>
                          </thead>
                        </template>
                      </v-simple-table>
                      <v-simple-table>
                        <template v-slot:default>
                          <tr class="grey lighten-4 elevation-0">
                            <th class="text-left pa-4">Código da Trava</th>
                            <th class="text-left pa-4">Nome do dispositivo</th>
                            <th class="text-left pa-4">Nome da Trava</th>
                            <th class="text-left pa-4">Detalhes</th>
                          </tr>
                          <tbody>
                            <tr>
                              <td>
                                {{item.lock.code}}
                              </td>
                              <td>
                                {{item.lock.deviceName}}
                              </td>
                              <td>
                                {{item.lock.friendlyName}}
                              </td>
                              <td>
                                {{ formatSpecificResult(item.result) }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </td>
                  </template>

                </v-data-table>
              </v-card>
            </v-tab-item>
            <v-tab-item key="telemetry">
              <v-card>
                <v-data-table
                  :loading="telemetry.loading"
                  :headers="telemetry.headers"
                  :items="telemetryList"
                  :server-items-length="totalItemsTelemetry"
                  :options.sync="optionsTelemetry"
                  no-data-text="Sem informações para listar"
                  :footer-props="{ itemsPerPageOptions: [10, 20, 30] }">
                  <template v-slot:item.telemetryDate="{ item }">
                    <span>{{ formatDate(item.telemetryDate) }}</span>
                  </template>
                  <template v-slot:item.lock.ageRestricted="{ item }">
                    <span>{{ formatAgeRestricted(item.lock.ageRestricted) }}</span>
                  </template>
                  <template v-slot:item.telemetryInfo.deviceState="{ item }">
                    <v-chip :color="getColorTelemetry((item.telemetryInfo||{}).deviceState)" dark>
                      {{ formatDeviceStatus((item.telemetryInfo||{}).deviceState) }}
                    </v-chip>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-flex>
    </v-layout>
    <feedback ref="feedback"></feedback>
  </v-container>
</template>

<script>
import AccessControlFilter from '@/components/filters/AccessControlFilter';
import { shouldRefreshData, toApiPagination } from '@/components/TableOptionsComparator';
import moment from 'moment';
import DownloadButton from '@/components/DownloadButton';
import BlockUserIcon from '@/components/common/BlockUserIcon.vue'
import HelpVideo from '@/components/HelpVideo.vue'

export default {
  data() {
    return {
      expanded: [],
      accessControl: [],
      telemetryList: [],
      valid: true,
      rules:{
      },
      accessList: {
        loading: true,
        headers: [
          { text: 'PDV', align: 'left', sortable: false, value: 'pointOfSale.id' },
          { text: 'Cliente', align: 'left', sortable: false, value: 'pointOfSale.customerName' },
          { text: 'Local', align: 'left', sortable: false, value: 'pointOfSale.localName' },
          { text: 'Local específico', align: 'left', sortable: false, value: 'pointOfSale.specificLocation' },
          { text: 'Data', align: 'left', sortable: false, value: 'date' },
          { text: 'Resultado', align: 'left', sortable: false, value: 'result' }
        ],
      },
      telemetry: {
        loading: true,
        headers: [
          { text: 'Cliente', align: 'left', sortable: false, value: 'pointOfSale.customerName' },
          { text: 'Local', align: 'left', sortable: false, value: 'pointOfSale.localName' },
          { text: 'Local específico', align: 'left', sortable: false, value: 'pointOfSale.specificLocation' },
          { text: 'Data da Telemetria', align: 'left', sortable: false, value: 'telemetryDate' },
          { text: 'Código do dispositivo', align: 'left', sortable: true, value: 'lock.code' },
          { text: 'Dispositivo', align: 'left', sortable: true, value: 'lock.deviceName' },
          { text: 'Nome dispositivo', align: 'left', sortable: true, value: 'lock.friendlyName' },
          { text: 'Restrição de idade?', align: 'left', sortable: true, value: 'lock.ageRestricted' },
          { text: 'Status', align: 'left', sortable: false, value: 'telemetryInfo.deviceState' },
        ],
      },
      removeDialog: {
        enabled: false,
        item: {}
      },
      snackbar: {
        enabled: false,
        message: null,
        color: 'error',
        timeout: 3000
      },
      form: {},
      totalItems: 0,
      totalItemsTelemetry: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [true],
        mustSort: true,
      },
      optionsTelemetry: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [true],
        mustSort: true,
      },
      excel: {
        loading: false
      }
    }
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.updateAccessControl();
        }
      },
      deep: true
    },
    optionsTelemetry: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.updateAccessControl();
        }
      },
      deep: true
    }
  },
  methods: {
    updateFilters(form) {
      this.form = form;
      this.options.page = 1;
      this.updateAccessControl();
    },
    updateAccessControl() {
      this.accessList.loading = true;
      this.telemetry.loading = true;

      let pagination = toApiPagination(this.options);
      this.insertFiltersFromForm(pagination);

      this.axios.get('api/AccessControl', { params: pagination })
        .then((response) => {
          this.accessList.loading = false;
          this.accessControl = response.data.items;
          this.totalItems = response.data.totalItems;
        });

      let paginationTelemetry = toApiPagination(this.optionsTelemetry);

      this.axios.get('api/AccessControl/telemetry', { params: paginationTelemetry})
        .then((response) => {
          this.telemetry.loading = false;
          this.telemetryList = response.data.items;
          this.totalItemsTelemetry = response.data.totalItems;
        });
    },
    insertFiltersFromForm(params) {
      const form = this.form;
      params.minDate = form.minDate;
      params.maxDate = form.maxDate;
      params.customerId = form.customerId;
      params.localId = form.localId;
      params.pointOfSaleId = form.pointOfSaleId;
      params.unlockResult = form.unlockResult;

      return params;
    },
    getColorTelemetry (status) {
      if (status == 'Active') return 'green'
      else if (status == 'NotConnected') return 'blue'
      else if (status == 'Inactive') return 'red lighten-3'
      else return 'grey lighten-1'
    },
    formatAgeRestricted(age) {
      return age ? 'Sim' : 'Não'
    },
    getColorAccess (result) {
      if (result === 'LockOpened' || result === 'FacialRecognitionSuccess') return 'green'
      else if (result === 'CpfNotValidated'
              || result === 'AgeNotValidated'
              || result === 'UserBlocked') return 'orange lighten-3'

      return 'red lighten-3'
    },
    formatDate(date) {
      return moment(date).isValid() ? moment(date).format('DD/MM/YYYY HH:mm:ss') : "";
    },
    formatDeviceStatus(status) {
      let translate = ''
      if (status == 'Active') translate = 'Ativo'
      else if (status == 'Inactive') translate = 'Inativo'
      else if (status == 'NotConnected') translate = 'Desconectado'
      else translate = 'Problema desconhecido'

      return translate
    },
    formatResultAccess(oldResult) {
      if (oldResult === 'LockOpened' || oldResult === 'FacialRecognitionSuccess') return 'Sucesso'
      else if (oldResult === 'CpfNotValidated'
              || oldResult === 'AgeNotValidated'
              || oldResult === 'UserBlocked') return 'Invalidado'

      return 'Erro'
    },
    formatSpecificResult(oldResult){
      let translate = ''
      if (oldResult == 'Unknown') translate = 'Sem resposta do terminal (verificar conexão)'
      else if (oldResult == 'LockNotFound') translate = 'Porta não encontrada'
      else if (oldResult == 'DeviceNotFound') translate = 'Dispositivo não encontrado'
      else if (oldResult == 'DeviceNotPaired') translate = 'Dispositivo não pareado'
      else if (oldResult == 'DeviceNotConnected') translate = 'Dispositivo não conectado'
      else if (oldResult == 'DeviceNotResponsive') translate = 'Dispositivo sem resposta'
      else if (oldResult == 'CommunicationTimeout') translate = 'Tempo de comunicação expirada'
      else if (oldResult == 'CommunicationError') translate = 'Erro na comunicação'
      else if (oldResult == 'TerminalNotReachable') translate = 'Terminal não acessível'
      else if (oldResult == 'BluetoothDisabled') translate = 'Bluetooth desabilitado'
      else if (oldResult == 'AgeNotValidated') translate = 'Idade não validada'
      else if (oldResult == 'CpfNotValidated') translate = 'CPF não validado'
      else if (oldResult == 'UserBlocked') translate = 'Usuário bloqueado'
      else if (oldResult == 'FacialRecognitionFail') translate = 'Reconhecimento facial falhou'
      else if (oldResult == 'FacialRecognitionSuccess') translate = 'Reconhecimento facial concluído com sucesso'

      return translate
    },
    handleSuccess(message) {
      this.$refs.feedback.handleSuccess(message);
      this.$nextTick(() => {
        this.updateAccessControl();
      })
    },
    handleError(message, error) {
      this.$refs.feedback.handleError(message, error);
    },
  },
  components: {
    AccessControlFilter,
    DownloadButton,
    BlockUserIcon,
    HelpVideo
  }
}
</script>
