<template>
  <help>
    <template #title>
      Pontos de venda
    </template>
    <p>
      Aqui são exibidos todos os pontos de venda na forma de um cartão para cada PDV. 
      A barra colorida no topo do cartão indica a disponibilidade do PDV. 
      Quando está <span class="green--text">verde</span>, o PDV está funcionando corretamente.
      <span class="orange--text">Laranja</span> indica que provavelmente algo está errado.
      <span class="red--text">Vermelho</span> indica que o PDV está indisponível. 
    </p>
    <p>
      Ao lado do nome e local do PDV, é possível enviar <strong>créditos remotos</strong> (<v-icon>mdi-cash-usd-outline</v-icon>) 
      e consultar os <strong>planogramas</strong> (<v-icon>view_comfy</v-icon>) do PDV.
      O botão de planograma é colorido de acordo com o status do mesmo.
    </p>
    <p>
      Mais abaixo no cartão temos uma tabela com o quantidade e o valor de produtos vendidos
      hoje, desde 7 dias e desde 30 dias. Ao lado dessa tabela, temos outras informações:
      <strong>nível de sinal</strong>, <strong>nível de estoque</strong>, quantidade
      de produtos abaixo do <strong>mínimo crítico</strong>, há quanto tempo foi a 
      <strong>última compra</strong> e <strong>desempenho atual de vendas</strong>, respectivamente.
      O desempenho atual é calculado com base no total de itens vendidos nas últimas horas, 
      em comparação com a média de vendas nos mesmos dias da semana e mesmo horário.
    </p>
    <p>
      Ao expandir o cartão clicando em <v-icon>keyboard_arrow_down</v-icon>, você vê um gráfico de 
      <strong>vendas por hora</strong> do PDV para o dia da semana atual. A curva azul (média) é a 
      média de vendas por hora no dia da semana atual, enquanto o segmento laranja são as vendas por hora
      do momento atual.
    </p>
    <p>
      Abaixo do gráfico de vendas por hora temos a opção de <strong>abastecer</strong> o ponto de venda.
    </p>

    <help-video  help-code="MyPointOfSale"/>
  </help>


</template>

<script>
import HelpVideo from '@/components/HelpVideo.vue'

export default {
components: {
    'help-video': HelpVideo
  },
}
</script>

<style>

</style>