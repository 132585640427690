
import Vue from 'vue';
import PlanogramDates from '@/planogram/PlanogramDates';
import PointOfSaleSelector from "@/components/PointOfSaleSelector.vue";
// eslint-disable-next-line no-unused-vars
import {PointOfSale} from "@/types/PointOfSale";

export default Vue.extend({
  components: {PointOfSaleSelector},
  props: {
    value: {type: Boolean, required: true},
  },
  data() {
    return {
      priceTableName: null,
      searchPosId: null,
      creationType: 'empty',
      submitting: false,
      listOfVersions: [],
      versionOfPlanogramId: null,
      loading: {
        pointsOfSale: true,
        planograms: false,
      },
      valid: false,
    };
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    dialog(value: boolean) {
      if (value) {
        this.clearDialogForm();
      }
    },
    searchPosId: function (searchId) {
      this.listOfVersions = [];
      if (searchId != null) {
        this.loading.planograms = true;
        this.getVersions();
      }
    }
  },
  methods: {
    // eslint-disable-next-line
    createPriceTable(bytes: BufferSource) {
      if ((this.$refs.form as any).validate(bytes)) {
        this.submitting = true;
        this.axios.post('api/PriceTables/', {name: this.priceTableName, planogramId: this.versionOfPlanogramId})
            .then(() => {
              this.dialog = false;
              this.$emit("createdPriceTable", this.priceTableName, this.versionOfPlanogramId);
            })
            .catch((error) => {
              this.handleError(`Não foi possível criar Tabela de Preços`, error);
            })
            .finally(() => {
              this.submitting = false;
              this.priceTableName = null;
              this.versionOfPlanogramId = null;
            });
      }
    },
    getVersions() {
      this.axios.get("api/Planograms?posId=" + this.searchPosId)
          .then((response) => {
            this.loading.planograms = false;
            this.listOfVersions = [];
            if (response.data.length > 0) {
              this.listOfVersions = response.data.map(function (x: any) {
                x.searchVersion = PlanogramDates.getPlanogramDescriptionFromIsoDates(x, true);
                return x;
              });
            }
          });
    },
    filterPosWithPlanogram(pos: PointOfSale) {
      return pos.currentPlanogramId != null;
    },
    clearDialogForm() {
      this.priceTableName = null;
      this.creationType = 'empty';
      this.searchPosId = null;
      this.versionOfPlanogramId = null;
      this.valid = false;
    },
    clearPlanogramsSelector() {
      this.searchPosId = null;
      this.versionOfPlanogramId = null;
    },
    handleSuccess(message: string) {
      (this.$refs.feedback as any).handleSuccess(message);
    },
    handleError(message: string, error: any) {
      (this.$refs.feedback as any).handleError(message, error);
    },
  }
});
