export default {
    pickListPointOfSaleNameColumn: 'punto de venta',
    pickListIdColumn: 'ID de la PickList',
    pickListDateCreateColumn: 'Fecha de creación',
    pickListDateUpdateColumn: 'Fecha de actualización',
    pickListStatusColumn: 'Estado',
    pickListPlanogramIdColumn: 'ID del Planograma',
    pickListMachineModelColumn: 'Modelo de la máquina',
    pickListManufactureMachineColumn: 'Fabricante de la máquina',

    productIdColumn: 'ID del Producto',
    productDescriptionColumn: 'Descripción',
    productQuantityColumn: 'Cantidad',
    productBarCodeColumn: 'Código de barras',
    productCodeColumn: 'Código del producto',
    productCategoryNameColumn: 'Categoría',
    productCurrentQuantityColumn: 'Cantidad actual',
    productQuantityToSupplyColumn: 'Cantidad deseada',
    productMinimumQuantityColumn: 'Mínimo crítico',

    PickLisTDialogTitle: '¿Qué columnas desea incluir en su Excel?',
    PickListDialogSubTitle: 'personalice la picklist con las columnas que deben aparecer',
    PickListDialogFixedColumns: 'Columnas fijas de la picklist',
    PickListDialogOptionalColumns: 'Columnas opcionales de la picklist',
    PickListDialogItemsFixedColumns: 'Columnas fijas de los ítems',
    PickListDialogItemsOptionalColumns: 'Columnas opcionales de los ítems',
    PickListDialogExcelButton: 'Descargar',
    PickListDialogCloseButton: 'Cerrar',
};
