
import Vue from 'vue';

export default Vue.extend({
  props: {
    imageUrl: { type: String, default: null },
    items: { type: Array, default: () => [] },
    title: { type: String, default: "" },
    quantityText: { 
      type: Function, 
      default: (quantity: number) => quantity > 1 ? 'unidades' : 'unidade'
    },
  },
  data() {
    const $cf = (this as any).$currencyFormatter;
    return {
      detailsDialog: false,
      headers: [
        { text: 'Descrição', value: 'serviceTypeInfo' },
        { text: 'Quantidade', value: 'chargedQuantity' },
        { text: `Valor unitário/Mês`, value: 'unitPrice' }
      ],
      expanded: [],
      cf: $cf
    }
  },
  computed: {
    totalQuantity() {
      return (this.items as { chargedQuantity: number}[])
        .reduce((acc, item) => acc + item.chargedQuantity, 0)
    },
    totalValue() {
      return (this.items as { chargedQuantity: number, unitPrice: number }[])
        .reduce((acc, item) => acc + item.chargedQuantity * item.unitPrice, 0)
    },
    showExpand() {
      return (this.items as { description: string | null }[] )
        .some(item => item.description !== null && item.description !== "")
    }
  },
  methods: {
    openWhatsapp() {
      window.open('https://api.whatsapp.com/send?phone=5511999999999', '_blank');
    }
  }
})
