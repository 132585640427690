
import Vue from 'vue'
import {primaryUnlockFilter} from "@/types/UnlockResult";


export default Vue.extend({
    data(){
        return{
            unlockResultItems: [primaryUnlockFilter.Error, primaryUnlockFilter.Invalid, primaryUnlockFilter.Success] as primaryUnlockFilter[],
        }
    },
    mounted(){
        
    },
    props: {
      value: { type: Array as () => primaryUnlockFilter[], required: true },
    },
    computed:{
        selectedUnlockResult : {
            get(): primaryUnlockFilter[]{
                return this.value
            },
            set(value: primaryUnlockFilter[]){
                this.$emit('input', value)
            }
        }
    },
    methods: {
        getColor(value: primaryUnlockFilter){
            if(value === primaryUnlockFilter.Success){
                return 'success'
            }
            if(value === primaryUnlockFilter.Invalid){
                return 'warning'
            }
            else{
                return 'error'
            }
        }
    },
})
