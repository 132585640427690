
import agent from '@/api/agent'
import Vue from 'vue'
import {unlockResult, primaryUnlockFilter, filters} from "@/types/UnlockResult";


export default Vue.extend({
    data(){
        return{
            unlockResultItems: [] as unlockResult[],
            unlockResultItemsFilter: [] as unlockResult[],
        }
    },
    mounted(){
        this.getUnlockResultItems()
    },
    props: {
      value: { type: Array as () => string[], required: true },
      filterUnlock : { type: Array as () => primaryUnlockFilter [], default: () => []},
      disabled: { type: Boolean, default: false }
    },
    computed:{
        selectedUnlockResult : {
            get(): string[]{
                return this.value
            },
            set(value: string[]){
                this.$emit('input', value)
            }
        },
        RuleArrayEmpty() {
            return (v: unlockResult[]) => {
                if (this.filterUnlock.length > 0 && !v.length) {
                    return "Escolha pelo menos um filtro."; 
                } else {
                    return true;
                }
            };
        }
    },
    watch: {
        filterUnlock: {
            handler: 'insertFilter',
            deep: true
        }
    },
    methods: {
        getUnlockResultItems(){
            agent.AccessControl.getUnlockResults()
                .then(response => {
                    this.unlockResultItems = response
                    this.insertFilter()
                })
        },
        getColor(value: string){
            if(filters.Success.includes(value)){
                return 'success'
            }
            if(filters.Invalid.includes(value)){
                return 'warning'
            }
            else{
                return 'error'
            }
        },
        insertFilter() {
            this.unlockResultItemsFilter = [];
            
            if (this.filterUnlock.includes(primaryUnlockFilter.Success)) {
                this.unlockResultItemsFilter.push( ...this.unlockResultItems.filter(x => filters.Success.includes(x.value)))
            }
            if (this.filterUnlock.includes(primaryUnlockFilter.Invalid)) {
                 this.unlockResultItemsFilter.push( ...this.unlockResultItems.filter(x => filters.Invalid.includes(x.value)))
            }
            if (this.filterUnlock.includes(primaryUnlockFilter.Error)) {
                this.unlockResultItemsFilter.push( ...this.unlockResultItems.filter(x => filters.Error.includes(x.value)))
            }
            this.selectedUnlockResult = this.unlockResultItemsFilter.map(x => x.value);
        }
    },
})
