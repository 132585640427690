
import Vue from 'vue';
import i18n from '@/locales/i18n';
import { shouldRefreshData, toApiPagination } from '@/components/TableOptionsComparator';
import { formatDateTimeMinutes } from '@/utility/TextFormatting';
import { ChangeOwnershipDto, ChangeOwnershipStatus, ApprovedOwnership, UserRequestingApproval } from '@/types/ChangeOwnershipType';
import agent from "@/api/agent";
import DialogApproval from '../ChangeOwnershipRequest/components/DialogApproval.vue';

export default Vue.extend({
  data() {
    return {
      search: '',
      loading: true,
      alterColor:'',
      items: [] as ChangeOwnershipDto[],
      dialogApprovalProps:{
        dialog: false,
        email: '' as string,
        requestData: {} as ApprovedOwnership,
      },
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [true],
        mustSort: true,
      },
      totalItems: 0,
      expanded: [],
      headers: [
        { text: i18n.t('CNPJ de origem'), value: 'originCnpj', sortable: false },
        { text: i18n.t('CNPJ de destino'), value: 'destinationCnpj', sortable: false },
        { text: i18n.t('Data de solicitação'), value: 'requestDate', sortable: false },
        { text: i18n.t('Ambiente de origem'), value: 'originTenantId', sortable: false },
        { text: i18n.t('Ambiente de destino'), value: 'destinationTenantId', sortable: false },
        { text: i18n.t('Status aprovação: Administradora'), value: 'adminApprovalStatus', sortable: false },
        { text: i18n.t('Status aprovação: CNPJ destino'), value: 'destinationApprovalStatus', sortable: false },
      ],
    };
  },
  components:{
    DialogApproval
  },
  watch: {
    options: {
      handler(newOptions: any, oldOptions: any) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.updateOwnershipRequests();
        }
      },
      deep: true
    },
  },
  methods: {
    formatDateTimeMinutes,
    approveItem(item: ChangeOwnershipDto) {
      const userRequesting = ((this as any).$auth.user().tenantGroupId === null || (this as any).$auth.user().tenantGroupId === 0) ?
      UserRequestingApproval.franchise : UserRequestingApproval.destination;

      this.dialogApprovalProps.requestData = {
        changeOwnershipRequestId: item.id,
        status: ChangeOwnershipStatus.Approved,
        userRequesting: userRequesting,
      };

      this.dialogApprovalProps.dialog = true;
    },
    rejectItem(item: any) {
      const userRequesting = ((this as any).$auth.user().tenantGroupId === null || (this as any).$auth.user().tenantGroupId === 0) ?
      UserRequestingApproval.franchise : UserRequestingApproval.destination;

      this.dialogApprovalProps.requestData = {
        changeOwnershipRequestId: item.id,
        status: ChangeOwnershipStatus.Rejected,
        userRequesting: userRequesting,
      };

      this.dialogApprovalProps.dialog = true;
    },
    getAlterColorStatus (status: ChangeOwnershipStatus) {
      if (status === ChangeOwnershipStatus.Approved) return 'success'
      if (status === ChangeOwnershipStatus.Pending) return 'warning'

      return 'error'
    },
    async updateOwnershipRequests() {
      this.loading = true;
      let params = toApiPagination(this.options, this.search);
      try {
        const response = await agent.ChangeOwnership.getChangeRequests(params)
        this.items = response.items;
        this.totalItems = response.totalItems;
      }
      catch (error) {
        (this.$refs.feedback as any).handleError("Não foi possível carregar as trocas de titularidade", error);
      }
      finally {
        this.loading = false
      }
    },
    FranchisePendingApproval(adminApprovalStatus: ChangeOwnershipStatus) {
      return ((this as any).$auth.user().tenantGroupId === null || (this as any).$auth.user().tenantGroupId === 0) &&
      adminApprovalStatus === ChangeOwnershipStatus.Pending;
    },
    isDestinationPendingApproval(groupIdItem : number, destinationApprovalStatus: ChangeOwnershipStatus) {

      return (this as any).$auth.user().tenantGroupId === groupIdItem && destinationApprovalStatus === ChangeOwnershipStatus.Pending;
    },
    closedDialog() {
      this.dialogApprovalProps.dialog = false;
      this.dialogApprovalProps.requestData = {} as ApprovedOwnership;

      this.updateOwnershipRequests();
    },
  },
  mounted() {
    this.updateOwnershipRequests();
  }
});
