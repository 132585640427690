<template>
<div class="text-center">
<youtube v-if="video[0].youTubeVideoId != null" :id="video[0].youTubeVideoId" :width="videoWidth" :height="videoHeight"></youtube> 
</div>  
</template>

<script>
import YouTube from '@/components/YouTube'
export default {
  props:{
    HelpCode: { type: String, required: false },
  },
  data() {
    return {
      dialog: false,
      video: [],
      videoWidth: 428,
      videoHeight: 330
    }
  },
  mounted() {
    this.axios.get(`api/help/items?codes=${this.HelpCode}`).then((response) => {
      this.video = response.data;
    });
  },
  components: {
    'youtube': YouTube
  }
}
</script>