export default {
      titlePickListCreationDialog: 'Gerar pick lists',
      descriptionLabelPickListCreationDialog: 'Descrição da Picklist',
      categoryLabelPickListCreationDialog: 'Filtrar produtos por categoria',
      criticalLabelPickListCreationDialog: 'Incluir somente produtos com estoque crítico',
      advancedSectionPickListCreationDialog: 'Avançado',
      secondaryLabelPickListCreationDialog: 'Marcar pick-list como secundária',
      groupByCategoryLabelPickListCreationDialog: 'Gerar uma picklist por categoria',
      generatePickListsButton: 'Criar',
      closeButtonTextPickListCreationDialog: 'Fechar',
      descriptionTooltipPickListCreationDialog: 'Quando agrupado por categoria, cada picklist terá como nome o nome da categoria',
      groupByCategoryDisabledTooltip: 'Para poder gerar uma picklist por categoria, deve-se marcar como pick-list secundária e filtrar por pelo menos uma categoria',
      pickListCreationError: 'Não foi possível gerar pickList',
      allTheProductsPickList: 'Todos os produtos do planograma',
  };
  