export default {
    dialogTitleSupply: 'Abastecimiento',
    helpTitleSupply: 'Abastecimiento del punto de venta',
    supplyCompleteLabel: 'Abastecimiento completo',
    planogramLabelSupply: 'planograma ',
    supplyPickListLabel: 'Abastecimiento por lista de selección',
    closeLabelSupply: 'Cerrar',
    supplyLabel: 'Abastecer',
    chooseSupplyTypeTooltip: 'Debe elegir una opción de abastecimiento',
    noPickListTooltipSupply: 'No hay lista de selección pendiente de abastecimiento para este punto de venta',
    noPlanogramTooltipSupply: 'No hay planograma activo o pendiente en este punto de venta',
    feedbackSuccessSupply: '¡Punto de venta abastecido!',
    feedbackErrorSupply: 'Error al abastecer el punto de venta',
    pickListSupply: 'Lista de selección ',
    pickListMainSupply: ' (principal)',
    pickListSecondarySupply: ' (secundaria)',
};
