export default {
    titleNegativeItemsDialog: 'Missing Products',
    descriptionNegativeItemsDialog: `This displays products that do not have sufficient balance in the distribution center
    for generating pick lists. By clicking on the <strong>Details</strong> of a specific
    product, it is possible to view the pending pick lists that contain the same product. It is also possible
    edit product quantities in these pick lists.`,
    content3NegativeItemsDialog: `The option to <strong>Redistribute item</strong> within <strong>Details</strong> changes
    the quantities of the item selected in the pick lists. This change is made in order to
    make the ratio between current quantity and desired quantity as similar as possible between
    the POS.`,
    noteNegativeItemsDialog: `For this information to be available, it is necessary to have the management system and
    central stock enabled in the TouchPay system.`,
    searchLabelNegativeItemsDialog: 'Search Product',
    searchLabelNegativeItemsNegativeItemsDialog: 'Search',
    headersIdNegativeItemsDialog: 'Id',
    headersProductNegativeItemsDialog: 'Product',
    headersProductCodeNegativeItemsDialog: 'Product code',
    headersStockNegativeItemsDialog: 'Stock',
    headersQtyInStockNegativeItemsDialog: 'Qty in stock',
    headersReservationsNegativeItemsDialog: 'Reservations',
    headersBalanceNegativeItemsDialog: 'Balance',
    headersDetailsNegativeItemsDialog: 'Details',
    saveButtonNegativeItemsDialog: 'Save',
    closeButtonTextNegativeItemsDialog: 'close',
};