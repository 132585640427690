
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {type: Boolean, required: true},
    targetType: {type: String, required: true}
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    confirmChange() {
      this.$emit("confirm-change");
    }
  }
});
