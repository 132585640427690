<template>
  <help>
    <template #title>{{ auditCategoryName }}</template>
    <p align="justify">
      Aqui as alterações realizadas em {{ auditCategoryName }} pelo uso do sistema AMLabs TouchPay
      podem ser visualizadas em forma de tabela.
    </p>
    <ul align="justify">
      <li>
        <strong>Ip:</strong> Ip do computador que realizou a modificação mostrada na linha.
      </li>
      <li>
        <strong>Usuário:</strong> Usuário que realizou a modificação mostrada na linha.
      </li>
      <li>
        <strong>Data e horário:</strong> Momento em que a modificação mostrada na linha foi realizada.
      </li>
      <li>
        <strong>Parâmetros:</strong> Parâmetros que foram usados para realizar-se a modificação mostrada na linha.
      </li>
      <li>
        <strong>Ação:</strong> Ação que foi feita na modificação mostrada na linha.
      </li>
    </ul>
  </help>
</template>

<script>
export default {
  props: {
    auditCategoryName: {type: String, default: null}
  }
};
</script>

<style scoped>

</style>