<template>
  <v-row>
    <portal to="toolbarTitle">

    </portal>

    <v-col cols="12">
      <v-tabs v-model="activeTab" grow background-color="grey lighten-4" class="mb-16">
        <v-tab :key="0">Pontos de Venda</v-tab>
        <v-tab :key="1" :disabled="!userIsManagerHierarchy">Terminais</v-tab>
        <v-tab-item :key="0">
          <pos-management :tab="activeTab"></pos-management>
        </v-tab-item>
        <v-tab-item :key="1" v-if="userIsManagerHierarchy">
          <box-management :tab="activeTab"></box-management>
        </v-tab-item>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
import PosManagement from './PosManagement.vue';
import BoxManagement from './BoxManagement.vue';
import { MANAGER_HIERARCHY} from '@/constants/Roles';

export default {
  data() {
    return {
      activeTab: 0
    }
  },
  components: {
    PosManagement,
    BoxManagement
  },
  computed: {
    userIsManagerHierarchy() {
      return MANAGER_HIERARCHY.some(role => this.$auth.check(role));
    }
  },
}
</script>
