export default {
      titleNegativeItemsDialog: 'Produtos em Falta',
      descriptionNegativeItemsDialog: `Aqui são exibidos os produtos que não possuem saldo suficiente no centro de distribuição 
      para a geração das pick lists. Ao clicar nos <strong>Detalhes</strong> de um determinado
      produto, é possível visualizar as pick lists pendentes que possuem o mesmo. Também é possível
      editar as quantidades do produto nessas pick lists.`,
      content3NegativeItemsDialog: `A opção de <strong>Redistribuir item</strong> dentro dos <strong>Detalhes</strong> altera
      as quantidades do item selecionado nas pick lists. Essa alteração é feita de maneira a
      deixar a razão entre quantidade atual e quantidade desejada mais parecida possível entre
      os PDVs.`,
      noteNegativeItemsDialog: `Para que essas informações estejam disponíveis, é necessário ter o sistema de gestão e 
      estoque central habilitado no sistema TouchPay.`,
      searchLabelNegativeItemsDialog: 'Procurar Produto',
      searchLabelNegativeItemsNegativeItemsDialog: 'Procurar',
      headersIdNegativeItemsDialog: 'Id',
      headersProductNegativeItemsDialog: 'Produto',
      headersProductCodeNegativeItemsDialog: 'Código do produto',
      headersStockNegativeItemsDialog: 'Estoque',
      headersQtyInStockNegativeItemsDialog: 'Qtd em estoque',
      headersReservationsNegativeItemsDialog: 'Reservas',
      headersBalanceNegativeItemsDialog: 'Balanço',
      headersDetailsNegativeItemsDialog: 'Detalhes',
      saveButtonNegativeItemsDialog: 'Salvar',
      closeButtonTextNegativeItemsDialog: 'fechar',
  };
  