<template>
  <v-container fluid grid-list-lg class="pa-0">
    <v-layout row wrap>
      <v-dialog v-model="removeDialog" max-width="400">
        <v-card>
          <v-card-text class="pt-4">{{ $t('removeConfirmationPosManagement') }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click.native="removeDialog = false">{{ $t('noPosManagement') }}</v-btn>
            <v-btn color="green darken-1" text @click.native="onDeleteConfirmed">{{ $t('deletePosManagement') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-flex xs12>
        <v-card class="mb-16">
          <v-card-title>
            {{ $t('pointsOfSalePosManagement') }}
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('searchPosManagement')"
              filled
              rounded
              dense
              single-line
              hide-details
            ></v-text-field>
            <v-btn color="success" class="ml-2" id="btnNewPdv" :to="`/${$t('path.nameNewPointOfSale')}`">
              <v-icon left>add</v-icon>{{ $t('newPosPosManagement') }}
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="pos"
            must-sort
            :loading="loading"
            hide-default-footer
            disable-pagination
            :no-data-text="$t('noResultsPosManagement')"
            :search="search">
            <template #[`item.boxId`]="{ item }">
              {{ item.boxId }}
              {{ item.boxMachineInterfaceId !== null ? ` (MMD-0${item.boxMachineInterfaceId})` : '' }}
            </template>
            <template #[`item.machineMachineModelName`]="{ item }">
              {{ item.machineMachineModelName }} / {{ item.machineMachineManufacturerName }}
            </template>
            <template #[`item.localName`]="{ item }">
              {{ item.localName }} / {{ item.specificLocation }}
            </template>
            <template #[`item.incompletePos`]="{ item }">
              <div class="justify-center px-0">

            <v-tooltip left v-if="item.incompletePos && showIncompletePos">
              <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    color="error"
                    icon
                    class="mx-0"
                    style="cursor:pointer;">mdi-alert-circle-outline</v-icon>
              </template>
              <span>{{ $t('incompletePosWarning') }}</span>
            </v-tooltip>

                <v-btn icon class="mx-0" @click="editItem(item)">
                  <v-icon color="info">edit</v-icon>
                </v-btn>
                <v-btn icon class="mx-0" @click.native.stop="deleteItem(item)">
                  <v-icon color="error">delete</v-icon>
                </v-btn>
              </div>
            </template>
            <template #[`item.dateCreated`]="{ item }">
              {{ formatDateTimeMinutes(item.dateCreated) }}
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <feedback ref="feedback"></feedback>
  </v-container>
</template>

<script>
import { MarketIntelligenceAccess } from '@/api/agent';
import i18n from '@/locales/i18n';
import { formatDateTimeMinutes } from '@/utility/TextFormatting';

  export default {
    data(){
      return {
        pos: [],
        headers: [
          { text: this.$t('idPosManagement'), align: 'left', sortable: true, value: 'id' },
          { text: this.$t('paymentSystemPosManagement'), align: 'left', sortable: true, value: 'boxId' },
          { text: this.$t('posCodePosManagement'), align: 'left', sortable: true, value: 'companyCode' },
          { text: this.$t('modelManufacturerPosManagement'), align: 'left', sortable: true, value: 'machineMachineModelName' },
          { text: this.$t('customerPosManagement'), align: 'left', sortable: true, value: 'localCustomerName' },
          { text: this.$t('locationPosManagement'), align: 'left', sortable: true, value: 'localName' },
          { text: this.$t('distributionCenterPosManagement'), align: 'left', sortable: true, value: 'distributionCenterName' },
          { text: this.$t('dateCreatedPosManagement'), align: 'left', sortable: true, value: 'dateCreated' },
          { text: this.$t('actionsPosManagement'), align: 'left', sortable: true, value: 'incompletePos' }
        ],
        itemToRemove: null,
        removeDialog: false,
        loading: true,
        search: '',
        localRelatedPeopleQuantity: null,
        localType: null,
        showIncompletePos: false
      }
    },
    watch: {
      showIncompletePos(value) {
        this.headers.find(header => header.text == this.$t('actionsPosManagement')).sortable = value;
      }
    },

    mounted() {
      this.axios.get('api/PointsOfSale')
          .then((response) => {
            this.loading = false;
            this.pos = response.data;
            this.pos.map((pos) => pos.incompletePos = pos.localRelatedPeopleQuantity === null || pos.localType === null)
          });
      this.getUserIntelligenceRegister();
    },
    methods: {
      editItem(item) {
        this.$router.push('/' + i18n.t('path.nameToEditPointOfSale') + '/' + item.id);
      },
      deleteItem(item){
        this.itemToRemove = item;
        this.removeDialog = true;
      },
      onDeleteConfirmed() {
        this.removeDialog = false;
        this.axios.delete('api/PointsOfSale/' + this.itemToRemove.id)
          .then(() => {
            this.pos = this.pos.filter(pos => pos.id != this.itemToRemove.id);
          }).catch((error) => this.$refs.feedback.handleError(this.$t('feedbackErrorPosManagement'), error));
      },
      async getUserIntelligenceRegister() {
        const params = {
          tenantId: this.$auth.user().tenantId,
          groupId: this.$auth.user().tenantGroupId
        }

        try {
          await MarketIntelligenceAccess.getUserAccess(params)
          this.showIncompletePos = true
        }
        catch (error){
          if (error.response?.status == 404) {
            this.showIncompletePos = false
          }
          else {
            this.showIncompletePos = true
          }
        }
      },
      formatDateTimeMinutes
  }
  };
</script>
