<template>
  <v-dialog persistent v-model="show" max-width="600" @keydown.esc="show = false">

    <template #activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>

    <feedback ref="feedback"></feedback>

    <v-form ref="form" lazy-validation v-model="valid" @submit.prevent="saveDiscount">
      <v-card>
        <v-card-title>
          <span class="title">Novo desconto</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 sm6>
                <v-autocomplete
                  :items="types"
                  v-model="form.type"
                  label="Tipo de desconto"
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 sm6>
                <v-text-field
                  type="number"
                  min="0"
                  step="0.01"
                  placeholder="0.00"
                  :label="valueLabel"
                  v-model="form.value"
                  :rules="[rules.nonEmpty, rules.float]"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6>
                <v-autocomplete
                  :items="codeTypes"
                  v-model="codeType"
                  label="Tipo de cupom"
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex v-if="codeType === 'specified'" xs12 sm6>
                <v-text-field
                  type="number"
                  min="0"
                  label="Limite de uso por cupom"
                  v-model="form.usageLimit"
                  :rules="[rules.integer]"
                  required
                ></v-text-field>
              </v-flex>
              <v-expansion-panels flat hover>
                <v-expansion-panel>
                  <v-expansion-panel-header>Avançado</v-expansion-panel-header>
                  <v-expansion-panel-content eager>
                    <v-layout row wrap>
                      <v-flex xs12 sm6>
                        <v-text-field
                          type="number"
                          min="0"
                          step="0.01"
                          placeholder="0.00"
                          label="Valor mínimo da compra"
                          v-model="form.minThreshold"
                          :rules="[rules.float]"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click.prevent="show = false">Fechar</v-btn>
          <v-btn type="submit" color="primary" :disabled="!valid" :loading="loading">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

  </v-dialog>
</template>

<script>
export default {
  watch: {
    show: function(enabled) {
      if (enabled) {
				this.valid = true;
			} else {
        this.$refs.form.reset();
        this.form = {};
			}
    },
  },
  data() {
    return {
      show: false,
      form: {},
      valid: true,
      loading: false,
      types: [{ value: 'Percentage', text: 'Porcentagem' }, { value: 'Fixed', text: 'Fixo' }],
      codeTypes: [{ value: 'single', text: 'Uso único' }, 
                  { value: 'unlimited', text: 'Ilimitado' }, 
                  { value: 'specified', text: 'Especificar' }],
      codeType: 'single',
      rules: {
        nonEmpty: v => /\S/.test(v) || "Insira algum valor",
        float: v => (!v || (!isNaN(v) && v >= 0)) || "Insira um valor válido ou deixe vazio",
        integer: (v) => /^\d+$/.test(v) || 'Insira um número inteiro',
      }
    };
  },
  computed: {
    valueLabel() {
      let suffix = '';
      if (this.form.type === 'Percentage') suffix = ' [%]'
      else if (this.form.type === 'Fixed') suffix = ` [${this.$currencyFormatter.getSign()}]`
      return `Valor do desconto${suffix}`
    }
  },
  methods: {
    saveDiscount() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.codeType === 'single') {
          this.form.usageLimit = 1;
        }
        else if (this.codeType === 'unlimited') {
          this.form.usageLimit = null;
        }
        this.axios.post('api/discounts', this.form)
          .then(response => {
            this.show = false;
            this.$emit('created', response.data);
          }).catch(error => this.$refs.feedback.handleError("Problema ao criar desconto", error))
          .then(() => this.loading = false);
      }
    }
  }
}
</script>