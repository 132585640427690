export const enumConstants = {
  billType: [
    {name: 'Mensalidade', value: 'ServicesMonthlyFee'},
    {name: 'Taxa de Ativação', value: 'ActivationFee'},
    {name: 'Venda de Equipamento', value: 'EquipmentSale'},
  ]
}

export const enumMappers = {
  billType: (enumValue: string): string => {
    const foundItem = enumConstants.billType.find(item => item.value === enumValue);
    return foundItem ? foundItem.name : '-';
  }
}
