import { render, staticRenderFns } from "./DialogApproval.vue?vue&type=template&id=8603e930&scoped=true&"
import script from "./DialogApproval.vue?vue&type=script&lang=ts&"
export * from "./DialogApproval.vue?vue&type=script&lang=ts&"
import style0 from "./DialogApproval.vue?vue&type=style&index=0&id=8603e930&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8603e930",
  null
  
)

export default component.exports