export type InvoicePurchase = {
    productRegistrationDisabled: Boolean;
    nfeNumber : string;
    serie : string;
    dateEmitted : string;
    providerName: string;
    key: string;
    purchaseType: PurchaseType;
    inventoryId: number;
    products: Product[];
}

export type Product = {
    productId: number | null;
    code: string;
    barCode: string;
    description: string;
    quantity: number;
    unitType: string;
    unitValue: number;

    quantityByUser?: number;
    unitTypeByUser?: string;
    codeByUser?: string;
    descriptionByUser?: string;
}

export type ProductData = {
    productId: number,
    amount: number,
    unitCost: number,
}

export type DataRequest = {
    nfeNumber: string,
    inventoryId: number,
    dateEmitted: string,
    Key : string,
    items: ProductData[];
}

export enum PurchaseType{
    XML = "XML",
    MANUAL = "MANUAL"
}