<template>
  <v-container fluid grid-list-lg>
    <!-- <v-progress-linear :indeterminate="changeOwnershipNewRequestStore.loadingView"></v-progress-linear> -->
    <v-layout row wrap>
      <v-flex xs12 class="mb-16">
        <v-form
          ref="form"
          class="px-2"
          @submit.prevent>
          
          <div class="subtitle-1 grey--text text--darken-2"> {{ $t('transferPointOfSale') }}</div>
          <v-divider class="mb-3 pb-3"></v-divider>
          <div class="font-size-16">{{ $t('transferProcessDescription') }} 
            <router-link :to="`/${$t('path.nameChangeOfOwnership')}`">
              {{ $t('linkApproveTransfers') }} 
            </router-link>
          </div>


          <div class="subtitle-1 grey--text text--darken-2 pt-3"> {{ $t('transfer') }}</div>
          <v-divider class="mb-3 pb-3"></v-divider>

          
          <v-row aling="center" justify="space-between">
            <v-col cols="12" md="7" class="search-field pa-3">
                <v-autocomplete
                v-model="changeOwnershipNewRequestStore.changeOwnershipNewRequest.pointOfSaleIds"
                :items="changeOwnershipNewRequestStore.pointsOfSales"
                :label="$t('pointsOfSaleToBeTransferred')"
                item-text="title"
                multiple
                prepend-icon="mdi-point-of-sale"
                class="ma-0 pa-0"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="1" class="ma-0 pa-2">
              <help>
                <template #title>
                  {{ $t('pointsOfSaleHelpTitle') }}
                </template>
                {{ $t('pointsOfSaleHelpDescription') }}
              </help>
            </v-col>
            <v-col cols="12" md="3" class="search-field pa-3">
              <cnpj-text-filter
                :cnpj.sync="changeOwnershipNewRequestStore.changeOwnershipNewRequest.destinationCnpj"
                :loading="changeOwnershipNewRequestStore.loadingCnpjField"
                :cnpjIsValid.sync="changeOwnershipNewRequestStore.cnpjIsValid"
              >
              </cnpj-text-filter>
            </v-col>
            <v-col cols="12" md="1">
              <help>
                <template #title>
                  {{ $t('cnpj') }}
                </template>
                {{ $t('cnpjHelpDescription') }}
              </help>
            </v-col>
          </v-row>
          <v-radio-group
            v-model="changeOwnershipNewRequestStore.enableChangeOwnershipSameFranchise"
            :label="$t('sameNetworkTransfer')"
            prepend-icon="mdi-swap-horizontal-bold"
            required>
            <v-radio :label="$t('yes')" value="true"></v-radio>
            <v-radio :label="$t('no')" value="false"></v-radio>
          </v-radio-group>


          <div class="subtitle-1 grey--text text--darken-2"> {{ $t('termsAndConditions') }}</div>
          <v-divider class="mb-3 pb-3"></v-divider>
          <div class="font-size-16"><b>{{ $t('termsAndConditionsDescription') }}</b></div>
          <div class="font-size-16">
            <a href="https://www.youtube.com/watch?v=Tb_7f2_hmSw" target="_blank" rel="noopener noreferrer" > 
              <i class="mdi mdi-youtube" style="font-size: 20px"> </i>{{$t('watchVideo')}}</a>
          </div>
          <v-radio-group v-model="changeOwnershipNewRequestStore.enableChangeOwnershipAgreedTermsOfPOS">
            {{ $t('termsOfResponsibilityPOS') }}
            <v-radio :label="$t('agreeWithTermsOfResponsibilityPOS')" required value="true"
            ></v-radio>
          </v-radio-group>
          <v-radio-group class="mt-0" v-model="changeOwnershipNewRequestStore.enableChangeOwnershipAgreedTermsOfTransfer">
            {{ $t('termOfResponsibilityTransfer') }}
            <v-radio :label="$t('agreeWithTermOfResponsibilityTransfer')" required value="true"
            ></v-radio>
          </v-radio-group>
          <div class="font-size-16">{{ $t('termsAndConditionsHelp') }} <a href="https://api.whatsapp.com/send/?phone=551151996668&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer" > 
            (11) 5199-6668</a>.</div>

          <v-row>
            <v-col cols="12" md="10">
            </v-col>
            <v-col cols="12" md="2">
              <v-btn
                :disabled="!changeOwnershipNewRequestStore.enableChangeOwnershipAgreedTermsOfPOS
                  || !changeOwnershipNewRequestStore.enableChangeOwnershipAgreedTermsOfTransfer
                  || !changeOwnershipNewRequestStore.enableChangeOwnershipSameFranchise
                  || !changeOwnershipNewRequestStore.enableChangeOwnershipDebtFreeOrigin
                  || !changeOwnershipNewRequestStore.enableChangeOwnershipDebtFreeDestination
                  || changeOwnershipNewRequestStore.changeOwnershipNewRequest.pointOfSaleIds.length === 0"
                block
                color="primary"
                type="submit"
                @click="changeOwnershipNewRequestStore.createChangeOwnershipNewRequest"
              >
                {{ $t('submitRequest') }}
              </v-btn>

            </v-col>
          </v-row>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import CnpjTextFilter from '@/components/textField/CnpjTextFilter.vue';
import {useChangeOwnershipNewRequestStore} from "@/stores/changeOwnershipNewRequestStore";
import {watch} from "vue";

export default {
  components: {
    CnpjTextFilter
  },
  data() {
    return {
      changeOwnershipNewRequestStore: useChangeOwnershipNewRequestStore()
    };
  },
  methods: {
    loadPointOfSales() {
      const user = this.$auth.user();
      const tenantId = this.$auth.user().tenantId;
      const groupId = this.$auth.user().tenantGroupId;
      this.changeOwnershipNewRequestStore.changeOwnershipNewRequest.originTenantId = tenantId;
      this.changeOwnershipNewRequestStore.changeOwnershipNewRequest.originGroupId = groupId;
      this.changeOwnershipNewRequestStore.getAllPointOfSales(user)
      this.changeOwnershipNewRequestStore.checkIfCustomerIsDebtFree(true,tenantId,groupId);
    }
  },
  mounted() {
    this.loadPointOfSales();


    watch(
      () => this.changeOwnershipNewRequestStore.changeOwnershipNewRequest.destinationCnpj,
      async () => {
        if (this.changeOwnershipNewRequestStore.cnpjIsValid)
        {
          await this.changeOwnershipNewRequestStore.verifyIfCnpjHasAEnvironment();
        }
      }
    );
  }
};
</script>
<style scoped>
.font-size-16 {
  font-size: 16px;
} 
</style>
