<template>
  <v-card class="mb-16">
    <portal to="toolbarTitle">
      <help>
        <template #title>
          Auditoria
        </template>
          <p align="justify">
            Aqui é exibida a auditoria de edições no sistema WEB. A auditoria serve
            para que os administradores possam ver quais usuários criaram, editaram ou apagaram
            certos dados do sistema, auxiliando assim no rastreio de erros no uso do sistema.
          </p>

          <help-video  help-code="Audit"/>
      </help>
    </portal>

    <audits-filter
      @modification="getModification"
      :audit-categories="auditCategories"
    ></audits-filter>

    <v-card-title>
      <div class="title">
        <v-icon left>mdi-clipboard-text-search-outline</v-icon>Auditoria
        <help-text :auditCategories="form.auditController"></help-text>
      </div>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-divider></v-divider>

    <v-data-table
      :headers="headers"
      :items="auditEvents"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading.table"
      :footer-props="{ itemsPerPageOptions: [10, 30, 100] }"
    >
      <template #[`item.date`]="{ item }">
        {{ formatDate(item.date) }}
      </template>
      <template #[`item.actionName`]="{ item }">
        <v-chip :color="item.actionColor">
          {{ item.mappedActionName }}
        </v-chip>
      </template>
    </v-data-table>


    <feedback ref="feedback"></feedback>
  </v-card>
</template>

<script>
import AuditsFilter from '@/views/audits/components/AuditsFilter.vue';
import { shouldRefreshData, toApiPagination } from '@/components/TableOptionsComparator';
import ActionsColors from '@/views/audits/ActionsColors.js';
import moment from 'moment';
import AuditsHelpText from '@/views/audits/helpers/AuditsHelpText';
import HelpVideo from '@/components/HelpVideo.vue'


export default {
  components: {
    AuditsFilter,
    'help-text': AuditsHelpText,
    HelpVideo
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getPlanogramAudits();
        }
      },
    },
  },
  mounted() {
    this.getAuditCategories();
  },
  data () {
    return {
      headers: [
        { text: 'Ip', align: 'left', sortable: true, value: 'ipAddress' },
        { text: 'Usuário', align: 'left', sortable: false, value: 'email' },
        { text: 'Data e horário', align: 'left', sortable: true, value: 'date' },
        { text: 'Parâmetros', align: 'left', sortable: true, value: 'parameters'},
        { text: 'Ação', align: 'left', sortable: true, value: 'actionName' }
      ],
      actionNames: [],
      auditCategories: [],
      loading: {
        table: false,
        filter: false
      },
      auditEvents: [],
      form: {},
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["date"],
        sortDesc: [true],
        mustSort: true
      },
      totalItems: 0,
      totalPages: 0,
    }
  },
  methods: {
    getAuditCategories() {
      this.loading.filter = true;
      this.axios.get('/api/Audits/categories')
        .then((response) => {
          this.auditCategories = response.data;
        })
        .catch((error) => this.$refs.feedback.handleError(error))
        .then(this.loading.filter = false);
    },
    async getPlanogramAudits() {
      this.loading.table = true;

      let params = toApiPagination(this.options);
      params = this.insertFiltersFromForm(params);

      await this.axios.get('api/Audits', { params: params })
        .then((response) => {
      const auditItems = response.data.items;
      for(const item of auditItems) {
        item.actionColor = this.mapActionColor(item.actionName);
        item.mappedActionName = this.mapActionName(item.actionName);
      }
            this.auditEvents = auditItems;
            this.auditEvents.map(e => e.parameters = JSON.stringify(e.parameters));
            this.totalItems = response.data.totalItems;
            this.totalPages = response.data.totalPages;
          })
          .catch((error) => this.$refs.feedback.handleError(error))
          .finally(() => this.loading.table = false)
      },
    async getModification(form) {
      this.form = form;
      this.options.page = 1;
      this.actionNames = this.auditCategories.find(el => el.name == form.auditController).actions;
      // The loop below applies the color to the actions objects
      for (let i = 0; i < this.actionNames.length; i++) {
        this.actionNames[i].color = ActionsColors.auditColorsPallete[i] ?? null;
      }
      await this.getPlanogramAudits();
    },
    insertFiltersFromForm(params) {
      let form = Object.assign({}, params);
      Object.assign(form, this.form);
      return form;
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY, HH:mm');
    },
    mapActionName(action) {
      const actionObject =  this.actionNames.find(ac => ac.value == action);
      return actionObject != undefined ? actionObject.description : '';
    },
    mapActionColor(action) {
      return this.actionNames.find(a => a.value == action).color;
    }
  }

}
</script>

<style>

</style>
