export default {
    titleNegativeItemsDialog: 'Productos sin stock',
    descriptionNegativeItemsDialog: `Aquí se muestran los productos que no tienen suficiente stock en el centro de distribución 
    para la generación de las listas de selección. Al hacer clic en <strong>Detalles</strong> de un producto específico,
    se puede ver las listas de selección pendientes que lo incluyen. También es posible
    editar las cantidades de producto en estas listas de selección.`,
    content3NegativeItemsDialog: `La opción <strong>Redistribuir artículo</strong> dentro de <strong>Detalles</strong> cambia
    las cantidades del artículo seleccionado en las listas de selección. Este cambio se realiza de manera que
    la relación entre la cantidad actual y la cantidad deseada sea lo más similar posible entre
    los PDVs.`,
    noteNegativeItemsDialog: `Para que esta información esté disponible, es necesario tener habilitado el sistema de gestión y 
    el stock central en el sistema TouchPay.`,
    searchLabelNegativeItemsDialog: 'Buscar producto',
    searchLabelNegativeItemsNegativeItemsDialog: 'Buscar',
    headersIdNegativeItemsDialog: 'ID',
    headersProductNegativeItemsDialog: 'Producto',
    headersProductCodeNegativeItemsDialog: 'Código de producto',
    headersStockNegativeItemsDialog: 'Stock',
    headersQtyInStockNegativeItemsDialog: 'Cantidad en stock',
    headersReservationsNegativeItemsDialog: 'Reservas',
    headersBalanceNegativeItemsDialog: 'Balance',
    headersDetailsNegativeItemsDialog: 'Detalles',
    saveButtonNegativeItemsDialog: 'Guardar',
    closeButtonTextNegativeItemsDialog: 'Cerrar',
};
