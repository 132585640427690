const transactionResults = {}
transactionResults['PendingPayment'] = "Pagamento pendente";
transactionResults['PinRequested'] = "Desconhecido (senha pedida)";
transactionResults['PinOk'] = "Desconhecido (senha verificada)";
transactionResults['Paid'] = "Desconhecido (pago)";
transactionResults['Ok'] = "Ok";
transactionResults['PaymentError'] = "Erro com o pagamento";
transactionResults['VendFailure'] = "Falha ao dispensar";
transactionResults['Cancelled'] = "Cancelado";
transactionResults['Refunded'] = "Estornada";
transactionResults['RefundedCancelled'] = "Erro ao estornar";
transactionResults['ProductNotSelected'] = "Produto não selecionado";
transactionResults['Dispute'] = "Em disputa";

export { transactionResults };
