<template>
  <v-dialog persistent v-model="show" max-width="600" @keydown.esc="show = false">

    <template #activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>

    <v-form
        ref="pickListForm"
        lazy-validation
        v-model="valid"
        @submit.prevent="createPickLists"
    >
      <v-card>
        <v-card-title>
          <span class="title">{{ $t('titlePickListCreationDialog') }}</span>
        </v-card-title>

        <v-card-text>
          <v-tooltip bottom :disabled="!disableDescriptionTextField">
            <template #activator="{ on }">
              <div v-on="on">
                <v-text-field
                    id="picklist-description"
                    :label="$t('descriptionLabelPickListCreationDialog')"
                    v-model="form.description"
                    @input="userHasEditedName = true"
                    :disabled="disableDescriptionTextField">
                </v-text-field>
              </div>
            </template>
            <span>{{ $t('descriptionTooltipPickListCreationDialog') }}</span>
          </v-tooltip>

          <v-radio-group v-show="!purchaseSuggestions" v-model="creationType">
            <v-radio :label="$t('allTheProductsPickList')" value="all"></v-radio>
            <v-radio :label="$t('categoryLabelPickListCreationDialog')" value="category"></v-radio>
          </v-radio-group>
          <v-autocomplete
              v-model="categories.selected"
              v-if="creationType === 'category'"
              :loading="categories.loading"
              :items="categories.items"
              item-text="name"
              item-value="id"
              multiple
              chips
          >
          </v-autocomplete>
          <v-checkbox v-show="!purchaseSuggestions" :label="$t('criticalLabelPickListCreationDialog')"
                      v-model="form.critical"></v-checkbox>

          <v-expansion-panels flat hover>
            <v-expansion-panel>
              <v-expansion-panel-header>{{ $t('advancedSectionPickListCreationDialog') }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-checkbox :label="$t('secondaryLabelPickListCreationDialog')" v-model="form.secondary"></v-checkbox>
                <v-tooltip bottom :disabled="!disableGroupByCategory">
                  <template #activator="{ on }">
                    <div v-on="on">
                      <v-checkbox 
                        v-model="form.groupByCategory"
                        :label="$t('groupByCategoryLabelPickListCreationDialog')"
                        :disabled="disableGroupByCategory"
                        class="pt-0 mt-0"
                      ></v-checkbox>
                    </div>                  
                  </template>
                  <span v-if="disableGroupByCategory">
                    {{ $t('groupByCategoryDisabledTooltip') }}
                  </span>
                </v-tooltip>                
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click.native="show = false">Fechar</v-btn>
          <v-btn
              color="primary"
              type="submit"
              :disabled="creationType == null || !valid"
              :loading="creating"
          >{{ $t('generatePickListsButton') }}
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-form>
    <feedback ref="feedback"></feedback>
  </v-dialog>
</template>

<script>

const formInitialState = {
  critical: false,
  groupByCategory: false,
  secondary: false,
  description: '',
};

export default {
  props: {
    selectedPos: {type: Array, required: true},
    purchaseSuggestions: {type: Array, required: false}
  },
  computed: {
    disableGroupByCategory() {
      return !this.form.secondary || this.creationType != 'category' || this.categories.selected.length == 0;
    },
    disableDescriptionTextField() {
      return this.form.groupByCategory;
    }
  },
  watch: {
    show: function (enabled) {
      if (enabled) {
        this.valid = true;
        this.creationType = 'all';
      } else {
        this.$refs.pickListForm.reset();
      }
    },
    disableGroupByCategory(value) {
      if (value == false) {
        this.form.groupByCategory = false;
      }
    },
    'categories.selected'(value) {
      if (this.form.groupByCategory == false && this.userHasEditedName == false) {
        const categoriesNames = this.categories.items.filter(c => value.includes(c.id)).map(c => c.name);
        this.form.description = categoriesNames.join(", ");
      }
    },
  },
  data() {
    return {
      show: false,
      valid: true,
      creating: false,
      creationType: 'all',
      categories: {
        loading: true,
        items: [],
        selected: []
      },
      form: {...formInitialState},
      userHasEditedName: false,
    };
  },
  mounted() {
    this.axios.get('api/productCategories')
        .then(response => {
          this.categories.loading = false;
          this.categories.items = response.data;
        });
  },
  methods: {
    createPickLists() {
      this.creating = true;
      if (this.form.critical == null) this.form.critical = false;
      const request = {
        pointOfSaleIds: this.selectedPos,
        productCategoryIds: this.creationType === 'category' ? this.categories.selected : null,
        critical: this.form.critical,
        isSecondary: this.form.secondary,
        description: this.form.description,
        purchaseSuggestions: this.purchaseSuggestions,
        groupByCategory: this.form.groupByCategory
      };
      if (this.purchaseSuggestions) {
        request.pointOfSaleId = this.selectedPos[0];
        this.axios.post('api/PickLists', request)
            .then( () => {
              this.creating = false;
              this.show = false;
              this.$emit('pick-list-creation');
            })
            .catch((error) => this.$emit("single-picklist-creation-error", error))
            .finally(() => this.creating = false);
      } else {
        this.axios.post('api/PickLists/Bulk', request)
            .then((response) => {
              this.creating = false;
              this.show = false;
              this.$emit('pick-list-creation', response.data);
            })
            .catch((error) => this.handleError(this.$t('pickListCreationError'), error))
            .finally(() => Object.assign(this.form, {...formInitialState}));
      }
    },
    handleError(message, error) {
      this.$refs.feedback.handleError(message, error);
    },

  }
};
</script>