export default {
  welcome : '¡Bienvenido!',
  emailLabel: 'Correo electrónico',
  passwordLabel: 'Contraseña',
  keepConnected: 'Mantenerme conectado',
  buttonLogin: 'Iniciar sesión',
  buttonContinue: 'Continuar',
  forgotPassword: '¿Olvidaste la contraseña?',
  clickHere: '¡Haz clic aquí!',
  recoverPassword: 'Recuperar contraseña',
  returnToLogin: 'Volver al inicio de sesión',
  seeEmailBox: 'Verifica tu bandeja de entrada de correo electrónico',
  systemUnavailable: 'Sistema no disponible, inténtalo de nuevo más tarde',
  userInvalidPassword: 'Usuario o contraseña inválidos',
  royaltyBlocking: 'Tu acceso está bloqueado. Por favor, contacta a tu franquiciador.',
  paymentBlock: 'Tu acceso está bloqueado. Por favor, comunícate con el administrativo al número (11) 5199-6668.',
  pendingContract: 'Hay pendientes en tu contrato. Por favor, comunícate con el administrativo al número (11) 5199-6668.',
  somethingWentWrong: '¡Ops! Algo salió mal',
  fillInEmail: 'Ingresa tu correo electrónico.',
  fillInPassword: 'Ingresa la contraseña.',
  hasFinancialIssues: 'Tu acceso está bloqueado. Haz clic en el botón para ver si hay problemas financieros. Si el problema persiste, por favor, contacta al administrativo al número (11) 5199-6668.',
  accountsDepartment: 'Departamento de Finanzas',
  twoFactorCode: 'Código de autenticación',
  twoFactorFailed: 'No se pudo validar el código de autenticación, por favor verifícalo nuevamente.',
  trustPeriodText: 'Si el pago ya se realizó pero aún está en proceso, puedes hacer clic en el botón a continuación para desbloquear tu acceso por 3 días. Si el pago no se confirma dentro de este período, tu acceso se bloqueará nuevamente, y esta opción no estará disponible.',
  trustPeriodUpdateFailed: 'No se pudo actualizar el período de confianza. Por favor, contacta al administrativo al +55 (11) 5199-6668.',
  trustPeriodButton: 'Período de confianza',
  trustPeriodDialogText: 'Esto desbloqueará tu acceso por 3 días. Si el pago no se confirma dentro de este período, tu acceso se bloqueará nuevamente, y esta opción no estará disponible. ¿Deseas continuar?',
  buttonConfirm: 'Confirmar',
  buttonCancel: 'Cancelar'
};
