const inventoryOperationTypes = {
  Correction: "Correção",
  Sale: "Venda",
  Purchase: "Compra",
  Reversed: "Reversão Operação",
  AutomaticCorrection: "Correção automática",
  Supply: "Abastecimento",
  Expired: "Validade vencida",
  Damaged: "Produto danificado",
  Return: "Devolução",
  RemovedFromInventory: "Remoção do Inventário",
  Other: "Outros",
  ExpirationDateChanged: "Data de validade alterada",
  ExpirationDateAdded: "Data de validade adicionada",
  ExpirationDateRemoved: "Data de validade removida",
}; 

// obsolete Operation Types not to be used
const allInventoryOperationTypes = {
  ...inventoryOperationTypes,
  Full: "Completo",
  PickList: "Pick-list",
  Repositioning: "Reposicionamento",
  PlanogramChange: "Mudança de Planograma",
  Operation: "Abastecimento/Inventário",
};

export { inventoryOperationTypes, allInventoryOperationTypes };