export default {
    sortSearchTelemetry: 'Ordenar / Buscar',
    sortByTelemetry: 'Ordenar por',
    ascendingTelemetry: 'Ascendente',
    descendingTelemetry: 'Descendente',
    searchTelemetry: 'Buscar',
    updateTelemetry: 'Actualizar',
    lastUpdateTelemetry: 'Última actualización:',
    planogramPendingTelemetry: 'Planograma pendiente de activación',
    planogramActiveTelemetry: 'Planograma activo',
    noPlanogramTelemetry: 'Sin planograma registrado',
    remoteCreditTelemetry: 'Enviar crédito remoto',
    helpTelemetry: 'Ir al vídeo de ayuda',
    salesTodayTelemetry: 'Hoy',
    salesSevenDaysTelemetry: '7 días',
    salesthirtyDaysTelemetry: '30 días',
    transactionsTelemetry: 'Ver transacciones de',
    lastTransactionTelemetry: 'Última venta hace',
    salesHealthTelemetry: 'Rendimiento actual de ventas:',
    poorTelemetry: 'Malo',
    goodTelemetry: 'Bueno',
    averageTelemetry: 'Promedio',
    outOfServiceTelemetry: 'Fuera de servicio: ',
    unknownReasonTelemetry: 'razón desconocida',
    screenOffTelemetry: 'pantalla apagada',
    cardReaderFailTelemetry: 'verificar lector de tarjetas',
    disconnectedTelemetry: 'sin conexión a internet',
    outOfServiceNoPowerTelemetry: 'sin energía',
    outOfServiceMachineTelemetry: 'máquina averiada',
    deadBatteryTelemetry: 'sin conexión por falta de energía',
    onlineTelemetry: 'En línea',
    offlineTelemetry: 'Fuera de línea',
    alertTelemetry: 'Alerta',
    noLauncherTelemetry: 'La aplicación no es el lanzador predeterminado',
    noPowerTelemetry: 'Sistema de pago no conectado a la energía',
    maybeOutOfServiceTelemetry: 'Mantenimiento o reabastecimiento posible de la máquina',
    maybeDisconnectedTelemetry: 'Posible pérdida de conexión',
    maybeCardReaderFailTelemetry: 'Posible problema con el lector de tarjetas',
    stockPercentageTelemetry: '% de stock',
    productsTelemetry: ' productos',
    belowMinimumCriticalTelemetry: ' productos por debajo del mínimo crítico',
    thereAreTelemetry: 'Hay',
    toEditTelemetry: 'Editar',
    replenishmentTelemetry: 'Reabastecer',
    restartTerminalTelemetry: 'Reiniciar terminal',
    pushNotificationTelemetry: 'Notificación push',
    unlockDoorTelemetry: 'Desbloquear una cerraduras',
    posTelemetry: 'PDV ',
    codeTelemetry: '/ Código',
    systemTelemetry: 'Sistema ',
    versionTelemetry: 'Versión: ',
    serialTelemetry: 'Serial: ',
    periodTelemetry: 'Período',
    quantityTelemetry: 'Cant.',
    priceTelemetry: 'Precio',
    statusTelemetry: 'Estado',
    clientNameTelemetry: 'Nombre del cliente',
    stockTelemetry: 'Stock',
    pointOfSaleTelemetry: 'Fecha de creación (ID del punto de venta)',
    lastSaleTelemetry: 'Última venta',
    outdatedVersion: 'Versión desactualizada',
};
