
import Vue from 'vue';

export type productMinimumPurchase = {
  id: number
  productConversionUnitName: string,
  productConversionUnitRatio: number,
  minimumPurchaseQuantity: number
}

export default Vue.extend({
  props: {
    value: { type: Boolean, required: true },
    productConversionUnit: { type: Object, default: () => {} } // type: productMinimumPurchase
  },
  watch: {
    productConversionUnit: {
      handler(value: productMinimumPurchase): void {
        this.conversionUnitQuantity = value.minimumPurchaseQuantity / value.productConversionUnitRatio || 0;
      },
      deep: true
    }
  },
  mounted() {
    this.getConversionUnitsList();
  },
  computed: {
    /**
     * The inventory item's Minimum Purchase Quantity.
     * @param {number} this.conversionUnitQuantity - The amount of minimum purchase items in terms of the products's Conversion Unit. This IS NOT the Minimum Purchase Quantity.
     * @param {number} this.productConversionUnit.productConversionUnitRatio - The product's Conversion Unit ratio.
     * @returns {number} - Returns the value that will be registered in the DB as the MinimumPurchaseQuantity of the inventory item.
     */
    minimumPurchaseQuantity(): number {
      return this.conversionUnitQuantity * this.productConversionUnit.productConversionUnitRatio;
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('close', value);
      }
    },
    minimumPurchaseText(): string {
      const unit = this.conversionUnits.find(cu => cu.value == this.productConversionUnit.productConversionUnitName)?.text ?? "";
      const text =  unit + `${this.conversionUnitQuantity > 1 ? 's' : ''} de ` +
        this.productConversionUnit.productConversionUnitRatio + " unidade"
        + `${this.productConversionUnit.productConversionUnitRatio != 1 ? "s" : ""}`;
      return text;
    }
  },
  data() {
    return {
      conversionUnits: [] as any[],
      conversionUnitQuantity: null as unknown as number, // This is NOT sent to the form.
      loading: false as boolean,
      valid: true as boolean
    }
  },
  methods: {
    getConversionUnitsList(): void {
      this.axios.get("api/Products/conversionUnitsList")
        .then((response) => {
          this.conversionUnits = response.data;
        })
    },
    setMinimumQuantity() {
      if ((this.$refs.form as any).validate()) {
        this.loading = true
        this.axios.patch(`api/web/inventory/itemMinimumPurchaseQuantity/${this.productConversionUnit.id}`,
          { minimumPurchaseQuantity: this.minimumPurchaseQuantity } )
          .then((response) => {
            this.loading = false;
            this.show = false;
            this.$emit("update", response.data)
          })
          .catch((error: any) => {
            (this.$refs.feedback as any).handleError("Não foi possível editar a Quantidade Mínima de Compra", error);
            this.loading = false;
          });
      }
    },
    decreasePurchaseQuantity() {
      this.conversionUnitQuantity = Math.ceil(Number(this.conversionUnitQuantity) - 1);
    },
    increasePurchaseQuantity() {
      this.conversionUnitQuantity = Math.floor(Number(this.conversionUnitQuantity) + 1);
    }
  }
})
