export default {
    systemPaymentPointOfSale: "Sistema de pago",
    systemCodePointOfSale: "Código del sistema",
    enabledInvoicePointOfSale: "Factura habilitada",
    disabledInvoicePointOfSale: "Factura no habilitada",
    configurePointOfSale: "Configurar",
    appEnabledPointOfSale: "App habilitada",
    appDisabledPointOfSale: "App no habilitada",
    displayPointOfSale: "Visualizar",
    registerPointOfSale: "Registrar",
    picPayPointOfSale: "PicPay",
    picPayTokenPointOfSale: "Token de PicPay",
    registeredLocksPointOfSale: "Cerraduras registradas",
    noLocksPointOfSale: "Sin cerraduras registradas",
    changePointOfSale: "Cambiar",
    addPointOfSale: "Agregar",
    thenPointOfSale: "luego",
    clientPointOfSale: "Cliente",
    locationPointOfSale: "Ubicación",
    internalLocationPointOfSale: "Ubicación interna",
    statePointOfSale: "Estado",
    residentsQuantityPointOfSale: "Cantidad de residentes",
    employeesQuantityPointOfSale: "Cantidad de empleados",
    studentsQuantityPointOfSale: "Cantidad de estudiantes",
    frequentPeopleQuantityPointOfSale: "Cantidad frecuente de personas",
    stockPointOfSale: "Stock",
    distributionCenterPointOfSale: "Centro de distribución",
    sharedStockPointOfSale: "Stock compartido",
    posCodePointOfSale: "Código de PDV",
    manufacturerPointOfSale: "Fabricante",
    modelPointOfSale: "Modelo",
    updatePointOfSale: "Actualizar",
    unableToSavePosPointOfSale: "No se pudo guardar el punto de venta",
    okPointOfSale: "Ok",
    identificationCodePointOfSale: "Código identificador del sistema.",
    systemNotFoundPointOfSale: "Sistema no encontrado",
    tokensNotFoundPointOfSale: "Tokens no encontrados",
    selectClientPointOfSale: "Seleccione un cliente o agregue uno nuevo.",
    selectLocationPointOfSale: "Seleccione un cliente antes de elegir la ubicación",
    totalNumberOfResidentsPointOfSale: "Seleccione una ubicación antes de definir el número total de residentes",
    localTypePointOfSale: "Tipo de ubicación",
    nameDistributionCenterPointOfSale: "Nombre del centro de distribución",
    distributionCenterNotRegistered: "No hay centros de distribución registrados",
    distributionCenterConfiguration : `Aquí configuramos el centro de distribución asociado a este punto de venta.
    Cuando se realice un abastecimiento en este PDV, los productos se restarán de él.
    Además, al generar listas de selección para este PDV, este será
    el centro de distribución considerado para verificar la disponibilidad de cada producto.
    Las ventas realizadas en este PDV también tendrán el costo promedio correspondiente al costo promedio del
    producto del centro de distribución en el momento de la compra.`,
    cannotBeChangedPointOfSale: `Como el PDV tiene el stock compartido con el punto de venta {{ masterPointOfSaleName }},
    su stock central no se puede cambiar.`,
    accessToFunctionality: "Para acceder a esta funcionalidad, es necesario tener el módulo de stock contratado.",
    noPointsOfSalePointOfSale: "Sin puntos de venta",
    withMoreTerminalPointOfSale: "Para tiendas con más de un terminal",
    microMarketsFunction: `Esta función es solo para micro-mercados que tienen
    más de un terminal de pago en la misma tienda.`,
    terminalInTheSameStore: `En el caso de que haya más de un terminal en la misma tienda, es
    necesario un punto de venta para cada terminal. A pesar de
    ser PDVs distintos, al tratarse de una sola tienda,
    debe haber un solo planograma y un solo stock. De esta
    manera, cuando cualquiera de los PDVs de la tienda realiza una
    venta, el mismo stock se reduce.`,
    registrationAtOnlyOnePdv: `El planograma debe registrarse en solo un PDV, al que
    llamamos <strong>PDV principal</strong>. La consulta de
    stock también debe realizarse en el PDV principal. Los demás
    PDVs de la tienda son los <strong>PDVs secundarios</strong>.
    Todos los PDVs secundarios deben tener el PDV principal en
    el campo <strong>Stock compartido</strong>. Para el PDV
    principal, este campo permanece vacío.`,
    touchPayReplenishmentPointOfSale: "TouchPay Reabastecedor",
    machinePointOfSale: "Máquina",
    errorFindingTokenPointOfSale: "No se pudo encontrar un token para el PDV.",
    taxRegion: 'Región fiscal',
};
