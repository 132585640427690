
import Vue from 'vue';
// eslint-disable-next-line no-unused-vars
import { DivergentProduct } from '@/types/PriceTable';
// eslint-disable-next-line no-unused-vars
import {SingleOperationBulkResult} from "@/types/common/BulkOperation";

export default Vue.extend({
  props: {
    infoMessages: { type: Array, required: true }
  },
  data() {
    return {
      show: false,
      tab: 0,
    }
  },
  computed: {
    filteredInfoMessages() {
      return this.infoMessages.filter((im: any) => im.divergentProducts !== undefined);
    },
    absencesInPriceTablesPreFiltered(): SingleOperationBulkResult[] {
      return (this.filteredInfoMessages as SingleOperationBulkResult[])
        .filter((im: SingleOperationBulkResult) => im.divergentProducts!
          .some((dp: DivergentProduct) => dp.divergentProductType == "AbsentPriceTable"));
    },
    absencesInPriceTablesFiltered(): SingleOperationBulkResult[] {
      return (this.absencesInPriceTablesPreFiltered as SingleOperationBulkResult[])
        .map((item: SingleOperationBulkResult) : SingleOperationBulkResult => {
          const divergentProducts = item.divergentProducts!.filter(dp => dp.divergentProductType == "AbsentPriceTable")
          return {
            entityId: item.entityId,
            success: item.success,
            error: item.error,
            divergentProducts: divergentProducts
          }
        });
    },
    absencesInPlanogramsPreFiltered(): SingleOperationBulkResult[] {
      return (this.filteredInfoMessages as SingleOperationBulkResult[])
        .filter((im: SingleOperationBulkResult) => im.divergentProducts!
          .some((dp: DivergentProduct) => dp.divergentProductType == "AbsentPlanogram"));
    },
    absencesInPlanogramsFiltered(): SingleOperationBulkResult[] {
      return this.absencesInPlanogramsPreFiltered
        .map((item: SingleOperationBulkResult) : SingleOperationBulkResult => {
          const divergentProducts = item.divergentProducts!.filter(dp => dp.divergentProductType == "AbsentPlanogram")
          return {
            entityId: item.entityId,
            success: item.success,
            error: item.error,
            divergentProducts: divergentProducts
          }
        });
    },
    showButton(): boolean {
      const showButton = ((this.absencesInPriceTablesPreFiltered as SingleOperationBulkResult[]).length > 0
        || (this.absencesInPlanogramsPreFiltered as SingleOperationBulkResult[]).length > 0)
      return showButton;
    },
  },
  methods: {
    absencesInPriceTablesText(result: SingleOperationBulkResult, divergentProduct: DivergentProduct): string {
      if (result != undefined && divergentProduct != undefined) {
        return `${divergentProduct.productId} - ${divergentProduct.productDescription}`+
        ` está no PDV ${result.entityId} mas está ausente da tabela aplicada`;
      }
      else return '';
    },
    absencesInPlanogramsText(result: SingleOperationBulkResult, divergentProduct: DivergentProduct): string {
      if (result != undefined && divergentProduct != undefined) {
        return `${divergentProduct.productId} - ${divergentProduct.productDescription}`+
        ` está na tabela aplicada mas está ausente do PDV ${result.entityId}`;
      }
      else return '';
    }
  }
})
