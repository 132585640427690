export default {
    dialogProductsUnusedTitle: 'Productos sin movimientos',
    dialogProductsUnusedCloseButton: 'Cerrar',
    dialogProductsUnusedOpenConfirmDialog: 'Eliminar Productos',
    dialogProductsUnusedOpenConfirmDialogWithItems: 'Eliminar productos seleccionados',
    dialogProductsUnusedFilter30Days: '30 días',
    dialogProductsUnusedFilter60Days: '60 días',
    dialogProductsUnusedFilter90Days: '90 días',
    dialogProductsUnusedCodeProduct: 'Código del Producto',
    dialogProductsUnusedDescription: 'Descripción',
    dialogProductsUnusedTablePlanogramId: 'ID del Planograma',
    dialogProductsUnusedLastMovement: 'Último Movimiento',
    dialogProductsUnusedQuantity:'Cant.',
    dialogProductsUnusedNoMovement:'Sin movimientos',
    dialogProductsUnusedRemoveSelectedItems:'Seleccionar elementos para eliminar',
    dialogProductsUnusedErrorMessage: 'Error al cargar productos sin movimientos',
    dialogProductsUnusedHelpTitle: 'Productos sin movimientos',
    dialogProductsUnusedIntroParagraphHelp: 'En esta pantalla, puede listar los productos que no tuvieron movimientos en diferentes intervalos de tiempo. Las opciones predefinidas son:',
    dialogProductsUnusedFilterPeriod: 'Filtros de 30, 60 y 90 días: lista solo productos que no tuvieron movimientos de 30, 60 o 90 días anteriores hasta hoy, según el filtro seleccionado.',
    dialogProductsUnusedFilterPeriodSingleDate: 'Si prefiere seleccionar un período diferente al mencionado anteriormente, simplemente haga clic en el ícono del calendario y elija la fecha deseada para personalizar el intervalo de tiempo según sus necesidades.',
    dialogProductsUnusedFilterPeriodDescription: 'También puede eliminar productos del planograma sin movimientos. Marque "Seleccionar elementos para eliminar", elija los productos y haga clic en el botón rojo "eliminar productos" para eliminar del planograma.',
  };
  