
import Vue from 'vue';
import BarLineCombinedChart from '@/report/BarLineCombinedChart';
import { SalesDashboard } from '@/api/agent';
import SalesByWeekdayHelpVue from '../helpers-new/SalesByWeekdayHelp.vue';


export default Vue.extend({
  components: {
    "chart": BarLineCombinedChart,
    SalesByWeekdayHelpVue
  },
  props: {
    mustCallEndpoints: { type: Boolean, required: true },
    form: { type: Object, required: true }
  },
  data() {
    return {
      labels: [
        this.$t("sundayShort"),
        this.$t("mondayShort"),
        this.$t("tuesdayShort"),
        this.$t("wednesdayShort"),
        this.$t("thursdayShort"),
        this.$t("fridayShort"),
        this.$t("saturdayShort")
      ] as string[],
      datasetLabels: {
        thisWeek: this.$t("thisWeek"),
        previousWeek: this.$t("previousWeek"),
        average: this.$t("average"),
      } as {},
      datasets: [] as any[],
      loading: false,
      showChart: false,
      chartHeight: 350
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleChartSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleChartSize);
  },
  watch: {
    mustCallEndpoints: {
      handler(value) {
        if (value) {
          // endpoints calls go here
          // use the 'form' prop as param to endpoint call
          this.getSalesByWeekday();
          return value
        }
      },
      immediate: true
    }
  },
  methods: {
    async getSalesByWeekday() {
      this.loading = true
      this.showChart = false
      try {
        const form = { pointOfSaleIdList: this.form.pointOfSaleId }
        const response = await SalesDashboard.getSalesByWeekday(form)

        const entries = Object.entries(response);
        const backgroundColors = [
          (this.$vuetify.theme as any).defaults.dashboard.barColor,
          (this.$vuetify.theme as any).defaults.dashboard.secondaryYellow,
          (this.$vuetify.theme as any).defaults.dashboard.lightBlue2,
          (this.$vuetify.theme as any).defaults.dashboard.lightBlue
        ];
        const barPercentages = [1, .5, 1]        

        entries.forEach((entry, i) => {
          const key = entry[0]
          const data = entry[1]
          const dataValues = Object.values(data) as number[]

          
          this.datasets[i] = {
            label: this.datasetLabels[key as keyof {}],
            backgroundColor: backgroundColors[i],
            barPercentage: barPercentages[i],
            data: dataValues,
            type: "bar",
            fill: true,
            order: 1
          }
        })
        this.datasets[2].type = "line"
        this.datasets[2].fill = false
        this.datasets[2].tension = 0
        this.datasets[2].order = 0
        this.datasets[2].borderColor = backgroundColors[3]
      }
      catch (error) {
        (this.$refs.feedback as any).handleError(this.$t('salesByWeekdayGetError'), error)
      }
      finally {
        this.loading = false
        // this.showChart = true
        this.handleChartSize()
      }
    },
    handleChartSize() {
      this.showChart = false;
      
      switch(true) {
        case this.$vuetify.breakpoint.xs:
          this.chartHeight = 220;
          break;
        case this.$vuetify.breakpoint.sm:
          this.chartHeight = 400;
          break;
        case this.$vuetify.breakpoint.md:
          this.chartHeight = 330;
          break;
        default:
          this.chartHeight = 330;
      }

      setTimeout(() => {
        this.showChart = true;
      }, 100);
    },    
  }
})
