export default {
    tenantGroupDialogHelpTitle: 'Creation of new Groups',
    tenantGroupDialogHelpFirstParagraph: 'In this feature, you can create new Groups.',
    tenantGroupDialogHelpListElement1: `When the screen for creating new Groups is opened, the system
        searches and suggests the next available ID for the new Group.`,
    tenantGroupDialogHelpListElement2: `The <strong>CNPJ</strong> field is mandatory.
        <strong>It is emphasized that the filled CNPJ will be used for billing</strong>.`,
    tenantGroupDialogHelpListElement3: `The <strong>Group Name</strong> field is mandatory and must have between 5 and
        60 characters. <strong>It is suggested to use the trade name associated with the CNPJ</strong>.`
  }
  