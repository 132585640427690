export default{
    dialogProductsUnusedTitle: 'Produtos sem movimentações',
    dialogProductsUnusedCloseButton: 'Fechar',
    dialogProductsUnusedOpenConfirmDialog: 'Remover Produtos',
    dialogProductsUnusedOpenConfirmDialogWithItems: 'Remover produtos selecionados',
    dialogProductsUnusedFilter30Days: '30 dias',
    dialogProductsUnusedFilter60Days: '60 dias',
    dialogProductsUnusedFilter90Days: '90 dias',    
    dialogProductsUnusedCodeProduct: 'Código do Produto',
    dialogProductsUnusedDescription: 'Descrição',
    dialogProductsUnusedTablePlanogramId: 'ID do Planograma',
    dialogProductsUnusedLastMovement: 'Última Movimentação',
    dialogProductsUnusedQuantity:'Qtd.',
    dialogProductsUnusedNoMovement:'Sem movimentações',
    dialogProductsUnusedRemoveSelectedItems: 'Selecionar itens para remover',
    dialogProductsUnusedErrorMensage: 'Erro ao carregar produtos sem movimentações',
    dialogProductsUnusedHelpTitle: 'Produtos sem movimentações',
    dialogProductsUnusedIntroParagraphHelp: 'Nesta tela, você pode listar os produtos que não tiveram movimentação em diferentes intervalos de tempo. As opções pré-definidas são:',
    dialogProductsUnusedFilterPeriod:'Filtros de 30, 60 e 90 dias: lista apenas produtos que não tiveram movimentações de 30, 60 ou 90 dias anteriores até hoje, conforme o filtro selecionado.',
    dialogProductsUnusedFilterPeriodSingleDate: 'Caso você prefira selecionar um período diferente dos mencionados acima, basta clicar no ícone do calendário e escolher a data desejada para personalizar o intervalo de tempo conforme sua necessidade.', 
    dialogProductsUnusedFilterPeriodDescription: 'Você também pode remover produtos do planograma sem movimentação. Marque "Selecionar itens para remover", escolha os produtos e clique no botão vermelho "remover produtos" para remover do planograma.',
};