export default {
    sortSearchTelemetry: 'Sort/Search',
    sortByTelemetry: 'Sort',
    ascendingTelemetry: 'Ascending',
    descendingTelemetry: 'Descending',
    searchTelemetry: 'Search',
    updateTelemetry: 'Update',
    lastUpdateTelemetry: 'Last update:',
    planogramPendingTelemetry: 'Planogram pending activation',
    planogramActiveTelemetry: 'Planogram active',
    noPlanogramTelemetry: 'No planogram registered',
    remoteCreditTelemetry: 'Send remote credit',
    helpTelemetry: 'Go to help video',
    salesTodayTelemetry: 'Today',
    salesSevenDaysTelemetry: '7 days',
    salesthirtyDaysTelemetry: '30 days',
    transactionsTelemetry: 'View transactions from',
    lastTransactionTelemetry: 'Last sale ago',
    salesHealthTelemetry: 'Current sales performance:',
    poorTelemetry: 'Poor',
    goodTelemetry: 'Good',
    averageTelemetry: 'On average',
    outOfServiceTelemetry: 'Out of service: ',
    unknownReasonTelemetry: 'unknown reason',
    screenOffTelemetry: 'screen off',
    cardReaderFailTelemetry: 'check card reader',
    disconnectedTelemetry: 'no internet connection',
    outOfServiceNoPowerTelemetry: 'out of power',
    outOfServiceMachineTelemetry: 'faulty machine',
    deadBatteryTelemetry: 'no connection due to lack of power',
    onlineTelemetry: 'Online',
    offlineTelemetry: 'Offline',
    alertTelemetry: 'Alert',
    noLauncherTelemetry: 'Application is not default launcher',
    noPowerTelemetry: 'Payment system not connected to power',
    maybeOutOfServiceTelemetry: 'Possible machine maintenance or refueling',
    maybeDisconnectedTelemetry: 'Possible connection drop',
    maybeCardReaderFailTelemetry: 'Possible problem with card reader',
    stockPercentageTelemetry: '%stock',
    productsTelemetry: 'products',
    belowMinimumCriticalTelemetry: 'products below critical minimum',
    thereAreTelemetry: 'There',
    toEditTelemetry: 'Edit',
    replenishmentTelemetry: 'Replenish',
    restartTerminalTelemetry: 'Restart terminal',
    pushNotificationTelemetry: 'Push notification',
    unlockDoorTelemetry: 'Unlock a lock',
    posTelemetry: 'POS ',
    codeTelemetry: '/Code',
    systemTelemetry: 'System ',
    versionTelemetry: 'Version: ',
    serialTelemetry: 'Serial: ',
    periodTelemetry: 'Period',
    quantityTelemetry: 'Qty.',
    priceTelemetry: 'Value',
    statusTelemetry: 'Status',
    clientNameTelemetry: 'Client name',
    stockTelemetry: 'Stock',
    pointOfSaleTelemetry: 'Creation date (Point of sale ID)',
    lastSaleTelemetry: 'Last sale',
    outdatedVersion: 'Outdated version',
  };
