
    import Vue from 'vue'
    import { UnusedPlanogramEntryRequest, ProductUnusedDto } from '@/types/PlanogramTypes'
    import agent from '@/api/agent'
    import { PaginatedList } from '@/types/common/PaginatedList'
    import { formatDateTimeToDate } from "@/utility/TextFormatting";
    import DateChipFilter from '@/components/filters/DateChipFilter.vue';
    import moment from 'moment';
    import SingleDatePicker from '@/components/filters/SingleDatePicker.vue';
    import DialogProductsUnusedHelp from './DialogProductsUnusedHelp.vue';

    export default Vue.extend({
        data() {
            return {
                showSingleDatePicker: null as string | null,
                intervalSingleDate: 0 as number | null,
                headers: [
                    { text: this.$t('dialogProductsUnusedCodeProduct') , align: 'left', sortable: false, value: 'productCode'},
                    { text: this.$t('dialogProductsUnusedDescription'), align: 'left', sortable: false, value: 'productDescription' },
                    { text: this.$t('dialogProductsUnusedTablePlanogramId'), align: 'left', sortable: false, value: 'planogramId' },
                    { text: this.$t('dialogProductsUnusedLastMovement'), align: 'left', sortable: false, value: 'lastUpdate'},
                    { text: this.$t('dialogProductsUnusedQuantity'), align: 'left', sortable: false, value: 'quantity'},
                ],
                options: {
                    itemsPerPage: 10 as number,
                    page: 1 as number,
                },
                productUnused: [] as ProductUnusedDto[],
                params: {} as UnusedPlanogramEntryRequest,
                totalItems: 0 as number,
                loading: false as boolean,
                dateIntervals: [
                    { text: this.$t('dialogProductsUnusedFilter30Days'), value: 30 },
                    { text: this.$t('dialogProductsUnusedFilter60Days'), value: 60},
                    { text: this.$t("dialogProductsUnusedFilter90Days"), value: 90},
                ],
                selectedDateInterval: 30 as number | null,
                dateFilter: new Date() as Date,
                selectAll: false as boolean,
                selectedItems: [] as number[],
                rules: {
                    required: (v: string) => !!v || this.$t('requiredRules'),
                },
            }
        },
        props: {
            value: {type: Boolean, default: false},
            planogramId: {type: Number, default: null},
            posId: { type: Number, default: null },
        },
        computed: {
            dialog: {
                get() : boolean {
                    if(this.value) {
                        this.getProductsUnused();
                    }
                    return this.value;
                },
                set(value : boolean) {
                    (this as any).$emit("close", value);
                }
            },
            computedHeaders(): any[] {
                if(this.selectAll){

                    return [ { text: '', align: 'left', sortable: false, value: 'checkbox' }, ...this.headers]
                }
                return this.headers;
            },
        },
        mounted() {
            this.showSingleDatePicker = moment().subtract(30, 'days').format("YYYY-MM-DD");
            
        },
        methods: {
            formatDateTimeToDate,
            closeDialog() {
                this.dialog = false;
            },
            getLastUpdateProductDate() {
                let dateNow = new Date(Date.now());
                
                if (this.selectedDateInterval !== null) {
                  this.dateFilter = new Date(dateNow.setDate(dateNow.getDate() - this.selectedDateInterval));
                }
                else if (this.intervalSingleDate !== null) 
                {
                    this.dateFilter = new Date(dateNow.setDate(dateNow.getDate() - this.intervalSingleDate));
                }
            },
            getProductsUnused() {
                this.getLastUpdateProductDate();
                this.loading = true;
                this.params = {
                    planogramId: this.planogramId,
                    lastUpdateProduct: this.dateFilter,
                    page: this.options.page,
                    pageSize: this.options.itemsPerPage,
                };

                agent.Planogram.getProductsUnused(this.params)
                    .then((response : PaginatedList<ProductUnusedDto>) => {
                        this.productUnused = response.items;
                        this.productUnused.forEach(product =>
                            product.selected = this.selectedItems.includes(product.productId) ? true : false
                        );
                        this.totalItems = response.totalItems;
                    })
                    .catch(error => {
                        this.$emit('error', this.$t('dialogProductsUnusedErrorMensage'), error);
                        this.dialog = false;
                    }).finally(() => {
                        this.loading = false;
                    });
                    
            },
            openConfirmDialog(){
                this.$emit('openCreatePlanogramUnusedProduct', this.dateFilter, this.selectedItems);
                this.dialog = false;
            },
            updateSelectedItems(item: ProductUnusedDto) {
                if (item.selected) {
                    this.selectedItems.push(item.productId);
                } else {
                    const index = this.selectedItems.indexOf(item.productId);
                    if (index > -1) {
                        this.selectedItems.splice(index, 1);
                    }
                }   
            },
            formatDate(date: string | null) : string {
                return moment(date).format("DD/MM/YYYY");
            },
            changeShowSingleDatePicker(value: number | null) {
                if(value != null){
                    this.showSingleDatePicker = moment().subtract(this.selectedDateInterval, 'days').format("YYYY-MM-DD");
                }
            },
            convertDatePickerToNumber(value: string | null){
               
                this.intervalSingleDate = moment().diff(moment(value), 'days');
                this.selectedDateInterval = null;
            }
        },
        components: {
            DateChipFilter,
            SingleDatePicker,
            DialogProductsUnusedHelp
        }
    })
    