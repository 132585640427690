<template>
  <v-autocomplete
    prepend-icon="mdi-shape"
    :items="categories"
    v-model="productCategoryId"
    :label="$t('categoryCategoriesDropDown')"
    item-text="description"
    item-value="id"
    single-line
    clearable
    :rules="rules"
  >  
  </v-autocomplete>  
</template>
<script>
export default {
  props: {
    resetComponent: {},
    value: {},
    rules: { type: Array }
  },
  data() {
    return {
      categories: [],
    };
  },
  computed: {
    productCategoryId: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  mounted() {
    // Load Categories dropdown when page is mounted
    this.axios.get("api/ProductCategories/details", {
      params: { page: 1, pageSize: -1, sortOrder: "Id", descending: false }
    })
    .then((response) => {
      this.categories = response.data.items.map(i => {
        i.description = i.id + " - " + i.name;
        return i;
      });
    });
  },
  methods: {
    resetDropDown() {
      this.productCategoryId = null;
    }
  }
}
</script>
<style>
</style>