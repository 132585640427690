export default{
    titleDetailDialog: 'INFORMACIÓN DE LA FACTURA',
    infoDetailDialog: 'Detalles',
    numberInvoiceDetailDialog: 'Número de la factura',
    keyDetailDialog: 'Clave de acceso (NFe)',
    dateEmmitedDetailDialog: 'Fecha de emisión',
    productsDetailDialog: 'Productos',
    addItemDetailDialog: 'Agregar producto',
    removeInvoiceDetailDialog: 'Eliminar',
    receiveProductDetailDialog: 'Recibir productos',
    validMessageDetailDialog: 'Para continuar, complete todos los datos',
    codeDetailDialog: 'Código',
    quantityInvoiceDetailDialog: 'Cantidad en la factura',
    unitPurchaseDetailDialog: 'Unidad de compra',
    unitTotalDetailDialog: 'Unidad total',
    unitValueDetailDialog: 'Valor unitario',
    valueTotalDetailDialog: 'Valor total',
    actionsDetailDialog: 'Acciones',
    rulesNumbers: 'Sólo se aceptan números',
    returnSuccess: 'La compra se realizó correctamente',
    returnError: 'No se puede ingresar la compra',
    unit: 'Unidad',
    box: 'Caja',
    bleats: 'Fardo',
    package: 'Paquete',
    unitUnknown: 'Desconocido',
    }
