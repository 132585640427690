<template>
  <!-- <v-layout row wrap>
    <v-flex xs12>
      <v-toolbar color="white">
        <v-btn><v-icon left>add</v-icon>Desconto</v-btn>
      </v-toolbar>
    </v-flex>
  </v-layout> -->
  <v-container fluid grid-list-lg align-content-start class="px-5">

    <portal to="toolbar" v-if="tab === 0">
      <new-discount-dialog @created="onDiscountCreated">
        <template #activator="{ on }">
          <v-btn v-on="on" text style="height:100%">
            <v-icon left>add</v-icon>Desconto
          </v-btn>
        </template>
      </new-discount-dialog>
    </portal>

    <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
    <feedback ref="feedback"></feedback>

    <new-coupon-dialog v-model="newCouponDialog.enabled" :discount="newCouponDialog.discount">
    </new-coupon-dialog>

    <coupons-dialog v-model="couponsDialog.enabled" :discount="couponsDialog.discount">
    </coupons-dialog>

    <!-- Remove dialog -->
    <v-dialog v-model="removeDialog.enabled" max-width="400">
      <v-card>
        <v-card-text class="pt-4">
          Tem certeza que deseja remover o desconto de {{ getDiscountText(removeDialog.discount) }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.native="removeDialog.enabled = false">Não</v-btn>
          <v-btn
            color="primary"
            :loading="removeDialog.loading"
            text
            @click.native="deleteDiscount(removeDialog.discount)"
          >Remover</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-layout row wrap>
      <v-flex xs6 md4 lg2 v-for="discount in discounts" :key="discount.id">
        <v-card>
          <v-card-title>
            <div class="title">{{ getDiscountText(discount) }}</div>
            <v-spacer></v-spacer>
            <v-btn text icon color="primary" class="ma-0" @click.native="confirmRemoval(discount)">
              <v-icon>delete</v-icon>
            </v-btn>
            <div class="info-subtitle mb-2" :style="{ visibility: discount.minThreshold > 0 ? 'visible' : 'hidden' }">
              Compra mínima: {{ $currencyFormatter.format(discount.minThreshold) }}
            </div>
            <v-btn 
              @click.native="couponsDialog.discount = discount; couponsDialog.enabled = true"
              :disabled="discount.length == 0"
              class="mx-0"
              outlined 
              small 
              color="primary"
            >
              {{ getCouponsText(discount) }}
            </v-btn>
          </v-card-title>
          <v-card-actions>
            <v-btn text small color="primary" @click.native="openNewCouponDialog(discount)">
              <v-icon left>add</v-icon>Cupons
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    
  </v-container>
</template>

<script>
import NewDiscountDialog from '@/discounts/NewDiscountDialog';
import NewCouponDialog from '@/discounts/NewCouponDialog';
import CouponsDialog from '@/discounts/CouponsDialog';

export default {
  props: {
    tab: { type: Number, default: 0}
  },
  data() {
    return {
      discounts: [],
      loading: true,
      removeDialog: {
        discount: {},
        enabled: false,
        loading: false
      },
      newCouponDialog: {
        enabled: false,
        discount: null
      },
      couponsDialog: {
        enabled: false,
        discount: null
      }
    };
  },
  mounted() {
    this.getDiscounts();
  },
  methods: {
    getDiscounts() {
      this.loading = true;
      this.axios.get('api/discounts')
        .then((response) => {
          this.loading = false;
          this.discounts = response.data;
        })
    },
    deleteDiscount(discount) {
      this.removeDialog.loading = true;
      this.axios.delete(`api/discounts/${discount.id}`)
        .then(() => {
          this.$refs.feedback.handleSuccess('Desconto removido');
          const index = this.discounts.indexOf(discount);
          this.discounts.splice(index, 1);
        }).catch(error => this.$refs.feedback.handleError('Problema ao remover desconto', error))
        .then(() => {
          this.removeDialog.loading = false;
          this.removeDialog.enabled = false;
        });
    },
    getDiscountText(discount) {
      if (discount.type === 'Percentage') return `${discount.value.toLocaleString('pt-BR')}%`;
      if (discount.type === 'Fixed') return this.$currencyFormatter.format(discount.value);
      else return discount.value;
    },
    getCouponsText(discount) {
      const couponCount = discount.coupons.length;
      if (couponCount > 1) return `${couponCount} cupons`;
      if (couponCount == 1) return "1 cupom";
      return "Sem cupons";
    },
    onDiscountCreated(discount) {
      this.$refs.feedback.handleSuccess('Desconto criado');
      this.discounts.push(discount);
    },
    confirmRemoval(discount) {
      this.removeDialog.discount = discount;
      this.removeDialog.enabled = true;
    },
    openNewCouponDialog(discount) {
      this.newCouponDialog.discount = discount;
      this.newCouponDialog.enabled = true;
    }
  },
  components: {
    NewDiscountDialog,
    NewCouponDialog,
    CouponsDialog,
  }
}
</script>