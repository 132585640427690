
  import Vue from 'vue';
  import Months from "@/components/Months";

  export default Vue.extend({
    props: {
      value: { type: String, default: null },
      rounded: { type: Boolean, default: true },
    },
    data() {
      return {
        months: Months.months,
      }
    },
    computed: {
    selectedMonth: {
      get(): string | null {
        return this.value;
      },
      set(value: string | null): void {
        this.$emit("input", value);
      },
    },
  },
  })
  