export default {
    alerts: 'Alerts',
    connectivity: 'Connectivity',
    telemetry: 'Telemetry',
    fiscalScenarios: 'Tax Scenarios',
    discounts: 'Discounts',
    fiscalNotes: 'Invoices',
    planograms: 'Planograms',
    salesReports: 'Sales Reports',
    dynamicSalesSummary: 'Dynamic Sales Summary',
    priceTables: 'Price Tables',
    transactions: 'Transactions',
    replenishment: 'Replenishment',
    purchases: 'Purchases',
    stocks: 'Stocks',
    pickLists: 'Pick Lists',
    products: 'Products',
    ruptureReports: 'Rupture Reports',
    operationalReports: 'Operational Reports',
    dynamicStockSummary: 'Dynamic Stock Summary',
    purchaseSuggestions: 'Purchasing Suggestions',
    help: 'Help',
    condominiumReports: 'Condominium Reports',
    dashboard: 'Dashboard',
    downloads: 'Downloads',
    releaseNotes: 'Release Notes',
    posRegistration: 'POS Registration',
    newTouchPay: 'New TouchPay',
    privateLabel: 'Private Label',
    users: 'Users',
    posView: 'POS View',
    evaluations: 'Evaluations',
    orders: 'Orders',
    promotionalLogos: 'Promotional Logos',
    pushNotifications: 'Push Notifications',
    appUsers: 'App Users',
    deviationAlerts: 'Deviation Alerts',
    audit: 'Audit',
    accessControl: 'Access Control',
    myBills: 'My Bills',
    activations: 'Activations',
    clients: 'Clients',
    pagSeguro: 'PagSeguro',
    swagger: 'Swagger',
    feedback: 'Feedbacks',
    missingProducts: 'Missing Products',
  };
