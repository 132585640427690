export default {
    filtersAndActions: 'Filters and actions',
    customerTransactions: 'Customer',
    minimumPriceTransactions: 'Minimum price',
    initialDateTransactions: 'Initial date',
    clearTransactions: 'Clear',
    updateTransactions: 'Update',
    excelTransactions: 'Excel',
    nfceTransactions: 'NFc-e',
    downloadXmlTransactions: 'Download XMLs',
    downloadExcelTransactions: 'Download Excel',
    reissueInBulk: 'Batch reissue',
    invalidateNumbering: 'Invalidate numbering',
    consultInvalidations: 'Consult invalidations',
    showOnlyInvoiceErrors: 'Show errors only',
    maxDateTransactions: 'End date',
    paymentTypeTransactions: 'Method',
    lastCardDigits: 'Last Card Digits',
    maxTimeTransactions: 'End Time',
    minTimeTransactions: 'Start Time',
    noResultsTransactions: 'No results',
    approvedInvoiceTransactions: 'NFC-e successfully generated, click for more details',
    invoiceErrorTransactions: 'Problem generating NFC-e, click for more details',
    notEnabledForPos: 'NFC-e not enabled for this POS',
    acquirerTransactions: 'Acquirer:',
    acquirerCode: 'Acquirer code:',
    destinationAccount: 'Destination account:',
    brandTransactions: 'Flag:',
    internalCode: 'Internal code:',
    discountCoupon: 'Discount coupon:',
    productCode: 'Product code',
    productTransactions: 'Product',
    productCategoryTransactions: 'Product category',
    quantityTransactions: 'Quantity',
    cmvTransactions: 'CMV({sign})',
    valueTransactions: 'Value({sign})',
    marginValueTransactions: 'Margin({sign})',
    marginPercentageTransactions: 'Margin(%)',
    selectionTransactions: 'Selection',
    resultTransactions: 'Result',
    locationTransactions: 'Location',
    posTransactions: 'POS',
    methodTransactions: 'Method',
    cardTransactions: 'Card',
    dateTimeTransactions: 'Date/Time',
    todayTransactions: 'Today',
    sevenDaysTransactions: 'Seven',
    unknownTransactions: 'Unknown',
    dispensingFailure: 'Failed to dispense',
    refundedTransactions: 'Reversed',
    refundCancelledTransactions: 'Chargeback canceled',
    refundErrorTransactions: 'Error reversing',
    productNotSelectedTransactions: 'Product not selected',
    selectClientFirstTransactions: 'Select a client first',
    transactionLocation: 'Location',
    maximumPriceTransactions: 'Maximum price',
    selectLocationTransactions: 'Select a location first',
    pointOfSaleTransactions: 'Point of sale',
    errorWhenDownloadingXml: 'The invoice XMLs could not be downloaded',
    errorDownloadingReport: 'The invoice report could not be downloaded',
    noneTransactions: 'none',
    noDate: 'No date',
    failureToCommunicate: 'Communication with the issuing system failed',
    errorWhenGeneratingReport: 'The report could not be generated',
    errorWhenGeneratingReportNFCe: 'The NFC-e report could not be generated',
    cashlessCard:'Card: ',
    showProfits: 'Show profits'
  };
