<template>
  <v-container fluid class="pa-5">
    <v-layout wrap fill-height>
      <v-flex xs12>
        <span class="subtitle-1">Adicionar novo email para alerta</span>
        
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-layout wrap class="mb-4">
            <v-flex xs12 sm8 md6 lg3>
              <v-text-field
                label="Email"
                v-model="newEmail"
                :rules="[(v) => /\S+@\S+\.\S+/.test(v) || 'Digite um email válido']"
                required
              ></v-text-field>
            </v-flex>
            <v-btn type="submit" color="success" @click.native="addNewEmail" :disabled="!valid">Adicionar</v-btn>
          </v-layout>
        </v-form>

        <span class="subtitle-1">Emails cadastrados</span>

        <v-flex xs12 md8 lg6>
          <v-data-table 
            :headers="headers" 
            :items="alertEmails" 
            hide-default-footer
            disable-pagination
            :loading="loading" 
            must-sort 
            class="elevation-1 mt-2" 
            no-data-text="Sem emails">
            <template #item="{ item }">
              <tr>
                <td>{{ item.email }}</td>
                <td><v-icon>{{ item.alertType === 1 ? 'check_circle' : 'cancel' }}</v-icon></td>
                <td class="justify-center px-0">
                  <v-btn icon class="mx-0" @click.native.stop="removeEmail(item)">
                    <v-icon color="error">delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>

      </v-flex>
    </v-layout>

    <v-dialog v-model="removeDialog" max-width="400">
      <v-card>
        <v-card-text class="pt-4">Tem certeza que deseja remover o email {{emailToRemove.email}}?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click.native="removeDialog = false">Não</v-btn>
          <v-btn color="green darken-1" text @click.native="onRemoveConfirmed">Remover</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
export default {
  data(){
    return {
      valid: false,
      loading: true,
      dialog: false,
      headers: [{ text: 'Email', align: 'left', sortable: true, value: 'email' },
                { text: 'Ativo', align: 'left', sortable: true, value: 'active'},
                { text: 'Ações', align: 'left', sortable: false, value: '' }],
      alertEmails: [],
      newEmail: '',
      removeDialog: false,
      emailToRemove: { email: '', alertType: 1 }
    }
  },
  mounted(){
    this.axios.get('api/AlertEmails')
      .then((response) => {
        this.loading = false;
        this.alertEmails = response.data;
      });
  },
  methods: {
    addNewEmail(){
      if(this.$refs.form.validate()){
        var alertEmail = { email: this.newEmail, alertType: 1 };
        this.loading = true;
        this.axios.post('api/AlertEmails', alertEmail)
          .then(() => {
            this.alertEmails.push(alertEmail)
          })
          .catch(() => {})
          .then(() => this.loading = false);
      }
    },
    removeEmail(email){
      this.emailToRemove = email;
      this.removeDialog = true;
    },
    onRemoveConfirmed(){
      this.removeDialog = false;
      this.loading = true;
      var emailToRemove = this.emailToRemove;
      this.axios.delete('api/AlertEmails/' + emailToRemove.email)
        .then(() => {
          this.alertEmails.splice(this.alertEmails.indexOf(emailToRemove), 1);
        })
        .catch(() => {})
        .then(() => this.loading = false);
    }
  }
}
</script>