export default {
    filter: 'Filter',
    period: 'Period',
    startDate: 'Start Date',
    endDate: 'End Date',
    initialHour: 'Start Hour',
    finalHour: 'End Hour',
    customer: 'Customer',
    category: 'Category',
    statePointOfSale: 'State of Point of Sale',
    location: 'Location',
    localtions: 'Location',
    pointOfSale: 'Point of Sale',
    showZeroedPOS: 'Show Zeroed POS',
    update: 'Update',
    clear: 'Clear',
    requiredRules: 'This field is required',
    noData: 'No data available',
    all: 'All',
    allF: 'All',
    pdv: 'POS',
    categoryId: 'Category ID',
    productId: 'Product ID',
    selectALocation: 'Select a location first',
 
    
  }
  