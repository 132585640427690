<template>
  <v-card class="mb-16">
    <news v-if="isBrazil"></news>

      <!-- Filters Component -->
      <transactions-reports-filters
        @table="acquireTable"
        @excel="acquireExcel"
        :loadingExcelStop="loadingExcelStop"
      ></transactions-reports-filters>

    <v-card-title>
      <div class="title">{{ $t('dynamicTransactionSummaryTransactionsReports') }}</div>
      <v-spacer></v-spacer>

      <v-form ref="form" lazy-validation v-model="search.valid" @submit.prevent="getDynamicTransactions">
        <v-text-field
          class="mr-2 pt-0"
          v-model="search.text"
          append-icon="search"
          :label="$t('searchTransactionsReports')"
          single-line
          hide-details
          clearable
          filled
          rounded
          dense
          @click:clear="search.text = ''; getDynamicTransactions()"
          @click:append="options.page = 1; getDynamicTransactions()"
          @input="options.page = 1"
        ></v-text-field>
      </v-form>

    </v-card-title>
    <v-divider></v-divider>

    <v-data-table
      :loading="loading.transactionsReports"
      :headers="dynamicHeaders"
      :items="transactionsReports"
      :options.sync="options"
      :server-items-length="totalItems"
      :footer-props="{ itemsPerPageOptions: [ 5, 10, 20, 30, 50, 100] }"
      :no-data-text="$t('selectGroupingsTransactionsReports')"
   >
      <template v-slot:[`item.SumPrice`]="{ item }">
        {{ $currencyFormatter.format(item.SumPrice) }}
      </template>
      <template v-slot:[`item.SumCost`]="{ item }">
        {{ $currencyFormatter.format(item.SumCost) }}
      </template>
      <template v-slot:[`item.CardBrand`]="{ item }">
        {{ item.CardBrand ? item.CardBrand : $t('othersTransactionsReports') }}
      </template>
      <template v-slot:[`item.PaymentMethod`]="{ item }">
        {{ paymentMethodTranslations[item.PaymentMethod] || "Outro" }}
      </template>
      <template v-slot:[`item.Day`]="{ item }">
        {{ $moment.utc(item.Day).format('DD/MM/YYYY') }}
      </template>
      <template v-slot:[`item.Month`]="{ item }">
        {{ monthNameConvertions[item.Month] }}
      </template>
      <template v-slot:[`item.DayOfWeek`]="{ item }">
        {{ weekDayTranslations[item.DayOfWeek] }}
      </template>
      <template v-slot:[`item.ProductCategory`]="{ item }">
        {{ item.ProductCategory ? item.ProductCategory : $t('othersTransactionsReports') }}
      </template>
      <template v-slot:[`item.Hour`]="{ item }">
        {{ `${item.Hour}:00 - ${item.Hour}:59` }}
      </template>

      <template v-if="transactionsReports.length > 0" slot="body.append">
        <tr>
          <td style="font-weight: bold">{{ $t('totalTransactionsReports') }}</td>
          <td v-for="index in dynamicHeaders.length - 4"
            :key="index"
            value="index"
          ></td>
          <td style="font-weight: bold">{{ totals.totalCount }}</td>
          <td style="font-weight: bold">
            {{ $currencyFormatter.format(totals.totalSumPrice) }}
          </td>
          <td style="font-weight: bold">
            {{ $currencyFormatter.format(totals.totalSumCost) }}
          </td>
        </tr>
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import { paymentMethodTranslations } from '@/constants/PaymentMethods';
import { monthNameConvertions } from '@/constants/Months'
import { weekDayTranslations } from '@/constants/WeekDays'
import { shouldRefreshData, toApiPagination } from "@/components/TableOptionsComparator";
import TransactionsReportsFilters from './components/TransactionsReportsFilters.vue';
import download from '@/components/Download';
import News from '@/components/News';

export default {
  components: { TransactionsReportsFilters, News },
  data() {
    return {
      loadingExcelStop: false,
      transactionsReports: [],
      totals: {},
      dynamicHeaders: [],
      loading: {
        transactionsReports: false,
      },
      paymentMethodTranslations,
      weekDayTranslations,
      monthNameConvertions,
      groupings: {
        product: {
          columns: [
            { text: this.$t('productCodeTransactionsReports'), value: 'ProductCode'},
            { text: this.$t('productDescriptionTransactionsReports'), value: 'ProductDescription' }
          ]
        },
        category: {
          columns: [
            { text: this.$t('categoryTransactionsReports'), value: 'ProductCategory' },
          ]
        },
        pointOfSaleId: {
          columns: [
            { text: this.$t('pointOfSaleIdTransactionsReports'), value: 'PointOfSaleId' },
            { text: this.$t('pointOfSaleCodeTransactionsReports'), value: 'PointOfSaleCompanyCode' },
            { text: this.$t('customerTransactionsReports'), value: 'CustomerName' },
            { text: this.$t('locationTransactionsReports'), value: 'LocalName' },
            { text: this.$t('specificLocationTransactionsReports'), value: 'SpecificLocation' },
          ]
        },
        customerName: {
          columns: [
            { text: this.$t('customerTransactionsReports'), value: 'CustomerName' },
          ]
        },
        addressState: {
          columns: [
            { text: this.$t('stateTransactionsReports'), value: 'AddressState' },
          ]
        },
        paymentMethod: {
          columns: [
            { text: this.$t('paymentMethodTransactionsReports'), value: 'PaymentMethod' }
          ]
        },
        cardBrand: {
          columns: [
            { text: this.$t('cardBrandTransactionsReports'), value: 'CardBrand' }
          ]
        },
        day: {
          columns: [
            { text: this.$t('dateTransactionsReports'), value: 'Day' }
          ]
        },
        month: {
          columns: [
            { text: this.$t('monthTransactionsReports'), value: 'Month' }
          ]
        },
        dayOfWeek: {
          columns: [
            { text: this.$t('dayOfWeekTransactionsReports'), value: 'DayOfWeek' }
          ]
        },
        hour: {
          columns: [
            { text: this.$t('hour') , value: 'Hour' }
          ]
        }
      },
      totalizers: [
        { text: this.$t('quantityTransactionsReports'), align: 'left', sortable: true, value: 'Count'},
        { text: this.$t('totalValueTransactionsReports'), align: 'left', sortable: true, value: 'SumPrice'},
        { text: this.$t('totalCostTransactionsReports'), align: 'left', sortable: true, value: 'SumCost'}
      ],
      form: {},
      search: {
        text: '',
        valid: true
      },
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["SumPrice"],
        sortDesc: [true],
        mustSort: true,
      }
    };
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getDynamicTransactions();
        }
      },
      deep: true,
    },
  },
  methods: {
    acquireExcel(form) {
      this.updateFilters(form);
      this.getDynamicTransactionsExcel();
    },
    acquireTable(form) {
      this.updateFilters(form)
      this.getDynamicTransactions();
    },
    updateFilters(form) {
      this.form = form;
      this.options.page = 1;
      this.options.sortBy = ["SumPrice"];
      this.options.sortDesc = [true];
    },
    getGroupings() {
      return this.form.group.map((value) => this.groupings[value]);
    },
    getUniqueColumns(groupings) {
      const columnValues = groupings
        .flatMap((g) => g.columns
          .map((c) => c.value ));
      return [...new Set(columnValues)];
    },
    getDynamicTransactionsExcel() {
      const groupings = this.getGroupings();
      const uniqueColumns = this.getUniqueColumns(groupings);

      const params = {
        minDate: this.form.minDate,
        maxDate: this.form.maxDate,
        groupColumns: uniqueColumns,
      };

      this.insertFiltersFromForm(params, uniqueColumns);

      this.axios
        .get("/api/DynamicReports/Transactions/Excel", { params, responseType: 'blob' })
          .then((response) => {
            this.loadingExcelStop = !this.loadingExcelStop
            download(response)
          }).catch(() => {
            this.loadingExcelStop = !this.loadingExcelStop
          });
    },
    getDynamicTransactions() {
      this.loading.transactionsReports = true;

      const groupings = this.getGroupings();
      const uniqueColumns = this.getUniqueColumns(groupings);

      const params = toApiPagination(this.options, this.search.text);
      this.insertFiltersFromForm(params, uniqueColumns);

      this.axios
        .get("/api/DynamicReports/Transactions", { params })
        .then((response) => {
          this.loading.transactionsReports = false;
          this.transactionsReports = response.data.items;
          this.totalItems = response.data.totalItems;
          this.totals = response.data.totals;
          this.dynamicHeaders = groupings
            .flatMap((g) => g.columns
              .map((c) => ({text: c.text, value: c.value })));
          this.dynamicHeaders
            .push(...this.totalizers);
        });
    },
    insertFiltersFromForm(params, columnValues) {
      const form = this.form;

      params.minDate = form.minDate;
      params.maxDate = form.maxDate;
      params.groupColumns = columnValues;

      params.addressState = form.addressState;
      params.customerId = form.customerId;
      params.localId = form.localId;
      params.posId = form.posId;
      params.productCategoryId = form.productCategoryId;
      params.productId = form.productId;

      return params;
    },
  },
  computed: {
    isBrazil() {
      return this.$auth.user().tenantCountry == 'BR';
    },
  }
};
</script>
