<template>
  <help>
    <template #title>Alertas de desvios</template>
    <p>
      Aqui são exibidas as vendas realizadas, dados importantes para o controle de desvios e se a compra foi cancelada
      ou não. É possível ter acesso aos vídeos das transações e configurar o status de verificação de desvios.
    </p>
    <p>
      <strong>Alertas de vídeo:</strong>
    </p>
    <ul>
      <li>
        É possível ter acesso às gravações das câmeras de cada PDV separadamente.
      </li>
    </ul>
    <br>
    <p>
      <strong>Transações Suspeitas:</strong>
    </p>
    <ul>
      <li>
        Em transações suspeitas, você tem uma visualização de todas as tentativas de transação nos PDVs, tendo
        transações
        canceladas
        <v-icon color="error">mdi-minus-circle</v-icon>
        e transações pagas
        <v-icon color="success">mdi-check-circle</v-icon>
        ;
      </li>
      <li>
        Você pode ver mais detalhes das tentativas clicando para expandir a transação, onde é possível ver os produtos
        que
        foram adicionados ao carrinho no ato da compra, junto com produtos que foram retirados posteriormente, marcados
        em
        vermelho;
      </li>
      <li>
        As transações por padrão aparecerão com o status de verificação "Pendente", e dependendo da ação que você
        selecionar, esse status é alterado. As ações são:
        <ul>
          <li>
            <v-icon color="success">mdi-check-circle</v-icon>
            : indica que não houve desvios e a transação está OK.
          </li>
          <li>
            <v-icon color="error">mdi-alert</v-icon>
            : indica que foi identificado um desvio.
          </li>
          <li>
            <v-icon color="success">mdi-cash-multiple</v-icon>
            : indica que o desvio foi reembolsado.
          </li>
          <li>
            <v-icon color="error">mdi-close-circle</v-icon>
            : indica que o desvio não será reembolsado e considerado como perda.
          </li>
          <li>
            <v-icon color="success">mdi-download</v-icon>
            : quando acionado, inicia a tentativa de download do video.
            <strong>É muito importante que este botão seja acionado uma única vez para não causar lentidão no
              sistema.</strong></li>
          <li>
            <v-icon color="info">mdi-video</v-icon>
            : será exibido se o downaload foi concluído com sucesso e, quando acionado,
            permite a visualização do vídeo que foi baixado.
          </li>
        </ul>
      </li>
    </ul>
    <br>
    <p>
      <strong>Acessos Suspeitos:</strong>
    </p>
    <ul>
      <li>
        Em acessos suspeitos, aparecerão todas as vezes que há um acesso pelo módulo de controle de acesso e não foi
        notificada nenhuma transação dentro de 5 minutos;
      </li>
      <li>
        Você pode realizar as mesmas ações que as Transações Suspeitas, tendo dísponivel a opção de fazer o download do
        vídeo do momento do acesso;
      </li>
      <li>
        Clicando no botão
        <v-icon color="info">mdi-magnify</v-icon>
        próximo a data, você consegue visualizar as 5 transações que ocorreram após o
        acesso dentro de um período de 1 hora, podendo fazer o download do vídeo, realizar ações e verificar detalhes da
        transação.
      </li>
    </ul>
    <br>
    <v-alert border="left" class="text-left" dense outlined text type="info">
      Para ter acesso aos vídeos das transações de um ponto de venda, é necessário que ele possua integração
      de vídeo ativada.
    </v-alert>

    <help-video  help-code="DeviationAlert"/>
  </help>
</template>

<script>
import HelpVideo from '@/components/HelpVideo.vue'

export default {
components: {
    'help-video': HelpVideo
  },
}
</script>
