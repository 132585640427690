<template>
  <v-dialog
    persistent
    width="720"
    v-model="show"
    @keydown.esc="show = false"
  >
  <template #activator="{ dialog }">
    <v-tooltip bottom open-delay="50" :disabled="addItemsEnabled">
      <template #activator="{ on }">
        <span v-on="on">
          <v-btn color="info" v-on="dialog" @click="show = true" :disabled="!addItemsEnabled" class="mr-2">
            <v-icon>mdi-pencil-plus</v-icon>{{ $t('btnItems') }}
          </v-btn>
        </span>
      </template>
      {{ $t('selectPosWithPlanogram') }}
    </v-tooltip>
  </template>
    <v-card>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="addOrUpdate"
      >
        <v-card-title>
          <span class="title">{{ $t('addOrUpdateItems') }}</span>
          <help>
            <template #title>{{ $t('addOrUpdateItems') }}</template>
            <p>
              {{ $t('addPlanogramProducts')}}
            </p>
            <ul>
              <li>
                {{ $t('justAdd') }}
              </li>
              <li>
                {{ $t('justUpdate') }}
              </li>
              <li>
                {{ $t('addOrUpdate') }}
              </li>
            </ul>
          </help>
        </v-card-title>
        <v-card-subtitle>
            <span v-html="$t('warningAddOrUpdateItems')" align="justify" class="subtitle red--text" style="font-size: smaller;" />
        </v-card-subtitle>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm8>
                <v-autocomplete
                  :items="products"
                  v-model="editedEntry.productId"
                  :loading="loading.products"
                  :label="$t('productNameLabel')"
                  item-text="searchText"
                  item-value="id"
                  :no-data-text="$t('noProducts')"
                  required
                  :rules="[rules.required, rules.nonEmpty]"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 sm4>
                <v-text-field
                  type="number"
                  min="0"
                  step="0.01"
                  placeholder="0.00"
                  :label="$t('priceNameLabel')"
                  v-model="editedEntry.price"
                  :rules="priceRule"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex
                xs12
                sm6
                md4
              >
                <v-text-field
                  type="number"
                  :label="$t('desiredQuantityLabel')"
                  v-model="editedEntry.quantityToSupply"
                  :rules="[rules.integer]"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex
                xs12
                sm6
                md4
              >
                <v-text-field
                  type="number"
                  :label="$t('criticalMinimumLabel')"
                  v-model="editedEntry.minimumQuantity"
                  :rules="[rules.integer]"
                ></v-text-field>
              </v-flex>
              <v-spacer></v-spacer>


              <v-radio-group
                v-model="editedEntry.updateStrategy"
                :label="$t('optionsLabel')"
                mandatory
                column
              >
                <v-tooltip right open-delay="50">
                  <template #activator="{ on }">
                    <span v-on="on">
                      <v-radio
                        :label="$t('addOnlyLabel')"
                        value="AddOnly"
                      ></v-radio>
                    </span>
                  </template>
                  {{ $t('justAddTheProduct') }}
                </v-tooltip>
                <v-tooltip right open-delay="50">
                  <template #activator="{ on }">
                    <span v-on="on">
                      <v-radio
                        :label="$t('updateOnlyLabel')"
                        value="UpdateOnly"
                      ></v-radio>
                    </span>
                  </template>
                  {{ $t('onlyEditPlanograms') }}
                </v-tooltip>
                <v-tooltip right open-delay="50">
                  <template #activator="{ on }">
                    <span v-on="on">
                      <v-radio
                        :label="$t('addOrUpdateLabel')"
                        value="Both"
                      ></v-radio>
                    </span>
                  </template>
                  {{ $t('addsTheProductToPlanograms') }}
                </v-tooltip>
              </v-radio-group>

            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click.native="show = false; editedEntry = {}"
          >{{ $t('btnClose') }}</v-btn>
          <v-btn
            color="primary"
            type="submit"
            :disabled="!valid"
            :loading="loading.newItem"
          >{{ $t('btnSave') }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    posIds: { type: Array, default: () => [] },
    addItemsEnabled: { type: Boolean, default: false }
  },
  watch: {
    "editedEntry.productId": function (newId, oldId) {
      if (newId && (oldId || this.editedEntry.price == null)) {
        const prod = this.products.find((p) => p.id === newId);
        if (prod) this.editedEntry.price = prod.defaultPrice;
      }
    },
  },
  computed: {
    priceRule() {
      return this.editedEntry.updateStrategy != 'UpdateOnly' ? [this.rules.float, this.rules.required] : [this.rules.required];
    }
  },
  data() {
    return {
      products: [],
      editedEntry: {},
      availability: [
        { value: "Local", text: this.$t('availabilityLocation') },
        { value: "Order", text: this.$t('availabilityOrder') },
        { value: "LocalAndOrder", text: this.$t('availabilityLocalAndOrder') },
      ],
      valid: true,
      show: false,
      loading: {
        products: true,
        newItem: false,
      },
      rules: {
        nonEmpty: (v) => /\S/.test(v) || this.$t('insertValidNameRules'),
        required: (v) => !!v || this.$t('requiredFieldRules'),
        integer: (v) => !v || /^\d+$/.test(v) || this.$t('insertIntegerRules'),
        float: (v) => !isNaN(v) || this.$t('insertValidValueRules'),
        requiredIf: (v, condition) =>
          !condition || !!v || this.$t('requiredFieldRules'),
        isUrl: (v) => !v ||
          !v || /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}[.]{0,1}/.test(
            v
          ) || this.$t('validUrlRules'),
      },
    };
  },
  mounted() {
    this.axios
      .get("api/products/productBaseSimpleInfo", {
        params: { page: 1, pageSize: -1, sortOrder: "description", descending: false },
      })
      .then((response) => {
        this.loading.products = false;
        this.products = response.data.items.map((prod) => {
          prod.searchText =
            (prod.code !== null ? prod.code + " - " : "") + prod.description;
          return prod;
        });
      });
  },
  methods: {
    async addOrUpdate() {      
      if (this.$refs.form.validate()) {
        this.loading.newItem = true;
        const numberOfElements = 4;
        const chunckedPosIds = this.partition(this.posIds, numberOfElements);
        const elementPercentage = 100 / chunckedPosIds.length;

        this.$emit('updateProgressDialogEnable', true);
        this.progress = 0;

        const promiseRequests = [...Array(chunckedPosIds.length).keys()]
          .map(x => 
            this.axios.post("api/PlanogramEntries/bulkAddOrUpdate", this.editedEntry, { params: { posIds: chunckedPosIds[x] } })
            .catch(() => {
              return {
                data: {
                  results: chunckedPosIds[x].map(posId => {
                    return {
                      entityId: posId,
                      success: false,
                      error: `${this.$t('addUpdatePos')} ${posId}. ${this.$t('tryAgainPos')}`
                    }
                  })
                }
              }
            })
            .finally(() => {
              this.progress += elementPercentage
              this.$emit('progress', this.progress);
            })          
          );

        await Promise.allSettled(promiseRequests)
          .then(response => {
            const bulkResult = { results: null }
            bulkResult.results = response.map(x => x.value.data).flatMap(it => it.results);
            this.$emit('entry-created', bulkResult);
            this.show = false;
          }).finally(() => {
            this.loading.newItem = false
            this.$emit('progress', 100)
          });          
      }
    },
    partition(array, n) {
      return array.length ? [array.splice(0, n)].concat(this.partition(array, n)) : [];
    },
  }
  }
</script>
