export default {
    detailXmlDialogInf: 'INFORMACIÓN DE LA FACTURA',
    detailXmlDialogAddProducts: 'Detalles',
    detailXmlDialogReceiptNumber: 'Número de Factura',
    detailXmlDialogReceiptSeries: 'Serie',
    detailXmlDialogReceiptSupplier: 'Proveedor',
    detailXmlDialogReceiptAccessKey: 'Clave de acceso (NFe)',
    detailXmlDialogReceiptIssueDate: 'Fecha de Emisión',
    detailXmlDialogReceiptProducts: 'Productos',
    detailXmlDialogReceiptDelete: 'Eliminar',
    detailXmlDialogReceiptReceiveProducts: 'Recibir Productos',
    detailXmlDialogReceiptCode: 'Código',
    detailXmlDialogReceiptProduct: 'Producto',
    detailXmlDialogReceiptQuantity: 'Cantidad en la Factura',
    detailXmlDialogReceiptPurchaseUnit: 'Unidad de Compra',
    detailXmlDialogReceiptTotalUnit: 'Unidad Total',
    detailXmlDialogReceiptUnitPrice: 'Precio Unitario',
    detailXmlDialogReceiptTotalPrice: 'Precio Total',
    detailXmlDialogReceiptActions: 'Acciones',
    detailXmlDialogReceiptSuccess: 'Compra insertada con éxito',
    detailXmlDialogReceiptFailure: 'No se pudo insertar la compra',
    detailXmlDialogReceiptUnit: 'Unidad',
    detailXmlDialogReceiptBox: 'Caja',
    detailXmlDialogReceiptBundle: 'Fardo',
    detailXmlDialogReceiptPackage: 'Paquete',
    detailXmlDialogReceiptUnknown: 'Desconocido',
    detailXmlDialogStockCentral: 'Para continuar, complete el stock central',
};
