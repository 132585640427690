<template>
<div>
    <v-dialog v-model="dialogVisible" persistent width="auto" max-width="600" @keydown.esc="closeDialog">
      <v-card>
        <v-card-title>
            Adicionar produtos ao estoque central
        </v-card-title>

        <v-card-text>
          <v-radio-group v-model="creationType" @change="radioButtonChange">
            <v-radio label="Adicionar todos os produtos do planograma no estoque central" value="addAll"></v-radio>
            <v-radio label="Adicionar produto específico" value="specific"></v-radio>
          </v-radio-group>
         <div v-if="productDropDownEnable">
            <products-filter
                  v-model="productId"
                  ref="productDropDown"
            ></products-filter>
          </div> 
        </v-card-text>

        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="closeDialog">Cancelar</v-btn>
          <v-btn color="success" :disabled="!confirmEnable" 
            @click="selectRequest"
        >Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <feedback ref="feedback"></feedback>
</div>
</template>

<script>
import agent from '@/api/agent';
import ProductsFilter from "@/components/filters/ProductsFilter";

  export default {
  components: { ProductsFilter },
    data(){
      return {
        productDropDownEnable: false,
        productId: Number,
        confirmEnable: false,
        creationType: String,
        dialogVisible: this.Visible
      }
    },
    props: {
        Visible: {
          type: Boolean,
          default: false
        },
        DistributionCenterId: {
          type: Number,
          required: false
        }
    }, 
    watch:{
      productId: function(){
        if(this.productId !== null && this.creationType === "specific"){
          this.confirmEnable = true
        } else {this.confirmEnable = false}
      },
      Visible(newVisible) {
      this.dialogVisible = newVisible;
      },
    },
    methods: {
    selectRequest(){
      if(this.creationType === "addAll"){
        this.AddItemMissingDistributionCenter()
      } else {this.AddSpecificItemDistributionCenter()}
    },
    AddItemMissingDistributionCenter(){
      agent.Inventory.AddInventoryItemsDistributionCenter(this.DistributionCenterId).then(response => {

        let message = response > 0 ? `${response} Produtos foram adicionados ao estoque central` : 'Não há produtos para adicionar ao estoque central';

        this.$refs.feedback.handleSuccess(message);

      }).catch(error => this.$refs.feedback.handleError(`Não foi possível adicionar os produtos ao estoque central`, error))
      .finally(() => this.closeDialog());
    },
    AddSpecificItemDistributionCenter(){
      const params = {productId: this.productId };
      agent.Inventory.AddSpecificItemDistributionCenter(this.DistributionCenterId, params).then(response => {

        this.$refs.feedback.handleSuccess(response);

      }).catch(error =>this.$refs.feedback.handleError(`Não foi possível adicionar o produto ao estoque central`, error))
      .finally(() => this.closeDialog());
    },
    closeDialog(){
      this.dialogVisible = false;
      this.productId = null;
      this.creationType = null;
      this.confirmEnable = false;
      this.productDropDownEnable = false;
      this.$emit('close')
    },
    radioButtonChange(){
        if(this.creationType === "specific"){
          this.productId = null
          this.productDropDownEnable = true
          this.confirmEnable = false
        } 
        else {
          this.productDropDownEnable = false
          this.confirmEnable = true
         }
    }
  },
}
</script>
