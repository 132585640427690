export default {
    resetSelectedPickListDetails: 'Restablecer seleccionados',
    showZeroPickListDetails: 'Mostrar cero',
    searchProductPickListDetails: 'Buscar producto',
    productCodePickListDetails: 'Código de producto',
    productNamePickListDetails: 'Nombre del producto',
    productBarCodePickListDetails: 'Código de barras',
    categoryPickListDetails: 'Categoría',
    quantityPickListDetails: 'Cantidad',
    currentQuantityPickListDetails: 'Cantidad actual',
    desiredQuantityPickListDetails: 'Cantidad deseada',
    criticalMinimumPickListDetails: 'Mínimo crítico',
    salesThisWeekPickListDetails: 'Ventas esta semana',
    centralStockQuantity: 'Cantidad en stock central',
    closePickListDetails: 'Cerrar',
    pickListInfoPickListDetails: 'Pick list ',
    pickListInfoPosDetails: 'PDV', 
    pickListInfoPlanogramDetails: ' Planograma ', 
    pickListInfoCreatedDetails: 'Creada: ', 
    pickListInfoUpdatedDetails: 'Actualizada: ', 
    changeErrorPickListDetails: 'Error al cambiar la cantidad',
    quantityNotChangedPickListDetails: 'Cantidad no cambiada: el formato del número no es válido',
    cancelPickListDetails: 'Cancelar',
    toSavePickListDetails: 'Guardar',
    emptySelectedError: 'Error al restablecer los elementos seleccionados',
    pendingPickListDetails: 'Pendiente',
    usedPickListDetails: 'Usada',
};
