
import Vue from 'vue';
import { formatDateTimeMinutes } from '@/utility/TextFormatting';
import {LastSupplies} from "@/types/Inventory";
import agent from "@/api/agent";
import LastSupplyHelp from '@/views/dashboard-new/helpers-new/LastSupplyHelp.vue';
import OperationHelper from '@/views/operations/OperationCommon';
import i18n from '@/locales/i18n';

export default Vue.extend({
  components: {
    LastSupplyHelp
  },
  props: {
    mustCallEndpoints: { type: Boolean, default: false },
    form: { type: Object, required: true }
  },
  data() {
    return {
      operations: [] as LastSupplies[],
      params: {
        pointOfSaleId: [] as number[],
        operationType: null as String | null,
      },    
      headers: [
        { text: i18n.t('lastSupplyColumnCustomerLocation'), align: 'left', sortable: false, value: 'description' },
        { text: i18n.t('lastSupplyColumnOperationType'), align: 'left', sortable: false, value: 'operationType', width: '90px;'},
        { text: i18n.t('lastSupplyColumnDate'), align: 'left', sortable: false, value: 'date' },
        { text: i18n.t('lastSupplyColumnUser'), align: 'left', sortable: false, value: 'userEmail' },
        { text: i18n.t('lastSupplyColumnDuration'), align: 'left', sortable: false, value: 'totalTime' },
        { text: i18n.t('lastSupplyColumnLosses'), align: 'left', sortable: false, value: 'losses' },
      ],
      typeOperations: [
        {text: i18n.t('lastSupplyTypeOperationsSupply'), value: "supply"},
        {text: i18n.t('lastSupplyTypeOperationsInventory'), value: "inventory"},
        {text: i18n.t('lastSupplyTypeOperationsPicking'), value: "picking"},
        {text: i18n.t('lastSupplyTypeOperationsCombined'), value: "combined"},        
        {text: i18n.t('lastSupplyTypeOperationsFull'), value: "Full"},        
      ],
      selectedTypeOperation: null,
    };
  },
  mounted() {
    this.getLasOperations();
  },
  watch: {
    mustCallEndpoints: {
      handler(value) {
        if (value == true) {
          this.params.pointOfSaleId = this.form.pointOfSaleId
          this.getLasOperations(); 
        }
      },
      immediate: true
    },
  },
  methods: {
    getLasOperations() {
      agent.Inventory.getLastSupplies(this.params)
        .then((response) => {
          this.operations = response;
        });
    },
    formatDateTimeMinutes,
    translateOperationType: OperationHelper.translateOperationType,
    filterOperationsByType() {
      if (this.selectedTypeOperation) {
        this.params.operationType = this.selectedTypeOperation;
      }
      this.getLasOperations();
    },
    clearFilter() {
      this.selectedTypeOperation = null;
      this.params.operationType = null;
      this.getLasOperations();
    },
  },
});
