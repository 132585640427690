
import Vue from 'vue';
// eslint-disable-next-line no-unused-vars
import {PagePointOfSaleConnectivity} from "@/types/Connectivity";
import { shouldRefreshData } from '@/components/TableOptionsComparator';
import agent from "@/api/agent";
import {formatPercentage} from "@/utility/TextFormatting";
import ConnectivityFilters from "@/views/connectivity/components/ConnectivityFilters.vue";

export default Vue.extend({
  components: {ConnectivityFilters},
  data() {
    return {
      headers: [
        {text: 'PDV', value: 'pointOfSaleInfo', align: 'left', sortable: false},
        {text: 'Wi-fi(%)', value: 'activeWifiPercentage', align: 'left', sortable: true},
        {text: 'Mobile(%)', value: 'activeMobilePercentage', align: 'left', sortable: true},
        {text: 'Off-line(%)', value: 'offlinePercentage', align: 'left', sortable: false},
      ],
      pointsOfSaleConnectivity: {} as PagePointOfSaleConnectivity,
      form: {},
      search: null as string | null,
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['occurrencesActiveWifi'],
        sortDesc: [true],
        mustSort: true
      },
    };
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getPointsOfSaleConnectivity();
        }
      },
    },
  },
  methods: {
    formatPercentage,
    updateFilters(form: any) {
      this.form = form;
      this.getPointsOfSaleConnectivity();
    },
    getPointsOfSaleConnectivity() {
      this.loading = true;

      let params = {
        ...this.form,
        page: this.options.page,
        pageSize: this.options.itemsPerPage,
        sortOrder: this.options.sortBy[0] === 'activeWifiPercentage' ? 'OccurrencesActiveWifi' : 'OccurrencesActiveMobile',
        descending: this.options.sortDesc[0],
        search: this.search ? this.search : null
      };

      agent.Connectivity.list(params)
          .then(response => {
            this.pointsOfSaleConnectivity = response;
          })
          .catch((error) => {
            this.handleError(`Não foi possível carregar Conectividade de PDVs, tente novamente`, error);
          })
          .finally(() => this.loading = false);
    },
    handleError(message: string, error: any) {
      (this.$refs.feedback as any).handleError(message, error);
    },
  }
});
