export default {
    redistributeButtonNegativeProductDetails: 'Redistribuir artículo',
    stockNegativeProductDetails: 'Stock: ',
    inStockNegativeProductDetails: 'En stock: ',
    reservationsNegativeProductDetails: 'Reservas: ',
    balanceNegativeProductDetails: 'Balance: ',
    quantityNegativeProductDetails: 'Cantidad',
    picklistNegativeProductDetails: 'Lista de selección',
    pointOfSaleNegativeProductDetails: 'Punto de venta',
    customerNegativeProductDetails: 'Cliente',
    locationNegativeProductDetails: 'Ubicación',
    specificLocationNegativeProductDetails: 'Ubicación específica',
    planogramNegativeProductDetails: 'Planograma',
    reservedQuantityNegativeProductDetails: 'Cantidad reservada',
    salesThisWeekNegativeProductDetails: 'Ventas esta semana',
    currentQuantityNegativeProductDetails: 'Cantidad actual',
    desiredQuantityNegativeProductDetails: 'Cantidad deseada',
    criticalMinimumNegativeProductDetails: 'Mínimo crítico',
    saveQuantityErrorNegativeProductDetails: 'Error al guardar la cantidad',
    distributeQuantitiesErrorNegativeProductDetails: 'Error al distribuir las reservas del producto',
    cancelNegativeProductDetails: 'Cancelar',
    toSaveNegativeProductDetails: 'Guardar',
};
