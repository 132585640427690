<template>
  <v-dialog v-model="dialog">
    <v-card>
      <v-card-title>
        {{ dialogTitle }}
      </v-card-title>
      <v-card-subtitle class="pt-2">
        Transações suspeitas em {{ formatDateTimeToDate(this.suspiciousAccess.date) }} das {{ formatDateTimeToTimeInDay(this.suspiciousAccess.date) }}
        às {{ formatDateTimeToTimeInDay(this.form.maxDate) }}
      </v-card-subtitle>
      <v-card-text>
        <!-- Is this case, the dialog itself is the enpoint trigger,
        hence the ':trigger="dialog"' -->
        <transactions-table :filters="form" :trigger="dialog"></transactions-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="dialog = false">Sair</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import Vue from 'vue';
import SuspiciousTransactionsDataTable from './SuspiciousTransactionsDataTable.vue';
import { formatDateTimeSeconds, formatDateTimeToDate, formatDateTimeToTimeInDay } from '@/utility/TextFormatting';
import moment from 'moment';

export default Vue.extend({
  components: {
    "transactions-table": SuspiciousTransactionsDataTable
  },
  props: {
    value: { type: Boolean, default: false },
    suspiciousAccess: { type: Object, default: () => {} }
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('close', value);
      }
    },
    dialogTitle() {
      return `(PDV ${this.suspiciousAccess.pointOfSaleId})` + 
        ` ${this.suspiciousAccess.pointOfSaleLocalCustomerName} - ${this.suspiciousAccess.pointOfSaleLocalName}` + 
        (this.suspiciousAccess.pointOfSaleSpecificLocation != null ? ` - ${this.suspiciousAccess.pointOfSaleSpecificLocation}` : '')
    },
  },
  watch: {
    value(newValue) {
      if (newValue == true) {
        const maxDate = moment(this.suspiciousAccess.date).add(1, 'hours').toDate();

        const newForm = {
          customerId: null,
          localId: null,
          minDate: this.suspiciousAccess.date,
          maxDate: maxDate,
          pointOfSaleId: this.suspiciousAccess.pointOfSaleId,
          result: null,
          status: this.suspiciousAccess.status,
          subtractedItems: null,
          userId: this.suspiciousAccess.user != null ? this.suspiciousAccess.user.userId : null,
          minDateUtc: moment(this.suspiciousAccess.date).utc(),
          maxDateUtc: moment(maxDate).utc()
        }
        this.form = newForm;
      }
    }
  },
  data() {
    return {
      form: {
        customerId: null,
        localId: null,
        minDate: null,
        maxDate: null,
        pointOfSaleId: null,
        result: null,
        status: null,
        subtractedItems: null,
        userId: null,
      },
    }
  },
  methods: {
    formatDateTimeSeconds,
    formatDateTimeToDate,
    formatDateTimeToTimeInDay
  }
})
</script>