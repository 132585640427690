<script>
import Vue from 'vue';
import { formatCurrencyReal, formatCurrencyUsDollar } from './TextFormatting';


export default Vue.extend({
  methods: {
    /** Returns the formatted currency string accordingly to the tenant's country.  */
    format(value) {
      if (this.$auth.user().tenantCountry == "BR") return this.formatCurrencyReal(value);
      if (this.$auth.user().tenantCountry == "US") return this.formatCurrencyUsDollar(value);
      if (this.$auth.user().tenantCountry == "CO") return this.formatCurrencyUsDollar(value); 
      
      return this.formatCurrencyReal(value);
    },
    formatCurrencyReal,
    formatCurrencyUsDollar,


    getSign() {
      if (this.$auth.user().tenantCountry == "BR") return "R$"
      if (this.$auth.user().tenantCountry == "US") return "$"
     
      
      return "R$ ";
    }
  }
})
</script>