export default {
    tenantGroupDialogNewGroup: 'Nuevo Grupo',
    tenantGroupDialogCardTitle: 'Crear Nuevo Grupo',
    tenantGroupDialogGroupIdLabel: 'ID del Grupo',
    tenantGroupDialogCnpjLabel: 'CNPJ',
    tenantGroupDialogGroupNameLabel: 'Nombre del Grupo',
    tenantGroupDialogCancel: 'Cancelar',
    tenantGroupDialogCreate: 'Crear',
    tenantGroupDialogHandleErrorMessage: 'No se pudo',
    tenantGroupDialogEmitSuccessMessage: 'Grupo id {groupId} creado exitosamente'
  };
  