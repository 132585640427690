export default {
    redistributeButtonNegativeProductDetails: 'Redistribute Item',
    stockNegativeProductDetails: 'Stock: ',
    inStockNegativeProductDetails: 'In Stock: ',
    reservationsNegativeProductDetails: 'Reservations: ',
    balanceNegativeProductDetails: 'Balance: ',
    quantityNegativeProductDetails: 'Quantity',
    picklistNegativeProductDetails: 'Picklist',
    pointOfSaleNegativeProductDetails: 'POS',
    customerNegativeProductDetails: 'Customer',
    locationNegativeProductDetails: 'Location',
    specificLocationNegativeProductDetails: 'Specific Location',
    planogramNegativeProductDetails: 'Planogram',
    reservedQuantityNegativeProductDetails: 'Reserved Quantity',
    salesThisWeekNegativeProductDetails: 'Sales this Week',
    currentQuantityNegativeProductDetails: 'Current Quantity',
    desiredQuantityNegativeProductDetails: 'Desired Quantity',
    criticalMinimumNegativeProductDetails: 'Minimum Critical',
    saveQuantityErrorNegativeProductDetails: 'Error changing quantity',
    distributeQuantitiesErrorNegativeProductDetails: 'Error distributing product reservations',
    cancelNegativeProductDetails: 'Cancel',
    toSaveNegativeProductDetails: 'Save',
};