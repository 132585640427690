<template>
  <v-container fluid class="pa-5">
    <v-layout wrap fill-height>
      <v-flex xs12 md3>
        <span class="subtitle-1">Insira o código de ativação</span>
        <v-form v-model="valid" ref="form" lazy-validation @submit.prevent="activate">
          <v-text-field
            label="Código"
            v-model="code"
            :rules="[(v) => v.length == 8 || 'O código tem 8 caracteres']"
            :counter="8"
            required
          ></v-text-field>
          <v-btn type="submit" right color="success" :loading="loading" :disabled="!valid">Ativar</v-btn>
        </v-form>
        <v-chip :hidden="message === null" label disabled :color="messageColor" text-color="white">
          <v-icon small>{{messageIcon}}</v-icon>{{message}}
        </v-chip>
      </v-flex>
    </v-layout>
      <v-form v-model="valid" ref="form" lazy-validation @submit.prevent="activate">
        <span class="subtitle-1">Novo método de ativação:</span>
        <span class="subtitle-2"> Se a maquininha solicitar um código de ativação, siga as etapas abaixo para concluir o processo: <br>        
          1 - Insira o código <b>{{newCode}}</b>. <br>
          2 - Clique em "Enviar". <br>
          3 - Aguarde a confirmação de ativação da maquininha. 
        </span>
      </v-form>
  </v-container>
</template>

<script>
export default {
  data(){
    return {
      code: "",
      newCode: "",
      valid: false,
      loading: false,
      message: null,
      messageColor: null,
      messageIcon: null
    }
  },
  mounted(){
    this.getCode()
  },
  methods: {
    activate(){
      if(this.$refs.form.validate()){
        this.loading = true;
        this.axios.post('api/BoxAuthentication/NewBox?code=' + this.code)
          .then(() => {
            this.messageColor = "success";
            this.messageIcon = "check_circle"
            this.message = "O sistema será ativado";
          })
          .catch(() => {
            this.messageColor = "error";
            this.messageIcon = "error";
            this.message = "Erro com a ativação"
          })
          .then(() => this.loading = false);
      }
    },
    getCode(){
     this.axios.get('api/BoxAuthentication/tenantAssociationCode')
      .then((response) => {
        this.newCode = response.data;
      })}
  }
}
</script>