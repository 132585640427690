<template>
  <v-row>
    <portal to="toolbarTitle">
      <help>
        <template #title>
          Informações de Estoque
        </template>
        <p>Mantenha um controle preciso dos itens disponíveis e das movimentações em seu estoque. 
          Utilize esta página para monitorar o inventário em diferentes locais, realizar ajustes e analisar padrões de movimentação.
        </p>
        <p><strong>Inventário: </strong>Explore detalhes do estoque e movimentações com filtros personalizados. 
          Você pode segmentar por Estoque de Loja ou Estoque Central. Faça ajustes no endereço do item, 
          quantidade, limite crítico e quantidade mínima de compra. Além disso, você pode editar quantidades diretamente no sistema e baixar os dados em formato Excel.
        </p>
        <p><strong>Movimentações: </strong>Analise operações por toque específico ou em toda a sua operação com filtros detalhados. 
          Compreenda padrões de movimentação e identifique eventuais erros logísticos para otimizar sua operação.
        </p>
      </help>
    </portal>
    <v-col cols="12">
      <v-tabs
        v-model="active"
        grow
        background-color="grey lighten-4"
      >
        <v-tab :key="0">Inventário</v-tab>
        <v-tab :key="1">Movimentações</v-tab>
        <v-tab-item :key="0">
          <inventory-items></inventory-items>
        </v-tab-item>
        <v-tab-item :key="1">
          <inventory-transactions></inventory-transactions>
        </v-tab-item>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
import InventoryItems from "./InventoryItems.vue";
import InventoryTransactions from './InventoryTransactions.vue';

export default {
  data() {
    return {
      active: 0,
    };
  },
  components: {
    InventoryItems,
    InventoryTransactions,
  },
  computed: {
    routeParams() {
      return Object.assign({}, this.$route.query);
    }
  },
  watch: {
    active: {
      handler(value) {
        // If this.active is to be initialized as 0, but there is also a query in the $route,
        // then it means the page is coming from a product in a planogram, and so it has to
        // show the transactions page, thus active = 1;
        if (Object.keys(this.routeParams).length != 0 && value == 0) {
          this.active = 1;
        }
      },
      // Immediate, so it listens to when the value is first valued by the data() return object.
      immediate: true
    }
  },
};
</script>
