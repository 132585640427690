<template>
  <v-dialog v-if="discount != null" v-model="show" max-width="720" @keydown.esc="show = false">
    <feedback ref="feedback"></feedback>
    <v-card>
      <v-card-title>
        <div class="title">Desconto de {{ getDiscountText(discount) }}</div>
        <div class="info-subtitle" :style="{ visibility: discount.minThreshold > 0 ? 'visible' : 'hidden' }">
          Compra mínima: {{ $currencyFormatter.format(discount.minThreshold) }}
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        :headers="headers" 
        :items="discount.coupons"
        hide-default-footer
        disable-pagination
      >
        <template #item="{ item }">
          <tr>
            <td>{{ item.code }}</td>
            <td>{{ item.expiresOn != null ? $moment(item.expiresOn).format('YYYY/MM/DD') : '' }}</td>
            <td>{{ item.consumerCode }}</td>
            <td>
              <v-btn icon class="mx-0" :loading="deleting" @click.native="deleteCoupon(item)">
                <v-icon color="error">delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click.native="show = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, required: true },
    discount: { type: Object, default: null },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  data() {
    return {
      deleting: false,
      headers: [
        { text: "Código", sortable: false }, 
        { text: "Expira em", sortable: false }, 
        { text: "Celular", sortable: false },
        { text: "Ações", sortable: false }
      ]
    };
  },
  methods: {
    getDiscountText(discount) {
      if (discount.type === 'Percentage') return `${discount.value.toLocaleString('pt-BR')}%`;
      if (discount.type === 'Fixed') return this.$currencyFormatter.format(discount.value);
      else return discount.value;
    },
    deleteCoupon(discountCode) {
      const discount = this.discount;
      this.deleting = true;
      this.axios.delete(`api/discounts/code/${discountCode.code}`)
        .then(() => {
          const index = discount.coupons.indexOf(discountCode);
          if (index >= 0) discount.coupons.splice(index, 1);
        }).catch(error => this.$refs.feedback.handleError("Não foi possível remover o cupom", error))
        .then(() => this.deleting = false);
    }
  }
}
</script>