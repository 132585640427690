<template>
    <v-dialog v-model="dialog" max-width="600" full>
      <v-card>
        <v-card-title class="headline"></v-card-title>
        <v-card-text>
          <v-sheet>
            {{ $t('trustPeriodDialogText') }}
          </v-sheet>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="cancelDialog">{{ $t('buttonCancel') }}</v-btn>
          <v-btn color="green darken-1" text @click="confirmTrustPeriod">{{ $t('buttonConfirm') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
<script>
  
  import agent from "@/api/agent";

  export default {
    props: {
      value: {
        type: Boolean,
        required: true
      },
      email: {
        type: String,
        required: true
      }
    },
    computed: {
      dialog: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      }
    },
    methods: {
      cancelDialog() {
        this.dialog = false;
      },
      confirmTrustPeriod() {
      this.dialog = false;
      const hasRequestedTrustPeriod = true;
      agent.Bills.startTrustPeriod(this.email, hasRequestedTrustPeriod)
        .then(() => {
          this.$emit('login');
        })
        .catch((error) => {
          this.$emit('error', this.$t('trustPeriodUpdateFailed') + ': ' + error.message);
        });
        }
    }
  };
</script>
  