
import Vue from 'vue';
import InventoriesDropDown from '@/components/filters/InventoriesDropDown.vue';
import { Product, DataRequest, ProductData} from "@/types/Purchases"
import { formatDateTimeToDate } from '@/utility/TextFormatting'
import agent from '@/api/agent';
import DeletePurchaseXmlDialog from '../XmlPurchase/DeletePurchaseXmlDialog.vue';

export default Vue.extend( {
    props: {
        invoice: {
            type: Object,
            required: true
        },
    },
    components: {
        InventoriesDropDown,
        DeletePurchaseXmlDialog
    },
    data() {
        return {
           headers: [
                { text: this.$t('codeDetailDialog'), value: 'codeByUser' },
                { text: this.$t('productsDetailDialog'), value: 'descriptionByUser' },
                { text: this.$t('quantityInvoiceDetailDialog'), value: 'quantity' },
                { text: this.$t('unitPurchaseDetailDialog'), value: 'unitPurchase' },
                { text: this.$t('unitTotalDetailDialog'), value: 'totalUnit' },
                { text: this.$t('unitValueDetailDialog'), value: 'unitValue' },
                { text: this.$t('valueTotalDetailDialog'), value: 'totalValue' },
                { text: this.$t("actionsDetailDialog"), sortable: false, value: "actions" }
            ],
            rules:{
                integer: (v : string) => Number.isInteger(Number(v)) || this.$t('rulesNumbers'),
            },
            confirmDeleteDialog : false as boolean,
            menu: false,
        }
    },
    computed: {
    formattedDate: function (): string {
      return formatDateTimeToDate(this.invoice.dateEmitted, true);
    },
    },
    methods: {
        createDataRequest() : DataRequest {
            return {
                nfeNumber: this.invoice.nfeNumber,
                inventoryId: this.invoice.inventoryId,
                dateEmitted: this.invoice.dateEmitted,
                Key: this.invoice.key,
                items: this.mapProducts(this.invoice.products),
            } as DataRequest;
        },

        mapProducts(products: Product[]): ProductData[] {
          return products
            .filter(product => product.quantityByUser != null && product.unitValue != null)
            .map((product) => ({
              productId: product.productId!,
              amount: product.quantityByUser! * product.quantity, 
              unitCost: product.unitValue / product.quantityByUser!,
            }));
        },
        insertPurchase(){
            const request = this.createDataRequest();
            agent.Purchases.createPurchase(request).then(() => {
                localStorage.removeItem('stepInvoice');
                localStorage.removeItem('invoiceImported');
                (this as any).$emit("complete", this.$t('returnSuccess'));
            }).catch((error : any) => {
                localStorage.removeItem('stepInvoice');
                localStorage.removeItem('invoiceImported');
                (this as any).$emit("error", this.$t('returnError'), error);
            })
        },
        closeConfirmationDialog(value : boolean, deleteOperation : boolean){
            this.confirmDeleteDialog = value;

            if(deleteOperation){
                localStorage.removeItem('stepInvoice');
                localStorage.removeItem('invoiceImported');
                this.invoice.inventoryId = null;
                (this as any).$emit("close");
            }
        },
        fillAllFields() : boolean{
            return (this.invoice.inventoryId != null && this.invoice.inventoryId != undefined && this.invoice.products.length > 0 && Number.isInteger(Number(this.invoice.nfeNumber)) 
            && Number.isInteger(Number(this.invoice.key)) && this.invoice.nfeNumber != '' && this.invoice.key != '' && this.invoice.dateEmitted != '');
        },
        unitTypeText(unit : string){
            switch (unit) {
                case "Unit":
                    return this.$t('unit');
                case "Box":
                    return this.$t('box');
                case "Package":
                    return this.$t('bale');
                case "Bag":
                    return this.$t('package')
                default:
                    return this.$t('unitUnknown');
                }
        },
        editProduct(index : number){
            (this as any).$emit("editProduct", index);
        },
        removeProduct(index : number){
            this.invoice.products.splice(index, 1);
        },
        addProduct(){
            (this as any).$emit("addProduct");
        },
        formatDateTimeToDate,
    }
})
