
import Vue from 'vue';
import moment from "moment";

export default Vue.extend({
  props: {
    rounded: { type: Boolean, default: false },
    filled:  { type: Boolean, default: false },
    soloInverted: { type: Boolean, default: true },
    icon: { type: String, default: "" }
  },
  data() {
    return {
      dateInterval: 30 as number | string,
      dateIntervals: [{ text: this.$t('last30days'), value: 30 },
        { text: this.$t('last15days'), value: 15 },
        { text: this.$t('last7days'), value: 7 },
        { text: this.$t('today'), value: 0 },
        { text: this.$t('currentMonth'), value: "currentMonth" }],
    }
  },
  methods: {
    sendMinDate() {
      const minDate = this.getMinDate();
      this.$emit('input', minDate);
      this.$emit('dateInterval', this.dateInterval)
    },
    getMinDate(): string {
      if (this.dateInterval == "currentMonth") {
        return moment().startOf('month').format('YYYY-MM-DD')
      }
      return moment().add(-this.dateInterval, 'days').format('YYYY-MM-DD')
    }
  }
})
