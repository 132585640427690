
import Vue from 'vue';

export default Vue.extend({
  props: {
    dateIntervals: { type: Array, required: true },
    mandatory: { type: Boolean, default: true },
    value: { type: Number, default: 30 }
  },
  computed: {
    intervalSelected: {
        get(): number | null {
            return (this as any).value;
        },
        set(value):void {
            (this as any).$emit('input', value);
        }
    }
  }
})
