export default {
  transferPointOfSale: 'Transfer point of sale to another CNPJ',
  transferProcessDescription: 'The process of ownership transfer consists of transferring the store from one CNPJ to another. To ensure that this process runs smoothly, follow the next steps. After submitting the request, you will be able to track the progress of the transfer process in the ',
  linkApproveTransfers: 'Approve Transfers menu.',
  transfer: 'Transfer',
  pointsOfSaleToBeTransferred: 'Points of sale to be transferred',
  pointsOfSaleHelpTitle: 'Points of sale',
  pointsOfSaleHelpDescription: 'Select the primary points of sale (with planogram) to transfer to the new CNPJ. Associated secondary machines (sharing the same planogram) will be automatically transferred.',
  cnpj: 'CNPJ',
  cnpjHelpDescription: 'Enter the CNPJ that will receive the selected points of sale. Please enter numbers only.',
  sameNetworkTransfer: 'Is this transfer within the same network/franchise',
  yes: 'Yes',
  no: 'No',
  termsAndConditions: 'Terms and Conditions',
  termsAndConditionsDescription: 'This transfer only refers to the POS within the system and does not change the location where the sale will be credited. To transfer the card machine data, please watch the video below:',
  watchVideo: 'WATCH VIDEO',
  termsOfResponsibilityPOS: 'TERMS OF RESPONSIBILITY FOR PAYMENT MACHINE TRANSFER',
  agreeWithTermsOfResponsibilityPOS: 'I understand that I am responsible for transferring the payment machine account and that only then will the CNPJ to which I am transferring be able to receive the revenue.',
  termOfResponsibilityTransfer: 'TERMS OF RESPONSIBILITY FOR OWNERSHIP TRANSFER',
  agreeWithTermOfResponsibilityTransfer: 'I understand that by transferring the payment machine account, all administrative, operational, and financial responsibilities related to this device will become the sole responsibility of the new owner. This includes, but is not limited to, monthly fees and POS visibility in telemetry. As the former owner, I will no longer have access to the revenue from this POS.',
  termsAndConditionsHelp: 'If you have any questions about the process, please contact our administrative team at ',
  submitRequest: 'Submit request'
};
