
import Vue from 'vue'
import {SalesSummary} from '@/types/SalesDashboard'
import agent from '@/api/agent'

export default Vue.extend({
    data() {
        return {
            salesSummary : {} as SalesSummary,
            params: { 
                pointOfSaleId: [] as number [],
            }
        }
    },
    props: {
        mustCallEndpoints: { type: Boolean, default: false },
        form: { type: Object, required: true }
    },
    watch: {
        mustCallEndpoints: {
            handler(value) {
                if (value) {
                    this.params.pointOfSaleId = this.form.pointOfSaleId,
                    this.getSalesSummary();
                }
            },
        }
    },
    mounted() {
        this.getSalesSummary();
    },
    methods:{
        getSalesSummary(){
            agent.SalesDashboard.getSalesSummary(this.params).then((response) => {
                this.salesSummary = response;
            })
        }
    }
                    
})
