
import Vue from 'vue';
import SalesByPaymentMethodHelp from '@/views/dashboard-new/helpers-new/SalesByPaymentMethodHelp.vue';
import agent from "@/api/agent";
import moment from "moment";
import { paymentMethodTranslations } from '@/constants/PaymentMethods';
import { formatPercentage } from '@/utility/TextFormatting';
import DoughnutChart from "@/report/DoughnutChart";
 
interface PaymentMethodTranslations {
  [key: string]: string;
}
 
export default Vue.extend({
  props:{
    mustCallEndpoints: { type: Boolean, default: false },
    form: { type: Object, required: true }
  },
  components: {
    SalesByPaymentMethodHelp,
    DoughnutChart
  },
  data() {
    return {
      graph: {
        visible: false as boolean,
        datasets: [] as object[],
      },
      params: { 
        pointOfSaleIds: [] as number [],
        minDate: moment().add(-30, 'days').format('YYYY-MM-DD'),
        maxDate: moment().format('YYYY-MM-DD'),
      },
      color: ['#00A88F','#2DCE98','#00479B','#2196F3','#666666'],
      paymentMethods: [] as { paymentMethod: string; percentage: number }[],
      paymentMethodTranslations: paymentMethodTranslations as PaymentMethodTranslations,
    };
  },
  mounted() {
      this.getSalesByPaymentMethod();
   },
  watch: {
    mustCallEndpoints: {
      handler(value) {
        if (value) {
          this.params.maxDate = this.form.maxDate;
          this.params.minDate = this.form.minDate;
          this.params.pointOfSaleIds = this.form.pointOfSaleId;
          this.getSalesByPaymentMethod();
        }
      },
    }
  },
  methods:{
    getSalesByPaymentMethod(){
      this.graph.visible = false;
      agent.reportSales.getSalesByPaymentMethod( this.params)
      .then((response) => {
        this.paymentMethods = response.map(item => ({
             paymentMethod: String(item.paymentMethod),
             percentage: item.percentage
            }));
           const labels = this.paymentMethods.map(item => this.paymentMethodTranslations[item.paymentMethod] || item.paymentMethod);
           const percentages = this.paymentMethods.map(item => formatPercentage(item.percentage));
           this.graph.datasets = [{
             label: labels,
             data: percentages,
             backgroundColor: this.color.slice(0, response.length),
           }];
         })
      .catch(() => {})
      .finally(() => {
        this.graph.visible = true;
      });
    },
    formatPercentage,
  },
})
