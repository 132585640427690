export default {
    removePickListsPickListTable: 'Eliminar pick lists',
    selectAtLeastOnePickList: 'Seleccione al menos una pick list',
    excelPickListTable: 'Excel',
    synchronizationWithManagementSystem: 'Sincronizar pick-lists con el sistema de gestión',
    noResultsPickListTable: 'Sin resultados',    
    idPickListTable: 'ID de Pick List',
    primaryPickListTable: 'Principal',
    customerPickListTable: 'Cliente',
    locationPickListTable: 'Ubicación',
    specificLocationPickListTable: 'Ubicación Específica',
    pointOfSalePickListTable: 'PDV',
    machinePickListTable: 'Máquina',
    updatedActionPickListTable: 'Actualizada en',
    syncWithOmiePickListTable: 'Sincronización con sistema de gestión Omie',
    erpManagementSystem: 'ERP',
    removeConfirmationPickListTable: '¿Está seguro de que desea eliminar?',
    pickListPickListTable: ' pick ',
    noPickListTable: 'No',
    removePickListTable: 'Eliminar',
    successRemovePickListTable: 'Pick list ',
    removeSuccessPickListTable: ' eliminada',
    errorRemovePickListTable: 'No se pudo eliminar la pick list ',
    synchronizationStarted: 'La pick-list',
    synchronizationStartedPickListTable: ' se está sincronizando con Omie y estará disponible en unos momentos.',
    syncErrorPickListTable: 'No se pudo sincronizar la pick-list',
    generateReportErrorPickListTable: 'No se pudo generar el informe',
    createdSuccessPickListTable: ' creada para el PDV ',
    createdErrorPickListTable: 'No se pudo crear la pick list para el PDV ',
    syncSuccessPickListTable: 'Las pick-lists se están sincronizando con el ERP AMLabs.',
    syncErrorPickList: 'No se pudo sincronizar las pick-lists.',
    editPickListTable: '(editada)',
    synchronizationPickListTable: 'Sincronización',
};
