export default {
    tenantGroupDialogHelpTitle: 'Creación de nuevos Grupos',
    tenantGroupDialogHelpFirstParagraph: 'En esta funcionalidad, puedes crear nuevos Grupos.',
    tenantGroupDialogHelpListElement1: `Cuando se abre la pantalla de creación de nuevos Grupos, el sistema
      busca y sugiere el próximo ID disponible para el nuevo Grupo.`,
    tenantGroupDialogHelpListElement2: `El campo <strong>CNPJ</strong> es obligatorio.
      <strong>Es importante destacar que el CNPJ ingresado se utilizará para facturación</strong>.`,
    tenantGroupDialogHelpListElement3: `El campo <strong>Nombre del Grupo</strong> es obligatorio y debe tener entre 5 y
      60 caracteres. <strong>Se recomienda usar el nombre fantasma correspondiente al CNPJ</strong>.`
}
