<template>
  <v-expansion-panels hover>
    <v-expansion-panel>

      <v-expansion-panel-header>
        <div><v-icon>filter_list</v-icon> Filtros e ações</div>
        <v-spacer></v-spacer>
      </v-expansion-panel-header>

      <v-expansion-panel-content class="pb-2">
        <v-form ref="form" v-model="valid">

          <v-row class="pt-5">

            <v-col cols="12" md="3">
              <inventories-drop-down 
                v-model="form.inventoryIds"
                :inventoryTypeFilter="inventoryTypeFilter"
                :multiple="true"
                >
              </inventories-drop-down>
            </v-col>

            <v-col cols="12" md="3">
              <ids-products-drop-down
                v-model="form.productId"
              >
              </ids-products-drop-down>
            </v-col>

            <v-col cols="12" md="3">
                <v-menu
                  class="pr-2"
                  :close-on-content-click="true"
                  v-model="hasDate"
                  transition="scale-transition"
                  offset-y
                  :nudge-right="40"
                  max-width="290px"
                  min-width="290px"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      v-on="on"
                      label="Data"
                      required
                      v-model="formattedDate"
                      prepend-icon="date_range"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.date"
                    no-title
                    scrollable
                    actions
                    locale="pt-br"
                    :allowed-dates="getAllowedDates"
                    @change="handleDateChanged"
                  ></v-date-picker>
                </v-menu> 
            </v-col>

            <v-col class="search-field px-2" cols="12" md="3">
              <v-row class="ma-0">
                <p class="search-field my-0">
                  Tipo de estoque:
                </p>
              </v-row>
              <v-row class="ma-0">
                <v-chip-group
                  v-model="form.inventoryTypes"
                  active-class="deep-purple--text text--accent-4"
                  column
                  mandatory
                  multiple
                >
                  <v-chip
                    v-for="(type, index) in inventoryTypes"
                    :key="index"
                    :value="type.value"
                  >
                    {{ type.text }}
                  </v-chip>
                </v-chip-group>
              </v-row>
            </v-col>

          </v-row>
          
          <v-row justify="end">
            <v-btn 
              class="mx-2" 
              text 
              @click.native="clearQuery"
              color="error" 
              :disabled="!valid"
            >
            <v-icon left>clear</v-icon>Limpar
            </v-btn>

            <v-btn 
              class="mx-2" 
              color="info" 
              @click.native="getExcel" 
              :disabled="!valid" 
              :loading="loading.excel"
            >
            <v-icon left>mdi-file-excel</v-icon>Excel
            </v-btn>

            <v-btn
              color="success"
              :disabled="!valid"
              v-on:click.native="emitModification"
              >
              <v-icon left>refresh</v-icon>Atualizar
            </v-btn>
          </v-row>

          <download-dialog
            v-model="downloadDialog.show"
            :fileName="downloadDialog.fileName"
            @update="(value) => downloadDialog.show = value"
          />

        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import moment from "moment";
import InventoriesDropDown from '../../../components/filters/InventoriesDropDown.vue';
import IdsProductsDropDown from '../../../components/filters/IdsProductsDropDown.vue';
import DownloadDialog from '@/components/common/DownloadDialog.vue';

export default {
  components: { 
    InventoriesDropDown, 
    IdsProductsDropDown,
    DownloadDialog 
  },
  data() {
    return {
      hasDate: false,
      valid: false,
      form: {
        inventoryIds: [],
        productId: null,
        inventoryTypes: [],
        date: null,
        timezoneOffset: new Date().getTimezoneOffset(),
      },
      loading: {
        excel: false
      },
      inventoryTypes: [
        { text: "Central", value: "distributionCenter" },
        { text: "PDV", value: "pointOfSale" },
      ],
      preSelectedInventoryType: [
        'distributionCenter'
      ],
      downloadDialog: {
        show: false,
        fileName: ""
      }
    };
  },
  mounted() {
    this.form = this.newForm();
    this.$emit("modify", this.form);
  },
  computed: {
    formattedDate: function() {
      return this.formatDateTime(this.form.date);
    },
    inventoryTypeFilter() {
      if (this.form.inventoryTypes.length === this.inventoryTypes.length) {
        return null;
      }
      else return this.form.inventoryTypes[0];
    }
  },
  methods: {
    getExcel(){
      if(this.$refs.form.validate()){
        this.loading.excel = true;
        this.axios
          .get('api/web/inventory/items/excel', {params: this.form})
          .then((response) => {
            this.loading.excel = false;
            this.downloadDialog.fileName = response.data;
            this.downloadDialog.show = true;
          }).catch((error) => {
            this.loading.excel = false;
            this.$emit("excelError", error);
          });
      }
    },
    emitModification() {
      if (this.$refs.form.validate()) {
        this.$emit("modify", this.form);
      }
    },
    newForm() {
      return {
        inventoryIds: [],
        productId: null,
        inventoryTypes: this.preSelectedInventoryType,
        date: moment(new Date()).format("YYYY-MM-DD"),
        timezoneOffset: new Date().getTimezoneOffset(),
      };
    },
    formatDateTime(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getAllowedDates(date) {
      if (this.form.date !== null) {
        return this.isDateRangeAllowed(
          new Date(date),
          new Date()
        );
      }
      return true;
    },
    isDateRangeAllowed(min, max) {
      return max >= min && Math.round(max - min) / (1000 * 60 * 60 * 24) < 90;
    },
    clearQuery() {
      this.form = this.newForm();
      this.emitModification();
    },
    handleDateChanged(newDate) {
      const currentDate = new Date();
      const isDifferentDate = !moment(newDate).isSame(currentDate, 'date');
      this.$emit('dateChanged', isDifferentDate);
    },
  },
};
</script>
