<template>
<div>
    <v-dialog v-model="dialogVisible" persistent width="auto" max-width="600" @keydown.esc="closeDialog">
      <v-card>
        <v-card-title>
            <v-icon left>mdi-alert-circle</v-icon>Remover produtos do estoque central
        </v-card-title>

        <v-card-text>
        <p>
            <strong>Atenção: </strong> 
            {{ Message }}
        </p>
        </v-card-text>

        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="closeDialog">Cancelar</v-btn>
          <v-btn color="error" 
            @click="selectRequest"
        >Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <feedback ref="feedback"></feedback>
</div>
</template>

<script>
import agent from '@/api/agent';

  export default {
    data() {
      return {
        dialogVisible: this.Visible,
        productId: Number,
        distributionCenterId: Number
      }
    },
    props: {
        Visible: {
          type: Boolean,
          default: false
        },
        Item: {
          type: Object,
          required: false
        },
        Message: {
          type: String,
          required: false
        },
        RequestType:{
          type: String,
          required: false
        }
    },
    methods: {
    selectRequest(){
      this.distributionCenterId = this.Item.distributionCenterId
      this.productId = this.Item.productId
      if(this.RequestType === "all"){
        this.removeItemCentralUseless()
      } else {this.removeSpecificItem()}
    },

    removeItemCentralUseless(){
        agent.Inventory.RemoveInventoryItemsUseless(this.distributionCenterId).then(response => {

        let message = response > 0 ? `${response} Produtos foram removidos do estoque central` : 'Não há produtos para remover do estoque central';

        this.$refs.feedback.handleSuccess(message);

      }).catch(error => this.$refs.feedback.handleError(`Não foi possível remover os produtos do estoque central`, error))
      .finally(() => this.closeDialog());
    },

    removeSpecificItem(){
      agent.Inventory.RemoveSpecificItemDistributionCenter(this.distributionCenterId, this.productId).then(response => {
        this.$refs.feedback.handleSuccess(response)
      }).catch(error => this.$refs.feedback.handleError(`Não foi possível remover o produto do estoque central`, error))
      .finally(() => this.closeDialog());
    },

    closeDialog(){
      this.dialogVisible = false;
      this.$emit('close')
    }

  },
  watch: {
    Visible(newVisible) {
      this.dialogVisible = newVisible;
    }
  }
}
</script>
