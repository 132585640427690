
import Vue from 'vue';
import { shouldRefreshData, toApiPagination } from "../../components/TableOptionsComparator";
import { formatCpf, formatCnpj, formatDateTimeToDate, formatCnpjOrCpfToNumbersOnly } from '@/utility/TextFormatting';
// eslint-disable-next-line no-unused-vars
import { Tenant, CustomerStatusChangeResponse } from '@/types/Customers';

export default Vue.extend({
  components: {
  },
  data() {
    return {
      tenants: [] as Tenant[] | undefined,
      headers: [
        { text: "Status", align: "left", sortable: true, value: "clientStatus", width: 140 },
        { text: "Id", align: "left", sortable: true, value: "tenantId" },
        { text: "Nome", align: "left", sortable: true, value: "name" },
        { text: "Banco de Dados", align: "left", sortable: true, value: "databaseName" },
        { text: "CPF", align: "left", sortable: true, value: "cpf" },
        { text: "CNPJ", align: "left", sortable: true, value: "cnpj" },
        { text: "Categoria", align: "left", sortable: true, value: "clientCategory" },
        { text: "Inicio-Parceria", align: "left", sortable: true, value: "dateCreated" },
        { text: "Qtd. Groups", align: "left", sortable: true, value: "groupsQuantity" },
        { text: "Ações", align: "center", sortable: false, value: "actions" },
      ],
      clientStatus: [
        { text: "Ativo", value: "Active" },
        { text: "Sem Acesso", value: "Locked" },
        { text: "Bloqueio Total", value: "Blocked" },
      ],
      clientBlockReason: "Payment" as "Payment" | "Royalties",
      loading: {
        table: true,
      },
      search: {
        valid: true,
        text: "" as string | null,
      },
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["tenantId"],
        sortDesc: [false],
        mustSort: true,
      },
      editedClientStatus: ''
    };
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getTenants();
        }
      },
      deep: true,
    }
  },
  mounted() {
    this.getTenants();
  },
  methods: {
    getTenants() {
      this.loading.table = true;
      const searchText = this.search.text && this.$validations.isCnpjOrCpf(this.search.text)
        ? formatCnpjOrCpfToNumbersOnly(this.search.text)
        : this.search.text;
      const params = toApiPagination(this.options, searchText);
      this.axios.get("api/tenants/list", { params: params })
        .then((response) => {
          this.loading.table = false;
          this.tenants = response.data.items;
          this.totalItems = response.data.totalItems;
        });
    },
    saveTenantStatus(tenantEntry: Tenant) {
      let tenantRequestDto = Object.assign({}, tenantEntry)
      tenantRequestDto.clientStatus = this.editedClientStatus
      if (tenantRequestDto.clientStatus != "Active") {
        tenantRequestDto.clientBlockReason = this.clientBlockReason;
      }
      else {
        tenantRequestDto.clientBlockReason = null;
      }
      let feedbackMessage = this.getFeedbackMessage(this.editedClientStatus)

      this.axios.put(`api/Tenants/status/`, tenantRequestDto)
        .then((response) => {
          tenantEntry.clientStatus = this.editedClientStatus
          if (tenantEntry.clientStatus != "Active") {
            tenantEntry.clientBlockReason = this.clientBlockReason;
          }
          else {
            tenantEntry.clientBlockReason = null;
          }
          let emailsMessage = this.getEmailsMessage(response.data);
          this.handleSuccess(`Sucesso ao realizar ${feedbackMessage} de ${tenantEntry.name}.${emailsMessage}`);
        }).catch((error) => {
          this.handleError(`Erro ao realizar ${feedbackMessage}.`, error);
        }).finally(() => {
          this.editedClientStatus = ''
        })
    },
    handleSuccess(message: string) {
      (this.$refs.feedback as any).handleSuccess(message);
    },
    handleError(message: string, error: any) {
      (this.$refs.feedback as any).handleError(message, error);
    },
    getStatusColor(status: string) {
      switch(status){
        case 'Active': return 'green darken-2'
        case 'Locked': return 'amber darken-2'
        case 'Blocked': return 'red darken-2'
      }
    },
    getStatusIcon(status: string) {
      switch(status){
        case 'Active': return 'check_circle'
        case 'Locked': return 'mdi-alert-circle'
        case 'Blocked': return 'mdi-alert-circle'
      }
    },
    getStatusDescription(tenant: Tenant) {
      let text = ""
      switch(tenant.clientStatus) {
        case 'Active': text =  'Ativo'; break;
        case 'Locked': text =  'Sem Acesso'; break;
        case 'Blocked': text =  'Bloqueio Total'; break;
      }
      switch(tenant.clientBlockReason) {
        case 'Payment': text += " - Pagamento"; break;
        case 'Royalties': text += " - Franquia"; break;
      }
      return text;
    },
    getFeedbackMessage(status: string) {
      switch(status){
        case 'Active': return 'Desbloqueio'
        case 'Locked': return 'Bloqueio de Acesso'
        case 'Blocked': return 'Bloqueo Total'
      }
    },
    getEmailsMessage(data: CustomerStatusChangeResponse) {
      if (data.emailsFailed + data.emailsSent == 0) {
        return "Nenhum email foi enviado.";
      }

      let emailsMessage = ` Foram enviados ${data.emailsSent} com sucesso.`
      if (data.emailsFailed > 0) {
        emailsMessage + ` ${data.emailsFailed} emails falharam.`;
      }
      return emailsMessage;
    },
    formatCpf,
    formatCnpj,
    formatDateTimeToDate,
  }
});
