<template>
  <v-container fluid class="pa-5">
    <a href="https://amlabs-academy.myedools.com/" target="_blank" rel="noopener noreferrer">
      <img src="https://touchpay-tenant-data.s3.sa-east-1.amazonaws.com/public/images/amlabs-academy.png" style="max-width:100%;max-height:100%;">
    </a>
    <v-row no-gutters class="mb-16">
      <v-col cols="12">
        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
        <template v-for="section in sections">
          <h2 class="grey--text text--darken-1" :key="`${section.id}-title`">{{ section.title }}</h2>
          <v-divider class="pb-2" :key="`${section.id}-divider`"></v-divider>
          <v-expansion-panels hover flat :key="`${section.id}-panel`">
            <support-tile v-for="item in section.items" :key="item.id" :title="item.title" :video-id="item.youTubeVideoId">
              <span v-if="item.content">{{ item.content }}</span>
            </support-tile>
          </v-expansion-panels>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SupportTile from '@/views/support/SupportTile'
export default {
  components: {
    SupportTile
  },
  data() {
    return {
      loading: true,
      sections: []
    }
  },
  mounted() {
    this.axios.get('api/help').then((response) => {
      this.loading = false;
      this.sections = response.data;
    });
  }
}
</script>
