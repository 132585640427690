
import Vue from 'vue';
import BarChart from '@/report/BarLineCombinedChart';
import {RevenueDto} from '@/types/SalesDashboard';
import agent from '@/api/agent';
import RevenueGrowthHelp from '@/views/dashboard-new/helpers-new/RevenueGrowthHelp.vue';


export default Vue.extend({
  props: {
    mustCallEndpoints: { type: Boolean, required: true },
    form: { type: Object, required: true }
  },
  mounted() {
    this.getRevenue();
    window.addEventListener('resize', this.handleChartSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleChartSize);
  },
  watch: {
    mustCallEndpoints: {
      handler(value) {
        if (value) {
          this.params.pointofSaleIds = this.form.pointOfSaleId
          this.getRevenue();

          return value;
        }
      },
    },
  },
  data() {
    return {
      revenueList: [{}] as RevenueDto [],
      barColorCurrentYear: (this.$vuetify.theme as any).defaults.dashboard.barColor,
      barColorLastYear: (this.$vuetify.theme as any).defaults.dashboard.barColorSecondary,
      params: {
        pointofSaleIds: [] as number[]
      },
      chart: {
        visible: false as boolean,
        height: 50 as number,
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map(month => this.$t(month)),
        datasets: [] as object[],
      },
      revenueSum:{
        revenueTotalYear: 0,
        highRevenueValue: 0,
        highRevenue: 0,
        currentMonthValue: 0,
        currentYear: 0
      }
    }
  },
  methods: {
    handleChartSize() {
      this.chart.visible = false;
      
      switch(true) {
        case this.$vuetify.breakpoint.xs:
          this.chart.height = 460;
          break;
        case this.$vuetify.breakpoint.sm:
          this.chart.height = 220;
          break;
        case this.$vuetify.breakpoint.md:
          this.chart.height = 90;
          break;
        default:
          this.chart.height = 80;
      }

      setTimeout(() => {
        this.chart.visible = true;
      }, 100);
    },

    getRevenue() {
      this.revenueSum = {
        revenueTotalYear: 0,
        highRevenueValue: 0,
        highRevenue: 0,
        currentMonthValue: 0,
        currentYear: 0
      }
      agent.SalesDashboard.getRevenue(this.params)
        .then(response => {
          this.revenueList = response;
          this.DataChartsMapper();
          this.getRevenueSum();
        })
        .catch(() => {
        }).finally(() => {
          this.handleChartSize()
        });
    },
    DataChartsMapper(){
      var currentYear = new Date().getFullYear();

      let lastYearData = Array(12).fill(0);
      let currentYearData = Array(12).fill(0);

      this.revenueList.forEach(item => {
          if (item.year === currentYear) {
              currentYearData[item.month - 1] = item.totalValue;
          } else if (item.year === currentYear - 1) {
              lastYearData[item.month - 1] = item.totalValue;
          }
      });
          
      this.chart.datasets = [
        {
          label: this.$t('salesLegend') + (currentYear).toString(),
          backgroundColor: this.barColorCurrentYear,
          data: currentYearData,
          barPercentage: 0.7,
        },
        {
          label: this.$t('salesLegend') + (currentYear - 1).toString(),
          backgroundColor: this.barColorLastYear,
          data: lastYearData,
          barPercentage: 0.35,
        }
      ];
    },
    getRevenueSum(){
      let currentYear = new Date().getFullYear();
      let currentMonth = new Date().getMonth() + 1;

      this.revenueSum.revenueTotalYear = this.revenueList.filter(bt => bt.year == currentYear).reduce((total, bm) => total + bm.totalValue, 0);

      let maxRevenue = this.revenueList.filter(bt => bt.year == currentYear).sort((a, b) => b.totalValue - a.totalValue)[0];
      this.revenueSum.highRevenueValue = maxRevenue.totalValue;
      this.revenueSum.highRevenue = maxRevenue.month;

      this.revenueSum.currentMonthValue = this.revenueList
        .filter(bt => bt.year == currentYear && bt.month == currentMonth)
        .reduce((total, item) => total + item.totalValue, 0);

      this.revenueSum.currentYear = currentYear;
    },
    getHigherMonthText(){
      var month = this.revenueSum.highRevenue - 1;
      const data = new Date(this.revenueSum.currentYear, month, 1);
      
      return data.toLocaleString('en-US', { month: 'long' });
    }
  },
  components: {
    BarChart,
    RevenueGrowthHelp
  }  
})
