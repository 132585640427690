<template>
  <help>
    <template #title>{{ $t('transactionsTitleTransactionsHelp') }}</template>
    <p>
        {{ $t('transactionsDescriptionTransactionsHelp') }}
    </p>
    <p>
      <ul>
        <li>
          <span v-html="$t('resultTransactionsHelp')"></span>
        </li>
        <li>
          <span v-html="$t('customerTransactionsHelp')"></span>
        </li>
        <li>
          <span v-html="$t('locationTransactionsHelp')"></span>
        </li>
        <li>
          <span v-html="$t('posTransactionsHelp')"></span>
        </li>
        <li>
          <span v-html="$t('amountTransactionsHelp')"></span>
        </li>
        <li>
          <span v-html="$t('marginAmountTransactionsHelp')"></span>
        </li>
        <li>
          <span v-html="$t('marginPercentageTransactionsHelp')"></span>
        </li>
        <li>
          <span v-html="$t('methodTransactionsHelp')"></span>
        </li>
        <li>
          <span v-html="$t('cardTransactionsHelp')"></span>
        </li>
        <li>
          <span v-html="$t('dateTimeTransactionsHelp')"></span>
        </li>
      </ul>
    </p>
    <v-alert class="text-left" dense outlined text type="info" border="left">
      {{ $t('cmvInfoTransactionsHelp') }}
    </v-alert>
    <help-video  help-code="Transactions"/>
  </help>
</template>
<script>
import HelpVideo from '@/components/HelpVideo.vue'

export default {
components: {
    'help-video': HelpVideo
  },
}
</script>
