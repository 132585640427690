export default {
    filtersAndActions: 'Filtros e ações',
    customerTransactions: 'Cliente',
    minimumPriceTransactions: 'Preço mínimo',
    initialDateTransactions: 'Data inicial',
    clearTransactions: 'Limpar',
    updateTransactions: 'Atualizar',
    excelTransactions: 'Excel',
    nfceTransactions: 'NFc-e',
    downloadXmlTransactions: 'Download XMLs',
    downloadExcelTransactions: 'Download Excel',
    reissueInBulk: 'Reemissão em lote',
    invalidateNumbering: 'Inutilizar numeração',
    consultInvalidations: 'Consultar inutilizações',
    showOnlyInvoiceErrors: 'Mostrar apenas erros',
    maxDateTransactions: 'Data final',
    paymentTypeTransactions: 'Método',
    lastCardDigits: 'Últimos Dígitos do Cartão',
    maxTimeTransactions: 'Hora Final',
    minTimeTransactions: 'Hora Inicial',
    noResultsTransactions: 'Sem resultados',
    approvedInvoiceTransactions: 'NFC-e gerada com sucesso, clique para mais detalhes',
    invoiceErrorTransactions: 'Problema ao gerar a NFC-e, clique para mais detalhes',
    notEnabledForPos: 'NFC-e não habilitada para esse PDV',
    acquirerTransactions: 'Adquirente:',
    acquirerCode: 'Cód. adquirente:',
    destinationAccount: 'Conta destino:',
    brandTransactions: 'Bandeira:',
    internalCode: 'Código interno:',
    discountCoupon: 'Cupom de desconto:',
    productCode: 'Código produto',
    productTransactions: 'Produto',
    productCategoryTransactions: 'Categoria produto',
    quantityTransactions: 'Quantidade',
    cmvTransactions: 'CMV({sign})',
    valueTransactions: 'Valor({sign})',
    marginValueTransactions: 'Margem({sign})',
    marginPercentageTransactions: 'Margem(%)',
    selectionTransactions: 'Seleção',
    resultTransactions: 'Resultado',
    locationTransactions: 'Local',
    posTransactions: 'PDV',
    methodTransactions: 'Método',
    cardTransactions: 'Cartão',
    dateTimeTransactions: 'Data/hora',
    todayTransactions: 'Hoje',
    sevenDaysTransactions: 'Sete',
    unknownTransactions: 'Desconhecido',
    dispensingFailure: 'Falha ao dispensar',
    refundedTransactions: 'Estornada',
    refundCancelledTransactions: 'Estorno cancelado',
    refundErrorTransactions: 'Erro ao estornar',
    productNotSelectedTransactions: 'Produto não selecionado',
    selectClientFirstTransactions: 'Selecione antes um cliente',
    transactionLocation: 'Localização',
    maximumPriceTransactions: 'Preço máximo',
    selectLocationTransactions: 'Selecione antes uma localização',
    pointOfSaleTransactions: 'Ponto de venda',
    errorWhenDownloadingXml: 'Não foi possível baixar os XMLs das notas fiscais',
    errorDownloadingReport: 'Não foi possível baixar o relatório das notas fiscais',
    noneTransactions: 'nenhum',
    noDate: 'Sem data',
    failureToCommunicate: 'Falha na comunicação com o sistema emissor',
    errorWhenGeneratingReport: 'Não foi possível gerar o relatório',
    errorWhenGeneratingReportNFCe: 'Não foi possível gerar o relatório da NFC-e',
    cashlessCard:'Cartão: ',
    showProfits: 'Exibir lucros'
  };
