<template>
  <v-container fluid grid-list-lg align-content-start class="pa-5">
    <portal to="toolbarTitle"><dashboard-help></dashboard-help></portal>
    <v-layout row wrap>
      <v-flex xs12 class="pt-0">
        <news v-if="isBrazil"></news>
        <v-expansion-panels hover v-model="expansionIndex">
          <v-expansion-panel :key="1">
            <v-expansion-panel-header>
              <div><v-icon>sort</v-icon>{{ $t('sortSearchTelemetry') }}</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content eager>
              <v-form
                class="px-4 mb-2"
                v-model="valid"
                @submit.prevent="getPointsOfSale(); expansionIndex = undefined;"
              >
                <v-layout row wrap>
                  <v-flex sm12 md4 class="search-field">
                    <v-flex sm12 class="search-field">
                      <v-select
                        prepend-icon="keyboard_arrow_down"
                        v-bind:items="ordenationTypes"
                        v-model="form.sortBy"
                        :label="$t('sortByTelemetry')"
                        single-line
                        hide-details
                      ></v-select>
                    </v-flex>
                  </v-flex>
                  <v-flex sm12 md2 class="search-field">
                    <v-flex sm12 class="search-field">
                      <v-select
                        :prepend-icon="
                          form.sortDesc ? 'arrow_downward' : 'arrow_upward'
                        "
                        v-bind:items="ordenations"
                        v-model="form.sortDesc"
                        single-line
                        hide-details
                      ></v-select>
                    </v-flex>
                  </v-flex>
                  <v-flex sm12 md4 class="search-field">
                    <v-flex sm12 class="search-field">
                      <v-text-field
                        prepend-icon="search"
                        v-model="form.search"
                        single-line
                        clearable
                        hide-details
                        :label="$t('searchTelemetry')"
                        @click:clear="form.search = ''"
                        @change="page = 1"
                      ></v-text-field>
                    </v-flex>
                  </v-flex>
                  <v-btn
                    color="success"
                    class="mt-2"
                    :disabled="!valid"
                    type="submit"
                  >
                    <v-icon left>refresh</v-icon>{{ $t('updateTelemetry') }}
                  </v-btn>
                </v-layout>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
      </v-flex>
      <v-flex sm6 md4 lg3 v-for="pos in pointsOfSale" :key="pos.id" flat>
        <v-card min-height="250px">
          <v-card-title
            class="pb-0 pt-2"
            v-bind:class="getMachineStatusClass(pos)"
            primary-title
          >
            <v-layout row justify-space-between class="card-top-container">
              <v-flex xs10>
                <div class="subtitle-1 ellipsis card-title">
                  <strong>{{ pos.localCustomerName }}</strong>
                </div>
                <div class="info-subtitle">
                  {{ pos.localName }} / {{ pos.specificLocation }}<br />
                  {{ pos.machineMachineModelName }} /
                  {{ pos.machineMachineManufacturerName }}
                </div>
              </v-flex>
              <v-flex xs2 class="top-buttons" v-if="!userIsRemoteMonitoring">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn
                      class="top-btn"
                      outlined
                      v-on="on"
                      x-small
                      max-width="30"
                      height="28"
                      :color="getPlanogramIconColor(pos)"
                      :to="`/${$t('path.namePlanogramMaster')}?pdv=${pos.id}`"
                    >
                      <v-icon>view_comfy</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ getPlanogramDescription(pos) }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn
                      v-on="on"
                      outlined
                      class="top-btn"
                      :style="{
                        visibility: pos.remoteCreditEnabled
                          ? 'visible'
                          : 'hidden',
                      }"
                      x-small
                      max-width="30"
                      height="28"
                      color="grey darken-2"
                      @click.native="remoteCreditClicked(pos.id)"
                      ><v-icon>mdi-cash-usd-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('remoteCreditTelemetry') }}</span>
                </v-tooltip>
              </v-flex>
            </v-layout>
          </v-card-title>
          <v-card-text class="pt-0 pb-0">

            <!-- POS Status Message or external HelpLink -->
            <div :class="'pb-0 mb-0 caption info-subtitle ' + getTextColor(pos)">
              <div v-if="getMachineStatusHelpItem(pos.status)">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <a
                        v-on="on"
                        :href="`https://www.youtube.com/watch?v=${getMachineStatusHelpItem(pos.status).youTubeVideoId}`"
                        target="_blank"
                        :class="'pb-0 mb-0 caption info-subtitle ' + getTextColor(pos)"
                    >
                      <v-icon :class="getTextColor(pos)" style="vertical-align: top" small>mdi-open-in-new</v-icon>
                      {{ getMachineStatus(pos) }}
                    </a>
                  </template>
                  <span>{{ $t('helpTelemetry') }}</span>
                </v-tooltip>
              </div>
              <div v-else>
                {{ getMachineStatus(pos) }}
              </div>
            </div>

            <v-layout row class="mt-0 pt-0">
              <v-flex xs7 class="pt-0">
                <!-- Sales table -->
                <table v-if="pos.salesInfo != null" style="width:100%;">
                  <tr>
                    <th
                      v-for="header in salesTotalsHeaders"
                      :key="header.text"
                      class="text-left caption grey--text sales-table-row"
                    >
                      {{ header.text }}
                    </th>
                  </tr>
                  <tr
                    v-for="item in [
                      {
                        description: $t('salesTodayTelemetry'),
                        info: pos.salesInfo.soldToday,
                        periodo: 'hoje',
                      },
                      {
                        description: $t('salesSevenDaysTelemetry'),
                        info: pos.salesInfo.sold7Days,
                        periodo: 'sete',
                      },
                      {
                        description: $t('salesthirtyDaysTelemetry'),
                        info: pos.salesInfo.sold30Days,
                        periodo: 'trinta',
                      },
                    ]"
                    :key="item.description"
                  >
                    <td
                      class="text-left caption grey--text text--darken-2 sales-table-row"
                    >
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <span v-on="on">
                            <router-link
                              class="grey--text text--darken-2"
                              :to="
                                `/${$t('path.nameTransactions')}?customerId=${pos.localCustomerId}&localId=${pos.localId}&pos=${pos.id}&periodo=${item.periodo}`
                              "
                            >
                              {{ item.description }}
                            </router-link>
                          </span>
                        </template>
                        <span
                          >{{ $t('transactionsTelemetry') }}
                          {{ item.description.toLowerCase() }}</span
                        >
                      </v-tooltip>
                    </td>
                    <td
                      class="text-left caption grey--text text--darken-2 sales-table-row"
                    >
                      {{ item.info.quantity }}
                    </td>
                    <td
                      class="text-left caption grey--text text--darken-2 sales-table-row"
                    >
                      {{ $currencyFormatter.format(item.info.value) }}
                    </td>
                  </tr>
                </table>
              </v-flex>

              <!-- POS status info -->
              <v-flex
                xs5
                class="pt-0 pb-0 caption grey--text pos-info ellipsis"
              >
                <network-icon
                  :strength="pos.currentTelemetry.signalStrength"
                  :type="pos.currentTelemetry.connectionType"
                  :subType="pos.currentTelemetry.connectionSubType"
                  :status="pos.status"
                ></network-icon>
                <div v-if="!userIsRemoteMonitoring">
                <div v-if="pos.inventoryLevel != null">
                  <v-icon small color="grey">mdi-package-variant-closed</v-icon>
                  {{
                    pos.inventoryLevel.totalDesired > 0
                      ? (
                          (pos.inventoryLevel.totalCurrent /
                            pos.inventoryLevel.totalDesired) *
                          100
                        ).toFixed(0)
                      : 0
                  }}{{ $t('stockPercentageTelemetry') }}
                </div>
                <v-tooltip top v-if="pos.inventoryLevel != null">
                  <template #activator="{ on }">
                    <div v-on="on">
                      <v-icon small color="grey">warning</v-icon>
                      {{ pos.inventoryLevel.alertCount }} {{ $t('productsTelemetry') }}
                    </div>
                  </template>
                  <span
                    >{{ pos.inventoryLevel.alertCount }} {{ $t('belowMinimumCriticalTelemetry') }}</span
                  >
                </v-tooltip>
                <v-tooltip
                  top
                  v-if="(pos.salesInfo || {}).lastTransaction != null"
                >
                  <template #activator="{ on }">
                    <div v-on="on">
                      <v-icon small color="grey">shopping_cart</v-icon>
                      {{ $t('thereAreTelemetry') }}
                      {{ $moment(pos.salesInfo.lastTransaction).fromNow(true) }}
                    </div>
                  </template>
                  <span
                    >{{ $t('lastTransactionTelemetry') }}
                    {{
                      $moment(pos.salesInfo.lastTransaction).fromNow(true)
                    }}</span
                  >
                </v-tooltip>
                <v-tooltip
                  top
                  v-if="
                    getStatusLevel(pos) > -6 &&
                      (pos.salesInfo || {}).salesHealth != null
                  "
                >
                  <template #activator="{ on }">
                    <div v-on="on">
                      <v-icon
                        small
                        :color="
                          getSalesHealthColor(pos.salesInfo.salesHealth.rating)
                        "
                      >
                        {{
                          getSalesHealthIcon(pos.salesInfo.salesHealth.rating)
                        }}
                      </v-icon>
                      {{ getSalesHealthText(pos.salesInfo.salesHealth.rating) }}
                    </div>
                  </template>
                  <span
                    >{{ $t('salesHealthTelemetry') }}
                    {{
                      getSalesHealthText(pos.salesInfo.salesHealth.rating)
                    }}</span
                  >
                </v-tooltip>
                </div>
              </v-flex>
            </v-layout>

            <v-layout row justify-space-between>
              <v-flex xs4 class="text-xs-left">
                <v-btn
                  v-if="userIsManager || userIsRemoteMonitoring"
                  class="action-button"
                  icon
                  @click.native="pos.expanded = !pos.expanded"
                >
                  <v-icon>{{
                      pos.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                    }}</v-icon>
                </v-btn>
              </v-flex>

              <v-flex xs8 class="d-flex justify-end">
                <a href="https://youtube.com/shorts/9ZmOwfSirpI" target="_blank">
                  <v-chip
                    v-if="pos.outdatedVersion"
                    color="orange lighten-1"
                    class="white--text full-width-chip"
                    style="width: auto; cursor: pointer"
                  >
                    <v-icon left class="white--text">mdi-alert-circle</v-icon>
                    <div class="text-decoration-underline">{{ $t('outdatedVersion') }}</div>
                  </v-chip>
                </a>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions class="pt-0 expandable-area"> </v-card-actions>
          <v-card-text v-if="pos.expanded">
            <sales-chart
              class="mb-4"
              :pos-id="pos.id"
              :day-offset="0"
              :sales-health="pos.salesInfo.salesHealth"
            ></sales-chart>
            <v-btn
              v-if="!userIsRemoteMonitoring"
              class="my-2 mr-1"
              outlined
              small
              color="grey darken-3"
              :to="`/${$t('path.nameToEditPointOfSale')}/${pos.id}`"
              >{{ $t('toEditTelemetry') }}</v-btn
            >
            <v-btn
              v-if="!userIsRemoteMonitoring"
              class="my-2 mr-1"
              outlined
              small
              color="grey darken-3"
              @click.native="
                supply.posId = pos.id
                supply.enabled = true
              "
              >{{ $t('replenishmentTelemetry') }}</v-btn
            >
            <v-btn
              class="my-2 mr-1"
              outlined
              small
              color="grey darken-3"
              @click.native="
                reboot.posId = pos.boxId,
                reboot.enabled = true
              "
              >{{ $t('restartTerminalTelemetry') }}</v-btn
            >
            <v-btn
              v-if="!userIsRemoteMonitoring"
              class="my-2  mr-1"
              color="grey darken-3"
              outlined
              small
              @click.native="
                push.posId = pos.id
                push.enabled = true
              "
              >{{ $t('pushNotificationTelemetry') }}</v-btn
            >
            <v-btn
              v-if="userIsManager || userIsRemoteMonitoring"
              class="my-2"
              color="grey darken-3"
              outlined
              small
              @click="
              posIdLocks = pos.id
              showLockDialog = true"
              >{{ $t('unlockDoorTelemetry') }}</v-btn
            >

            <div class="grey--text">
              {{ $t('lastUpdateTelemetry') }}
              {{
                $moment(pos.currentTelemetry.date).format('DD/MM/YYYY HH:mm')
              }}
            </div>
            <div class="grey--text">
              {{ $t('posTelemetry') }} {{ pos.id }} {{ $t('codeTelemetry') }} {{ pos.companyCode }}
            </div>
            <div class="grey--text">
              {{ $t('systemTelemetry') }} {{ pos.boxId
              }}{{
                pos.boxMachineInterfaceId !== null
                  ? ` (MMD-0${pos.boxMachineInterfaceId})`
                  : ''
              }}
            </div>
            <div :class="{'grey--text': !pos.outdatedVersion, 'orange--text': pos.outdatedVersion}">
              {{ $t('versionTelemetry') }} {{ pos.currentTelemetry.versionName }}
            </div>
            <div v-if="pos.boxCardReaderId" class="grey--text">
              {{ $t('serialTelemetry') }} {{ pos.boxCardReaderId }}
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
      <remote-credit
        v-model="remoteCreditDialog"
        :pos-id="remoteCreditPosId"
      ></remote-credit>
      <supply v-model="supply.enabled" :pos-id="supply.posId"></supply>
      <push-dialog v-model="push.enabled" :pos-id="push.posId"></push-dialog>
      <reboot-dialog v-model="reboot.enabled" :pos-id="reboot.posId"></reboot-dialog>
      <lock-dialog v-model="showLockDialog" :posId="posIdLocks"></lock-dialog>
    </v-layout>
    <v-pagination
      v-model="page"
      :length="totalPages"
      :total-visible="7"
      @input="getPointsOfSale"
    ></v-pagination>
  </v-container>
</template>

<script>
import NetworkIcon from '@/components/NetworkIcon';
import RemoteCredit from '@/components/RemoteCredit';
import SalesChart from '@/report/SalesChart';
import Supply from '@/components/Supply';
import News from '@/components/News';
import DashboardHelp from '@/dashboard/DashboardHelp';
import PushNotificationDialog from '@/views/PushNotificationDialog';
import LockDialog from '@/components/LockDialog';
import {MANAGER_HIERARCHY, MARKET_HIERARCHY, SUPPLIER_HIERARCHY, REMOTE_MONITORING_ROLE} from '@/constants/Roles';
import RebootDialog from '@/views/RebootDialog';
import { posMachineStatus } from '@/constants/PosMachineStatus'

export default {
  data() {
    return {
      progress: 30,
      pointsOfSale: [],
      telemetryHelpItems: [],
      applicationVersions: [],
      showLockDialog: false,
      valid: false,
      loading: true,
      updateStatusId: null,
      ordenationTypes: [
        { value: 'status', text: this.$t('statusTelemetry') },
        { value: 'local.customer.name', text: this.$t('clientNameTelemetry') },
        { value: 'inventory', text: this.$t('stockTelemetry') },
        { value: 'id', text: this.$t('pointOfSaleTelemetry')},
        { value: 'lastSale', text: this.$t('lastSaleTelemetry') }
      ],
      ordenations: [
        { value: false, text: this.$t('ascendingTelemetry') },
        { value: true, text: this.$t('descendingTelemetry') },
      ],
      form: {
        sortDesc: false,
        sortBy: 'status',
        search: null
      },
      page: 1,
      totalPages: 0,
      itemsPerPage: 24,
      remoteCreditDialog: false,
      remoteCreditPosId: 0,
      supply: {
        posId: null,
        enabled: false,
      },
      push: {
        posId: null,
        enabled: false,
      },
      reboot: {
        posId: null,
        enabled: false,
      },
      posIdLocks: null,
      expansionIndex: 0,
    }
  },
  mounted() {
    this.getApplicationVersionsPerModel()
    this.getPointsOfSale()
    this.getHelpItems()
    this.updateStatusId = setInterval(this.getPointsOfSale, 60000)
  },
  beforeDestroy() {
    clearInterval(this.updateStatusId)
  },
  computed: {
    userIsManager() {
      return MANAGER_HIERARCHY.some(role => this.$auth.check(role));
    },
    userIsSupplier() {
      return SUPPLIER_HIERARCHY.some(role => this.$auth.check(role));
    },
    userIsMarket() {
      return MARKET_HIERARCHY.some(role => this.$auth.check(role));
    },
    userIsRemoteMonitoring() {
      return this.$auth.check(REMOTE_MONITORING_ROLE);
    },
    salesTotalsHeaders(){
      return [
        { text: this.$t('periodTelemetry'), sortable: false },
        { text: this.$t('quantityTelemetry'), sortable: false },
        { text: this.$t('priceTelemetry') + ` [${this.$currencyFormatter.getSign()}]`, sortable: false }
      ];
    },
    isBrazil() {
      return this.$auth.user().tenantCountry == 'BR';
    },
  },

  methods: {
    getPointsOfSale() {
      this.loading = true
      const params = {
        params: {
          page: this.page,
          pageSize: this.itemsPerPage,
          sortOrder: this.form.sortBy,
          descending: this.form.sortDesc,
          search: this.form.search ? this.form.search : null,
        },
      }

      const posQuery = this.axios.get('api/dashboard/pointsOfSale', params)
      let salesQuery = null;
      let inventoryQuery = null;

      posQuery
        .then((response) => {
          this.loading = false
          this.totalPages = response.data.totalPages
          this.pointsOfSale = response.data.items.map((m) => {
            const existingPos =
              this.pointsOfSale.find((p) => p.id === m.id) || {}
            m.expanded = existingPos.expanded
            m.salesInfo = existingPos.salesInfo
            m.inventoryLevel = existingPos.inventoryLevel
            m.outdatedVersion = false
            if (m.currentTelemetry == null) {
              m.currentTelemetry = {}
            }
            return m
          })

          const pointOfSaleIds = this.pointsOfSale.map((p) => p.id)
          let paramsWithPointOfSaleIds = params;
          paramsWithPointOfSaleIds.params.pointOfSaleIds = pointOfSaleIds;

          salesQuery = this.userIsMarket || this.userIsRemoteMonitoring
            ? this.axios.get('api/dashboard/salesInfo', paramsWithPointOfSaleIds)
            : null;

          inventoryQuery = this.userIsSupplier
            ? this.axios.get('api/dashboard/inventoryLevel', paramsWithPointOfSaleIds)
            : null;

          return this.userIsMarket || this.userIsRemoteMonitoring ? salesQuery : inventoryQuery
        })
        .then((response) => {
          response.data.forEach((i) => {
            const h = i.salesHealth
            if (h != null) {
              if (h.currentCount < h.countMean - h.countStdDev) h.rating = 1
              else if (h.currentCount > h.countMean + h.countStdDev)
                h.rating = 3
              else h.rating = 2
            }

            const posIndex = this.pointsOfSale.findIndex((p) => p.id === i.id)
            if (posIndex >= 0) {
              const pos = this.pointsOfSale[posIndex]
              pos.salesInfo = i
              this.$set(this.pointsOfSale, posIndex, pos)
            }
          })
          return inventoryQuery
        })
        .catch(() => {
          return inventoryQuery
        })
        .then((response) => {
          this.pointsOfSale.forEach(pos => this.verifyOutdatedVersion(pos))
          response.data.forEach((i) => {
            const posIndex = this.pointsOfSale.findIndex(
              (p) => p.id === i.pointOfSaleId
            )
            if (posIndex >= 0) {
              const pos = this.pointsOfSale[posIndex]
              pos.inventoryLevel = i
              this.$set(this.pointsOfSale, posIndex, pos)
            }
          })
        })
    },
    getHelpItems() {
      const machineStatus = Object.keys(posMachineStatus).map(item => item.toString());
      this.axios
          .get('api/help/items', {params: {codes: machineStatus}})
          .then((response) => {
            this.telemetryHelpItems = response.data;
          });
    },
    getMachineStatusHelpItem(status) {
      return this.telemetryHelpItems.find(item => item.code === status)
    },
    remoteCreditClicked(posId) {
      this.remoteCreditPosId = posId
      this.remoteCreditDialog = true
    },
    getPlanogramIconColor(pos) {
      if (pos.nextPlanogramId != null) return 'orange lighten-1'
      if (pos.currentPlanogramId != null) return 'green lighten-1'
      return 'grey'
    },
    getPlanogramDescription(pos) {
      if (pos.nextPlanogramId != null) return this.$t('planogramPendingTelemetry')
      if (pos.currentPlanogramId != null) return this.$t('planogramActiveTelemetry')
      return this.$t('noPlanogramTelemetry')
    },
    getTextColor(pos) {
      var s = pos.status
      if (s === 'OK') return 'green--text text--darken-1 font-italic'
      if (
        s === 'NoLauncher' ||
        s === 'NoPower' ||
        s === 'MaybeOutOfService' ||
        s === 'MaybeDisconnected' ||
        s === 'MaybeCardReaderFail'
      ) {
        return 'orange--text text--darken-2 font-italic'
      } else return 'red--text text--darken-2 font-italic'
    },
    getSalesHealthIcon(rating) {
      if (rating < 2) return 'sentiment_very_dissatisfied'
      if (rating > 2) return 'sentiment_very_satisfied'
      return 'sentiment_satisfied'
    },
    getSalesHealthColor(rating) {
      if (rating < 2) return 'red'
      if (rating > 2) return 'blue'
      return 'green'
    },
    getSalesHealthText(rating) {
      if (rating < 2) return this.$t('poorTelemetry')
      if (rating > 2) return this.$t('goodTelemetry')
      return this.$t('averageTelemetry')
    },
    getInactivityDate(pos) {
      const outOfServiceDate = (pos.currentTelemetry || {}).outOfServiceDate
      if (outOfServiceDate != null) return outOfServiceDate
      const telemetryDate = (pos.currentTelemetry || {}).date
      if (this.$moment(telemetryDate) < this.$moment().subtract(1, 'hours'))
        return telemetryDate
      return null
    },
    getOutOfServiceText(pos, reason) {
      const inactivityDate = this.getInactivityDate(pos)
      if (inactivityDate != null) {
        return `${this.$t('thereAreTelemetry')} ${this.$moment(inactivityDate).fromNow(true)}: ${reason}`
      } else {
        return `${this.$t('outOfServiceTelemetry')} ${reason}`
      }
    },
    getMachineStatus(pos) {
      if (pos.status === 'OK') return this.$t('onlineTelemetry')
      if (pos.status === 'NoLauncher') return this.$t('noLauncherTelemetry')
      if (pos.status === 'NoPower')
        return this.$t('noPowerTelemetry')
      if (pos.status === 'MaybeOutOfService')
        return this.$t('maybeOutOfServiceTelemetry')
      if (pos.status === 'MaybeDisconnected') return this.$t('maybeDisconnectedTelemetry')
      if (pos.status === 'MaybeCardReaderFail')
        return this.$t('maybeCardReaderFailTelemetry')
      if (pos.status === 'OutOfService')
        return this.getOutOfServiceText(pos, this.$t('unknownReasonTelemetry'))
      if (pos.status === 'ScreenOff')
        return this.getOutOfServiceText(pos, this.$t('screenOffTelemetry'))
      if (pos.status === 'CardReaderFail')
        return this.getOutOfServiceText(pos, this.$t('cardReaderFailTelemetry'))
      if (pos.status === 'Disconnected')
        return this.getOutOfServiceText(pos, this.$t('disconnectedTelemetry'))
      if (pos.status === 'OutOfServiceNoPower')
        return this.getOutOfServiceText(pos, this.$t('outOfServiceNoPowerTelemetry'))
      if (pos.status === 'OutOfServiceMachine')
        return this.getOutOfServiceText(pos, this.$t('outOfServiceMachineTelemetry'))
      if (pos.status === 'DeadBattery')
        return this.getOutOfServiceText(pos, this.$t('deadBatteryTelemetry'))
    },
    getStatusLevel(pos) {
      if (pos.status === 'OK') return 0
      if (pos.status === 'NoLauncher') return -1
      if (pos.status === 'NoPower') return -2
      if (pos.status === 'MaybeOutOfService') return -3
      if (pos.status === 'MaybeDisconnected') return -4
      if (pos.status === 'MaybeCardReaderFail') return -5
      if (pos.status === 'OutOfService') return -6
      if (pos.status === 'ScreenOff') return -7
      if (pos.status === 'CardReaderFail') return -8
      if (pos.status === 'Disconnected') return -9
      if (pos.status === 'OutOfServiceNoPower') return -10
      if (pos.status === 'OutOfServiceMachine') return -11
      if (pos.status === 'DeadBattery') return -12
      else return -4
    },
    getMachineStatusClass(pos) {
      var s = pos.status
      if (s === 'OK') {
        return 'online-status'
      }
      if (
        s === 'NoLauncher' ||
        s === 'NoPower' ||
        s === 'MaybeOutOfService' ||
        s === 'MaybeDisconnected' ||
        s === 'MaybeCardReaderFail'
      ) {
        return 'medium-status'
      } else {
        return 'offline-status'
      }
    },
    getApplicationVersionsPerModel() {
      this.axios.get(`api/PointsOfSale/applicationVersions`)
        .then((response) => {
          this.applicationVersions = response.data
        })
    },
    verifyOutdatedVersion(pos) {
        if (pos.phoneModel && pos.currentTelemetry.versionName) {
          const version = this.applicationVersions.find(
            (v) => v.modelName === pos.phoneModel
          )
          if (version && version.currentVersion !== pos.currentTelemetry.versionName) {
            pos.outdatedVersion = true
          }
        }
    },
  },
  components: {
    'network-icon': NetworkIcon,
    'remote-credit': RemoteCredit,
    Supply,
    News,
    SalesChart,
    DashboardHelp,
    'push-dialog': PushNotificationDialog,
    'reboot-dialog' : RebootDialog,
    'lock-dialog': LockDialog
  },
}
</script>

<style scoped>
.card-title {
  margin-bottom: -2px;
}
.action-button {
  margin: 0;
  margin-left: -8px;
}
.pos-info {
  margin-top: -2px;
  justify-content: right;
  line-height: 18px !important;
}
.top-buttons {
  text-align: right;
}
.sales-table-row {
  line-height: 14px !important;
}
</style>
