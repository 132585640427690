<template>
  <v-dialog persistent v-model="itemDialog" max-width="960" @keydown.esc="itemDialog = false">
    <template #activator="{ on: dialog }">
      <v-btn :loading="loading.button" class="ml-2" color="success" v-on="dialog">
        <v-icon left>add</v-icon> {{ buttonTitle }} 
      </v-btn>
    </template>

    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="savePromotion">
      <v-card class="pa-5">
        <v-card-text class="pl-5 pr-5">
          <v-card-title class="ma-1 pa-0">
            <span class="title">{{ dialogTitle }}</span>
          </v-card-title>
          <v-row class="d-flex align-center">
            <v-col class="flex-grow-1 flex-shrink-0">
              <v-text-field :label="translations.promotionName" v-model="form.description"
                :rules="[rules.required, rules.nonEmpty]" counter maxlength="30" />
            </v-col>
            <v-col>
              <v-autocomplete :items="types" v-model="form.type" :label="translations.promotionType" required
                @change="changeType" />
            </v-col>
          </v-row>
          <v-row class="d-flex align-center">
            <v-col>
              <v-menu>
                <template v-slot:activator="{ on }">
                  <v-text-field v-on="on" 
                  v-model="formattedExpireDate" 
                  :label="translations.promotionExpiresOn" />
                </template>
                <v-date-picker
                  v-model="form.expiresOn"
                  no-title 
                  scrollable
                  actions
                  locale="pt-br"
                  width="450"
                  :min="new Date().toISOString().slice(0, 10)">
                  <v-card-actions>
                    <v-spacer>
                      <v-btn text color="primary" @click="clearDatePicker">{{translations.clearButton}}</v-btn>
                    </v-spacer>
                  </v-card-actions>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <!-- TODO: Implement a 'starts-on' promotion date -->
              <!-- <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :label="translation.promotionStartsOn" v-model="startDate" v-bind="attrs" v-on="on" />
                </template>
                <v-date-picker v-model="form.startsOn" width="450" no-title scrollable actions locale="pt-br"
                  :change="startDate = formatDate(form.startsOn)" :min="new Date().toISOString().slice(0, 10)" />
              </v-menu> -->
            </v-col>
          </v-row>
          <v-row class="d-flex align-start justify-start">
            <v-col class="flex-grow-0 flex-shrink-0 ma-0 pt-0" cols="4">
              <v-checkbox no-gutters v-model="applyToEveryPointOfSale" :label="translations.pointOfSaleForAllLabel" />
            </v-col>
            <v-col class="flex-grow-0 flex-shrink-0 ma-0 pt-0" cols="4">
              <v-checkbox v-model="applyToSpecificPointsOfSale" :label="translations.pointOfSaleForSpecificLabel" />
            </v-col>
          </v-row>
          <v-row class="d-flex" v-if="applyToSpecificPointsOfSale">
            <v-col class="ma-0 pt-0 pb-0">
              <v-autocomplete v-model="selectedPointsOfSaleIds" item-value="id" item-text="text" multiple small-chips
                :items="collectionOfDiscountPointOfSaleRules" :label="translations.promotionPointOfSaleChooseLabel"
                :loading="loading.pos" :rules="[validatePointOfSales]" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text class="pl-5 pr-5 pa-0 mb-0">
          <v-card-title class="ma-1 pa-0">
            <span class="title">{{ translations.productSectionTitle }}</span>
          </v-card-title>
          <v-row class="px-2">
            <v-col>
              <v-data-table class="pb-4" :headers="headers" :items="collectionOfRulesPerProduct" hide-default-footer
                no-data-text="Não há produtos no combo">
                <template class="search-field" #item.productId="{ item }">
                  <product-discount-filter v-model="item.productId"
                    :rules="[rules.nonEmpty, rules.required, rules.unique]" :label="translations.productLabel"
                    :discount-products="discountProducts" @product-changed="onProductSelected" 
                    @products-searched="onProductSearched" />
                </template>
                <template #item.quantity="{ item }">
                  <v-tooltip :disabled="!item.disabled" bottom open-delay="50">
                    <template #activator="{ on: tooltip }">
                      <div v-on="tooltip">
                        <v-text-field v-model="item.quantity" type="number" min="0" required
                          :label="translations.productItemsLabel" :rules="[rules.integer]" 
                          :disabled="item.disabled"
                          @input="changeItemQuantity(item)"/>
                      </div>
                    </template>
                    {{ translations.chooseAProduct }}
                  </v-tooltip>
                </template>
                <template #item.amount="{ item }">
                  <v-tooltip :disabled="!item.disabled" bottom open-delay="50">
                    <template #activator="{ on: tooltip }">
                      <div v-on="tooltip">
                        <v-text-field v-model="item.amount" type="float" required
                          :label="translations.productDiscountAmountLabel" 
                          :rules="[rules.float, validadeDiscount(item)]"
                          :disabled="item.disabled"
                          @input="changeDiscount(item)"/>
                      </div>
                      </template>
                      {{ translations.chooseAProduct }}
                  </v-tooltip>
                </template>
                <template #item.delete="{ item }">
                  <v-icon @click="removeProduct(item)" color="error">mdi-delete</v-icon>
                </template>
                <template #footer>
                  <v-btn text color="success" class="ml-3 font-weight-bold" @click="addNewProduct()">
                    <v-icon>mdi-plus-circle</v-icon>{{ translations.addAProduct }}
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.native="itemDialog = false">Cancelar</v-btn>
          <v-tooltip top :disabled="valid && collectionOfRulesPerProduct.length != 0">
            <template #activator="{ on }">
              <div v-on="on">
                <v-btn class="ml-2" type="submit" color="primary"
                  :disabled="!valid || collectionOfRulesPerProduct.length == 0" :loading="loading.button">
                  Salvar
                </v-btn>
              </div>
            </template>
            <span v-if="collectionOfRulesPerProduct.length === 0"> {{ translations.errorMessageProductRequired }} </span>
            <span v-else-if="!valid"> {{ translations.errorMissingData }} </span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import agent from '@/api/agent';
import ProductDiscountFilter from '@/discounts/filter/ProductDiscountFilter.vue';
import { DiscountType } from '@/types/DiscountTypes';
const _ = require('lodash');

const translations_root = "promotion";
const translations_path = `${translations_root}.details`;
const productRuleModel = {
  id: 0,
  productId: '0',
  productCode: '',
  productDescription: '',
  productCategory: '',
  productDefaultPrice: 0,
  quantity: 0,
  amount: 0,
  totalPrice: 0,
  finalPrice: 0,
  assigned: false,
  disabled: true,
};

export default {
  props: {
    value: { type: Boolean, required: true },
    editedIndex: { type: Number, required: true },
    promotion: { type: Object, required: true },
    pointsOfSale: { type: Array, required: true }
  },
  mounted() {
    if(this.pointsOfSale) {
      this.mapPointsOfSale(this.pointsOfSale);
    }
    this.mapPromotion();
  },
  data() {
    return {
      valid: false,
      headers: [
        { text: this.$t(`${translations_path}.product.name`), value: 'productId', align: 'left', sortable: false },
        { text: this.$t(`${translations_path}.product.items`), value: 'quantity', align: 'left', sortable: false },
        { text: this.$t(`${translations_path}.discount.amount.label`), value: 'amount', align: 'left', sortable: false },
        { text: this.$t(`${translations_root}.remove.label`), value: 'delete', align: 'left', sortable: false },
      ],
      rules: {
        nonEmpty: (v) => {
          return /\S/.test(v) && v !== '-1' || this.$t('requiredRules')
        },
        required: (v) => !!v && v !== '-1' || this.$t('requiredRules'),
        float: v => (!v || (!isNaN(v) && v >= 0)) || this.$t('floatRules'),
        integer: (v) => /^\d+$/.test(v) || this.$t('integerRules'),
        unique: (v) => {
          if(!v) {
            return true;
          } else if(`${v}` === '-1') {
            return true;
          } else if(`${v}` !== '-1') {
            if (this.collectionOfRulesPerProduct.filter(p => p.productId === `${v}`).length === 1) {
              return true;
            }
          }
          return this.$t('uniqueSelectionRules');
        }
        ,
      },
      form: {
        type: DiscountType.Percentage,
        expiresOn: null,
        description: '',
        discountProductRules: [],
        discountPointOfSaleRules: [],
        usage: 0,
      },
      loading: {
        button: false,
        pointsOfSale: false,
      },
      applyToEveryPointOfSale: true,
      applyToSpecificPointsOfSale: false,
      selectedPointsOfSaleIds: [],

      collectionOfRulesPerProduct: [],
      collectionOfDiscountPointOfSaleRules: [],
      discountProducts: [],
      translations: {
        promotionName: this.$t(`${translations_root}.name`),
        promotionType: this.$t(`${translations_path}.discount.type.text`),
        promotionNew: this.$t(`${translations_path}.new.text`),
        promotionNewError: this.$t(`${translations_path}.new.error`),
        promotionEdit: this.$t(`${translations_path}.edit.text`),
        promotionEditError: this.$t(`${translations_path}.edit.error`),
        promotionTitle: this.$t(`${translations_path}.title`, { description: this.form?.description }),
        promotionStartsOn: this.$t(`${translations_path}.starts-on-label`),
        promotionExpiresOn: this.$t(`${translations_path}.expires-on-label`),
        promotionExpiresOnNoDate: this.$t(`${translations_path}.expires-on-no-date`),
        pointOfSaleForAllLabel: this.$t(`${translations_path}.points-of-sale.all`),
        pointOfSaleForSpecificLabel: this.$t(`${translations_path}.points-of-sale.specific`),
        promotionPointOfSaleChooseLabel: this.$t(`${translations_path}.points-of-sale.choose`),
        pointOfSaleForSpecificError: this.$t(`${translations_path}.points-of-sale.specific-error`),
        productSectionTitle: this.$t(`${translations_path}.product.add`),
        productLabel: this.$t(`${translations_path}.product.name-label`),
        productItemsLabel: this.$t(`${translations_path}.product.items-label`),
        productDiscountAmountLabel: this.$t(`${translations_path}.discount.amount.label`),
        amountPercentage: this.$t(`${translations_path}.discount.amount.percentage`),
        amountFixed: this.$t(`${translations_path}.discount.amount.fixed`),
        discountInvalid: this.$t(`${translations_path}.discount.invalid`),
        addAProduct: this.$t(`${translations_path}.product.button-add`),
        chooseAProduct: this.$t('chooseAProduct'),
        errorMessageProductRequired: this.$t(`${translations_path}.product.error-required`),
        errorMissingData: this.$t(`${translations_path}.missing-data`),
        clearButton: this.$t('componentClearButton'),
      },
    }
  },
  watch: {
    itemDialog(isOpen) {
      if (!isOpen) {
        this.form = {
          type: DiscountType.Percentage,
          expiresOn: null,
          description: "",
          discountProductRules: [],
          discountPointOfSaleRules: []
        };
        this.discountProducts = [];
        this.selectedPointsOfSaleIds = [];
        this.applyToEveryPointOfSale = true;
        this.collectionOfRulesPerProduct = [];
        this.$refs.form.resetValidation();
        this.valid = true;
        return;
      } else if (this.promotion) {
        this.mapPromotion();
      }
    },
    applyToEveryPointOfSale(value) {
      this.applyToSpecificPointsOfSale = !value;
    },
    applyToSpecificPointsOfSale(value) {
      this.applyToEveryPointOfSale = !value;
    },
    'form.type'(value) {
      let headerLabel;
      if (value === DiscountType.Percentage) {
        headerLabel = this.translations.amountPercentage;
      } else {
        headerLabel = `${this.translations.amountFixed} ${this.$currencyFormatter.getSign()}`;
      }
      this.headers[2].text = headerLabel;
    },
    pointsOfSale(value) {
      this.mapPointsOfSale(value);
    },
  },
  computed: {
    itemDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    buttonTitle() {
      return this.editedIndex === -1 ? this.translations.promotionNew : this.translations.promotionEdit;
    },
    dialogTitle() {
      return this.editedIndex === -1 ? this.translations.promotionNew : this.translations.promotionTitle;
    },
    validatePointOfSales() {
      if (this.applyToSpecificPointsOfSale && this.selectedPointsOfSaleIds.length === 0) {
        return this.translations.pointOfSaleForSpecificError;
      }
      return true;
    },
    types() {
      return [{ value: DiscountType.Percentage, text: "%" }, { value: DiscountType.Fixed, text: this.$currencyFormatter.getSign() }]
    },
    formattedExpireDate: function() {
      return this.formatDate(this.form.expiresOn);
    },
    formattedMinDate: function() {
      return this.formatDate(new Date().toISOString());
    },
  },
  methods: {
    mapPointsOfSale(incomingPointsOfSale) {
      if(!incomingPointsOfSale) {
        return;
      }
      let pointsOfSale = [];
      pointsOfSale = _.cloneDeep(incomingPointsOfSale);
      this.collectionOfDiscountPointOfSaleRules = pointsOfSale.map(item => {
        item.text = `(PDV ${item.id})`
          + (item.localCustomerName != null ? ` ${item.localCustomerName}` : '')
          + ` - ${item.localName}`
          + (item.specificLocation != null ? ` - ${item.specificLocation}` : '');
        return item;
      });
    },

    mapPromotion() {
      const promotion = _.cloneDeep(this.promotion);
      if (!Object.values(promotion).length > 0) {
        return;
      }
      const form = {
        ...this.form,
        id: promotion.id,
        type: promotion.type,
        expiresOn: promotion.expiresOn && this.$moment(promotion.expiresOn).utc().format('YYYY-MM-DDTHH:mm:ss') || null,
        description: promotion.description,
        discountProductRules: [...promotion.discountProductRules],
        discountPointOfSaleRules: [...promotion.discountPointOfSaleRules],
        usage: promotion.usage || 0,
      };
      this.form = form;
      
      this.selectedPointsOfSaleIds = form.discountPointOfSaleRules.map(pointOfSaleRule => pointOfSaleRule.pointOfSaleId);
      this.applyToSpecificPointsOfSale = form.discountPointOfSaleRules.length > 0;
      this.applyToEveryPointOfSale = !this.applyToSpecificPointsOfSale;
      this.collectionOfRulesPerProduct = _.cloneDeep(form.discountProductRules);

      form.discountProductRules.forEach(productRule => this.mapProduct(productRule));
      this.discountProducts = this.collectionOfRulesPerProduct.map(productRule => ({
        id: productRule.productId,
        code: productRule.productCode,
        description: productRule.productDescription,
        category: productRule.ProductCategory,
        assigned: true,
      }));
    },
    cancelDialog() {
      this.form = {
        type: DiscountType.Percentage,
        expiresOn: null,
        description: "",
        discountProductRules: [],
        discountPointOfSaleRules: []
      };
      this.discountProducts = [];
      this.selectedPointsOfSaleIds = [];
      this.applyToEveryPointOfSale = true;
      this.collectionOfRulesPerProduct = [];
      this.show = false;
    },
    changeType() {
      this.collectionOfRulesPerProduct.map((item) => this.calculateFinalPrice(item));
    },
    clearDatePicker() {
      this.form.expiresOn = null;
    },
    formatDate(date) {
      return this.$moment(date).utc().format('DD/MM/YYYY');
    },

    mapProduct(product) {
      if (product.productId === null) {
        return;
      }
      
      const productRule = this.collectionOfRulesPerProduct.find(rule => rule.productId === product.productId && product.productId !== '-1');
      product.assigned = product.productId !== '-1';
      if (productRule) {
        product.id = productRule.id;
        productRule.totalPrice = product.productDefaultPrice * product.quantity;
        this.calculateFinalPrice(productRule);
        Object.assign(productRule, product);
      } else {
        this.collectionOfRulesPerProduct.push(product);
      }
    },

    onProductSearched(products) {
      this.discountProducts = [...this.discountProducts, ...products];
    },
    onProductSelected(product) {
      if (product === null || !product.id || !this.rules.unique(product.productId)) {
        return;
      }
      const mapOfProduct = {
        ...productRuleModel,
        productId: `${product.id}`,
        productCode: product.code,
        productDescription: product.description,
        productCategory: product.category,
        productDefaultPrice: product.defaultPrice,
        disabled: false,
      };
      this.mapProduct(mapOfProduct);
    },
    addNewProduct() {
      const collectionLength = this.collectionOfRulesPerProduct.length - 1;
      this.mapProduct({
        ...productRuleModel,
        id: collectionLength >= 0 ? collectionLength + 1 : 0,
        productId: `-1`
      });
    },
    removeProduct(toDelete) {
      this.collectionOfRulesPerProduct = this.collectionOfRulesPerProduct.filter(ruleProduct => ruleProduct !== toDelete);
      this.collectionOfRulesPerProduct.forEach((rule, i) => rule.id = i);
    },

    changeItemQuantity(productRule) {
      productRule.totalPrice = productRule.productDefaultPrice * productRule.quantity;
      this.calculateFinalPrice(productRule);
    },
    changeDiscount(productRule) {
      this.calculateFinalPrice(productRule);
    },
    validadeDiscount(item) {
      let isDiscountValid;
      if (this.form.type === DiscountType.Percentage) {
        isDiscountValid = item.amount <= 100 ? true : this.translations.discountInvalid;
      }
      else {
        isDiscountValid = item.amount <= item.totalPrice ? true : this.translations.discountInvalid;
      }
      return isDiscountValid;
    },
    calculateFinalPrice(product) {
      let finalPrice;
      if (!product.amount) {
        finalPrice = product.productDefaultPrice * product.quantity;
      }
      else if (this.form.type === DiscountType.Fixed) {
        finalPrice = product.totalPrice - product.amount;
      }
      else {
        finalPrice = product.totalPrice * (100 - product.amount) / 100;
      }
      product.finalPrice = finalPrice;
    },

    savePromotion() {
      if (this.$refs.form.validate()) {
        this.loading.button = true;
        const form = _.cloneDeep(this.form);
        delete form.startsOn;
        if(this.form.expiresOn) {
          form.expiresOn = this.$moment(form.expiresOn).utc().format('YYYY-MM-DDTHH:mm:ss');
        }
        this.collectionOfRulesPerProduct = this.collectionOfRulesPerProduct.filter(ruleProduct => ruleProduct.productId !== '-1');
        this.collectionOfRulesPerProduct.forEach((rule, i) => rule.id = i);
        form.discountProductRules = this.collectionOfRulesPerProduct.map((productRule) => {
          const id = form.discountProductRules.find(savedRule => savedRule.productId === productRule.productId)?.id;
          return {
            id: id || 0,
            productId: productRule.productId,
            quantity: Number(productRule.quantity || 0),
            amount: Number(productRule.amount || 0)
          }
        });
        if (this.applyToSpecificPointsOfSale) {
          form.discountPointOfSaleRules = this.selectedPointsOfSaleIds.map((pointOfSaleId) => {
            const pointOfSale = form.discountPointOfSaleRules.find(rule => rule.pointOfSaleId === pointOfSaleId);
            if (pointOfSale !== undefined) {
              return {
                id: pointOfSale.id,
                pointOfSaleId: pointOfSaleId,
                discountBaseId: form.id
              }
            } else {
              return {
                id: 0,
                pointOfSaleId: pointOfSaleId,
                discountBaseId: 0
              }
            }
          });
          this.form.discountPointOfSaleRules = form.discountPointOfSaleRules;
        } else {
          form.discountPointOfSaleRules = [];
          this.form.discountPointOfSaleRules = [];
        }
        const editedIndex = this.editedIndex;
        if (editedIndex === -1) {
          agent.Discounts.create(form)
            .then((response) => {
              this.form.discountProductRules = this.collectionOfRulesPerProduct;
              this.form.id = response.id;
              this.$emit('created', this.form);

              this.discountProducts = [];
              this.collectionOfRulesPerProduct = [];
            })
            .catch((error) => this.$emit('error', this.translations.promotionNewError, error))
            .then(() => this.loading.button = false);
        } else {
          agent.Discounts.update(form.id, form)
            .then(() => {
              this.form.discountProductRules = this.collectionOfRulesPerProduct;
              this.$emit('edited', this.form);

              this.discountProducts = [];
              this.collectionOfRulesPerProduct = [];
            })
            .catch((error) => this.$emit('error', this.translations.promotionEditError, error))
            .finally(() => this.loading.button = false);
        }
      }
    },
  },
  components: { ProductDiscountFilter },
}
</script>

<style></style>