export default {
    editLockLockForm: 'Editar cerradura',
    deviceNameLockForm: 'Nombre del dispositivo',
    deviceNameLockErrorForm: '¡Patrón incorrecto! Ejemplos con el patrón correcto: "TPLockAA00", "MMD-1234"',
    deviceNameHintLockForm: 'El nombre debe ser exactamente igual al del dispositivo Bluetooth',
    lockNameLockForm: 'Nombre de la cerradura',
    lockNameHintLockForm: 'Nombre para identificar la cerradura, por ejemplo: Refrigerador de bebidas, Puerta principal',
    ageRestrictedLockForm: 'Acceso restringido +18',
    ageRestrictedHint: 'Desbloqueable solo por mayores de 18 años',
    closeLockForm: 'Cerrar',
    saveLockForm: 'Guardar',
    registerLockForm: 'Registrar',
    successEditMessage: '¡Cerradura editada exitosamente!',
    successAddMessage: '¡Cerradura registrada!',
    errorMessageEdit: 'No se pudo actualizar el registro de la cerradura',
    errorMessageAdd: 'No se pudo registrar la cerradura',
    addLockLockForm: 'Registrar cerradura',
    deviceUnlockTime: 'Tiempo de apertura del dispositivo',
    deviceUnlockTimeSeconds: '{time} segundos',
    deviceUnlockTimeMinute: '{time} minuto'
}
