<template>
  <div>
    <v-dialog v-model="dialog" width="unset" @keydown.esc="dialog = false">
      <v-card>
        <v-card-title>
          <div class="title">{{ $t('dialogTitleSupply') }}</div>
          <help>
            <template #title>
              {{ $t('helpTitleSupply') }}
            </template>
            <supply-help></supply-help>
          </help>
          <div v-if="pointOfSale != null" class="info-subtitle">
            {{pointOfSale.customerName}}<br>
            {{pointOfSale.localName}} / {{pointOfSale.specificLocation}}<br>
            {{pointOfSale.machineModelName}} / {{pointOfSale.machineManufacturerName}}
          </div>
        </v-card-title>
        <v-progress-linear indeterminate v-if="loading.pos"></v-progress-linear>
        <v-card-text v-if="pointOfSale != null">
          <v-radio-group v-model="supplyType">
            <v-tooltip top :disabled="pointOfSale.hasAnyPickList">
              <span>{{ $t('noPickListTooltipSupply') }}</span>
              <template #activator="{ on }">
                <span v-on="on">
                  <v-radio
                    value="PickList"
                    :disabled="!pointOfSale.hasAnyPickList"
                    :label="$t('supplyPickListLabel')"
                  ></v-radio>
                </span>
              </template>
            </v-tooltip>
            <v-tooltip top :disabled="pointOfSale.targetPlanogramId != null">
              <span>{{ $t('noPlanogramTooltipSupply') }}</span>
              <template #activator="{ on }">
                <span v-on="on">
                  <v-radio 
                    value="Full"
                    :disabled="pointOfSale.targetPlanogramId == null"
                    :label="$t('supplyCompleteLabel') + (pointOfSale.targetPlanogramId != null ? ` (${$t('planogramLabelSupply')} ${pointOfSale.targetPlanogramId})` : '')"
                  ></v-radio>
                </span>
              </template>
            </v-tooltip>
          </v-radio-group>
          <v-select v-if="supplyType === 'PickList'"
            v-model="selectedPickListId"
            :items="pickLists"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click.native="dialog = false">{{ $t('closeLabelSupply') }}</v-btn>
          <v-tooltip open-delay="50" top :disabled="supplyType != null">
            <template #activator="{ on }">
              <span v-on="on">
                <v-btn
                  color="primary" 
                  :disabled="supplyType == null"
                  :loading="loading.supply"
                  @click.native="supplyPos"
                >{{ $t('supplyLabel') }}</v-btn>
              </span>
            </template>
            <span>{{ $t('chooseSupplyTypeTooltip') }}</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <feedback ref="feedback"></feedback>
  </div>
</template>

<script>
import SupplyHelp from '@/components/SupplyHelp'

export default {
  props: {
    value: { type: Boolean, required: true },
    posId: { type: Number, default: null }
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    dialog: function(enabled) {
      if (enabled) {
        this.getPosInfo();
      }
      else {
        this.pointOfSale = null;
        this.supplyType = null;
        this.loading.pos = false;
        this.loading.supply = false;
      }
    },
    posId: function() {
      this.getPosInfo();
    }
  },
  data() {
    return {
      pointOfSale: null,
      supplyType: null,
      loading: {
        pos: true,
        supply: false
      },
      pickLists: [],
      selectedPickListId: null
    }
  },
  methods: {
    getPosInfo() {
      this.loading.pos = true;
      this.axios.get(`api/inventory/${this.posId}/supplyInfo`)
        .then((response) => {
          const pos = response.data;
          pos.hasAnyPickList = pos.currentPickListId != null || pos.secondaryPendingPickListIds.length > 0;
          const pickLists = [];
          if (pos.currentPickListId !== null) pickLists.push({ value: pos.currentPickListId, text: `${this.$t('pickListSupply')} ${pos.currentPickListId} ${this.$t('pickListMainSupply')}` });
          pickLists.push(...pos.secondaryPendingPickListIds.map(id => ({ value: id, text: `${this.$t('pickListSupply')} ${id} ${this.$t('pickListSecondarySupply')}` })));
          this.pointOfSale = pos;
          this.pickLists = pickLists;
          this.selectedPickListId = pos.currentPickListId || (pos.secondaryPendingPickListIds.length > 0 ? pos.secondaryPendingPickListIds[0] : null)
          this.loading.pos = false;
          if (pos.hasAnyPickList) {
            this.supplyType = 'PickList';
          }
          else if (pos.targetPlanogramId != null) {
            this.supplyType = 'Full';
          }
        })
    },
    supplyPos() {
      this.loading.supply = true;
      let url = `api/inventory/${this.posId}/fill/${this.supplyType}`;
      if (this.supplyType === 'PickList') url += `?pickListId=${this.selectedPickListId}`;
      this.axios.put(url)
        .then(() => {
          this.$refs.feedback.handleSuccess(this.$t('feedbackSuccessSupply'));
          this.dialog = false;
        })
        .catch((error) => this.$refs.feedback.handleError(this.$t('feedbackErrorSupply'), error))
        .then(() => this.loading.supply = false);
    }
  },
  components: {
    SupplyHelp
  }
}
</script>