export default {
    resetSelectedPickListDetails: 'Reset selected',
    showZeroPickListDetails: 'Show zeroed',
    searchProductPickListDetails: 'Search product',
    productCodePickListDetails: 'Product code',
    productNamePickListDetails: 'Product Name',
    productBarCodePickListDetails: 'Bar code',
    categoryPickListDetails: 'Category',
    quantityPickListDetails: 'Quantity',
    currentQuantityPickListDetails: 'Qty. current',
    desiredQuantityPickListDetails: 'Qty. Desired',
    criticalMinimumPickListDetails: 'Critical Minimum',
    salesThisWeekPickListDetails: 'Sales this Week',
    centralStockQuantity: 'Qty. Central Stock',
    closePickListDetails: 'Close',
    pickListInfoPickListDetails: 'Pick list ',
    pickListInfoPosDetails: 'POS',
    pickListInfoPlanogramDetails: ' Planogram ',
    pickListInfoCreatedDetails: 'Created: ',
    pickListInfoUpdatedDetails: 'Updated: ',
    changeErrorPickListDetails: 'Error changing quantity',
    quantityNotChangedPickListDetails: 'Quantity not changed: number format is invalid',
    cancelPickListDetails: 'Cancel',
    toSavePickListDetails: 'Save',
    emptySelectedError: 'Error changing zero items',
    pendingPickListDetails: 'Pending',
    usedPickListDetails: 'Used',
};