import { render, staticRenderFns } from "./LossesProductAndPos.vue?vue&type=template&id=3fa08754&scoped=true&"
import script from "./LossesProductAndPos.vue?vue&type=script&lang=ts&"
export * from "./LossesProductAndPos.vue?vue&type=script&lang=ts&"
import style0 from "./LossesProductAndPos.vue?vue&type=style&index=0&id=3fa08754&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fa08754",
  null
  
)

export default component.exports