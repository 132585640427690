
import Vue from 'vue'
import BrazilStates from "@/components/BrazilStates"
import moment from "moment"
import agent from "@/api/agent"
import { ProductSalesInfo } from '@/types/DashboardMarketIntelligence'
import ProductSalesInfoHelp from '../helpers/ProductSalesInfoHelp.vue'
import DateIntervalIntelCardFilter from './DateIntervalIntelCardFilter.vue';
import ProductsBarCodeDropdownVue from '@/components/filters/ProductsBarCodeDropdown.vue'
import i18n from '@/locales/i18n';

export default Vue.extend({
  components: {
    ProductSalesInfoHelp,
    'date-interval-filter': DateIntervalIntelCardFilter,
    'ProductsDropDown': ProductsBarCodeDropdownVue
  },
  data() {
    return {
      params: {
        state: "" as string,
        maxDate: moment().format('YYYY-MM-DD'),
        minDate: moment().add(-30, 'days').format('YYYY-MM-DD'),
        productBarCode: "" as string
      },
      states: BrazilStates.states,
      productSalesInfo: {
        maxPrice: null as null | number,
        minPrice: null as null | number,
        averagePrice: null as null | number,
        ranking: null as null | number,
        quantity: null as null | number
      } as ProductSalesInfo,
      product: {
        description: null as string | null,
        imageUrl: null as string | null,
        defaultPrice: null as number | null
      },
      loading: false,
      validation: false,
      feedbackTextAppend: false
    }
  },
  methods: {
    async getProductSalesInfo() {
      this.feedbackTextAppend = false;
      if (this.params.productBarCode == null || this.params.productBarCode == "") {
        (this.$refs.feedback as any).handleWarning(this.$t('nullBarCodeWarningMessage'))
        this.feedbackTextAppend = true;
        setTimeout(() => this.feedbackTextAppend = false, 6100);
        return
      }
      this.loading = true;
      this.createEmptyInfo()
      await this.getProductDescription()
      await this.getProductPerformance()
      this.loading = false;
    },
    async getProductPerformance(): Promise<void> {
      if ((this.$refs.form as any).validate()) { 
        try {
          const response = await agent.DashboradMarketIntelligence.getProductSalesInfo(this.params)
          this.productSalesInfo = response
        }
        catch(error) {
          if ((error as any).response.status == 404) {
            (this.$refs.feedback as any).handleWarning(this.$t('getProductPerformanceError'))
          }
          else {
            (this.$refs.feedback as any).handleError(this.$t('getProductPerformanceProductSalesError'), error)
          }
        }
      }
    },
    async getProductDescription(): Promise<void> {
      if ((this.$refs.form as any).validate()) {
        const params = { barCode: this.params.productBarCode }
        try {
          const response = await agent.Products.productByBarcode(params);
          this.product.description = response.description;
          this.product.imageUrl = response.imageUrl;
          this.product.defaultPrice = response.defaultPrice;
        }
        catch(error) {
          this.product.description = null
          this.product.imageUrl = null;
          this.product.defaultPrice = null;
          (this.$refs.feedback as any).handleWarning(this.$t('getProductDescriptionError'))
        }
      }
    },
    createEmptyInfo(){
      this.productSalesInfo ={
        maxPrice : null ,
        minPrice :  null,
        averagePrice: null,
        ranking : null,
        quantity: null
      }
    },
    navigateToProduct(){
      const productId = (this.$refs.productDropDown as any).$data.selectedItem.id;
      this.$router.push(`/${i18n.t('path.nameProductsMaster')}?id=${productId}`)
    }
  }
})
