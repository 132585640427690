export default {
    supplyHelpTitle: `Abastecimento`,
    textSupplyHelp: `<p>Nessa tela são exibidos os abastecimentos e controles de inventários realizados no TouchPay.</p>
    <p>A opção de abastecimento por ser acessada de três formas:</p>
    <ul>
     <li><strong>Abastecimento pelo terminal</strong>: A opção de abastecimento pelo terminal é acessada através do menu de administrador do terminal de pagamento. </li>
     <li><strong>Abastecimento pelo aplicativo TouchPay Abastecedor</strong>: O abastecimento pelo aplicativo pode ser realizando quando uma picklist estiver pendente/separada. Nessa opção o usuário pode editar as quantidades e datas de validade, além de realizar a retirada dos itens.</li>
     <li><strong>Abastecimento pela telemetria</strong>: O abastecimento também pode ser realizado pela página de telemetria do sistema web, porém nesse modo não há como editar as quantidades abastecidas, realizar o controle de inventário ou informar a data de validade dos produtos. É possível realizar o abastecimento de uma picklist criada ou o abastecimento completo. No abastecimento completo, serão abastecidos itens o suficiente para completar a quantidade desejada daquele planograma, sempre será a diferença entre a quantidade atual e o valor desejado.</li>
    </ul>
    <p>O abastecimento atualiza o estoque do seu ponto de venda com os produtos abastecidos. É necessário uma pick list para cada abastecimento que não seja completo.</p>
    <p>A opção de inventário por ser acessada de três formas:</p>
    <ul>
    <li><strong>Inventário pelo terminal</strong>: A opção de inventário pelo terminal é acessada através do menu de administrador do terminal de pagamento.</li>
    <li><strong>Inventário pelo aplicativo TouchPay Abastecedor</strong>: O abastecimento pelo aplicativo pode ser realizando quando um PDV possuir um Planograma. Nessa opção o usuário pode editar as quantidades e datas de validade, além de realizar a retirada dos itens.</li>
    <li><strong>Inventário pelo Estoque</strong>: O inventário  também pode ser realizado pela página de estoques do sistema web, porém nesse modo não há como editar a data de validade dos produtos e não temos a referência da quantidade desejada. Essa operação só pode ser realizada por usuários com a permissão gerente ou administrador.</li>
    </ul>`,
}