<template>
  <v-select
    prepend-icon="mdi-list-status"
    :items="operations"
    v-model="operation"
    item-value="value"
    item-text="text"
    label="Tipo Operação"
    hint="Selecione o tipo de operação "
    :multiple="multiple"
    chips
    clearable
  >
    <template #selection="{ item }">
      <v-chip small :color="getColor(item.value)" :dark="isDarkChip(item.value)">{{
        item.text
      }}</v-chip>
    </template>
  </v-select>
</template>

<script>
import { getColorInventoryTransactionStatus } from '@/views/inventory/utility/inventoryUtility.js';
import { inventoryOperationTypes } from '@/constants/InventoryOperationTypes';

export default {
  props: {
    value: { type: Array, default: () => {} },
    multiple: { type: Boolean, default: true }
  },
  computed: {
    operation: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    operations: function() {
      return Object.keys(inventoryOperationTypes)
        .map(k => ({ text: inventoryOperationTypes[k], value: k }));
    }
  },
  data() {
    return {
    }    
  },
  methods: {
    getColor(status) {
      return getColorInventoryTransactionStatus(status)[0];
    },
    isDarkChip(status) {
      return getColorInventoryTransactionStatus(status)[1];
    },    
  }
}
</script>

<style>

</style>