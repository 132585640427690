<template>
  <v-dialog persistent v-model="show" max-width="600" @keydown.esc="show = false">
    <feedback ref="feedback"></feedback>
    <v-form ref="form" lazy-validation v-model="valid" @submit.prevent="saveCoupon">
      <v-card>
        <v-card-title>
          <span class="title">Criar cupom</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 sm6>
                <v-text-field
                  label="Código"
                  v-model="form.code"
                  :rules="[rules.integer]"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6>
                <v-menu 
                  class="pr-2" 
                  close-on-content-click 
                  transition="scale-transition"
                  offset-y 
                  full-width 
                  :nudge-right="40" 
                  max-width="290px" 
                  min-width="290px"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      v-on="on"
                      label="Expira em"
                      :value="date != null ? $moment(date).format('DD/MM/YYYY') : ''" 
                      readonly>
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title scrollable locale="pt-br">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="date = null">Limpar</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-expansion-panels flat hover>
                <v-expansion-panel>
                  <v-expansion-panel-header>Avançado</v-expansion-panel-header>
                  <v-expansion-panel-content eager>
                    <v-layout row wrap>
                      <v-flex xs12 sm6>
                        <v-text-field
                          v-mask="'(##) #####-####'"
                          label="Apenas para o celular"
                          v-model="form.maskedPhone"
                          :rules="[rules.phone]"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click.prevent="show = false">Fechar</v-btn>
          <v-btn type="submit" color="primary" :disabled="!valid" :loading="loading">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
  props: {
    value: { type: Boolean, required: true },
    discount: { type: Object, default: null },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    show: function(enabled) {
      if (enabled) {
        this.valid = true;
			} else {
        this.$refs.form.reset();
        this.form = {};
			}
    },
    date: function(date) {
      this.form.expiresOn = date != null ? this.$moment(date).utc().format('YYYY-MM-DDTHH:mm:ss') : null;
    },
    'form.maskedPhone': function(phone) {
      const code = (phone || '').replace(/\D/g, '');
      this.form.consumerCode = code.length > 0 ? code : null;
    }
  },
  data() {
    return {
      form: {},
      valid: true,
      loading: false,
      date: null,
      rules: {
        integer: (v) => /^\d+$/.test(v) || 'Insira um número inteiro',
        phone: (v) => !v || v.replace(/\D/g, '').length === 11 || 'Insira um celular válido'
      }
    };
  },
  methods: {
    saveCoupon() {
      if (this.$refs.form.validate()) {
        const discount = this.discount;
        this.form.discountId = discount.id;
        this.axios.post('api/discounts/code', this.form)
          .then(response => {
            this.show = false;
            discount.coupons.push(response.data);
          }).catch(error => this.$refs.feedback.handleError("Problema ao criar cupom", error))
          .then(() => this.loading = false);
      }
    }
  },
  directives: { mask }
}
</script>

<style>

</style>