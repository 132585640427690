<template>
  <portal to="toolbarTitle">
    <help>
      <template #title>Tabelas de Preços</template>
      <p>
        Essa página lista as Tabelas de Preços que foram criadas; podendo criar, editar e ativá-las em diversos pontos
        de vendas.
      </p>
      <p>
        <strong>Criar uma tabela de Preços:</strong>
      </p>
      <ul>
        <li>
          Para criar uma tabela de preços, basta clicar em <strong>"+ NOVA TABELA DE PREÇOS"</strong>, adicionar o nome
          de sua tabela e selecionar entre duas maneiras de criação.
          <ul>
            <li>
              <strong>Criar tabela vazia -</strong> Essa maneira apenas cria a Tabela de Preços sem nenhuma informação;
            </li>
            <li>
              <strong>Copiar de planograma -</strong> Ao selecionar essa maneira, abre as abas para selecionar qual o
              Ponto de venda que
              deseja copiar o planograma e qual a sua versão;
            </li>
          </ul>
        </li>
        <li>
          Para adicionar produtos na sua tabela de preços, clique em
          <v-icon color="info" small>mdi-playlist-edit</v-icon>
          e depois clique em
          <strong>"+
            ADICIONAR PREÇO"</strong>e preencher os campos de Produto e Preço e depois clicando em
          <strong>adicionar</strong>.
        </li>
      </ul>
      <br>
      <p>
        <strong>Edição da tabela de preços:</strong>
      </p>
      <ul>
        <li>
          Para editar o nome da tabela de preços, basta clicar no
          <v-icon color="info" small>edit</v-icon>
          e alterar o nome, clicando em
          <strong>salvar</strong>
          para finalizar.
        </li>
        <li>
          Para editar o preço de algum produto, é só clicar em
          <v-icon color="info" small>mdi-playlist-edit</v-icon>
          e alterar o valor no campo
          de preço, ao clicar fora ou navegar usando TAB (no teclado) o valor é <strong>automaticamente salvo</strong>.
        </li>
      </ul>
      <br>
      <p>
        <strong>Ativação da tabela de preços:</strong>
      </p>
      <ul>
        <li>
          Para ativar a tabela de preços, basta clicar em
          <v-icon color="info" small>mdi-domain</v-icon>
          , selecionar os
          pontos de venda que deseja aplicar a tabela de preços e depois clicar em <strong>CRIAR RASCUNHOS</strong>.
        </li>
        <li>
          Após clicar em Prosseguir, será criado um planograma rascunho para os pontos de venda selecionados, lembrando
          que é <strong>necessário</strong> ter o produto no planograma anteriormente para os preços serem aplicados.
        </li>
      </ul>
      <p></p>
      <help-video  help-code="PriceTable"/>
    </help>
  </portal>
</template>

<script>
import HelpVideo from '@/components/HelpVideo.vue'

export default {
components: {
    'help-video': HelpVideo
  },
}
</script>
