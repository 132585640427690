
import Vue from 'vue';
// eslint-disable-next-line no-unused-vars
import {MobileUser} from '@/types/MobileUser';

export default Vue.extend({
  props: {
    isBlocked: { type: Boolean, required: true },
    user: { type: Object, required: true}
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    handleKey(mobileUser: MobileUser) {
        this.loading = true;
        let toastMessage: string
        if(mobileUser.isBlocked) toastMessage = "Desbloqueio"
        else toastMessage = "Bloqueio"

        let mobileUserDto = Object.assign({}, mobileUser);
        mobileUserDto.isBlocked = !mobileUser.isBlocked;


      this.axios.put(`api/MobileUsers/`, mobileUserDto)
        .then(() => {
          this.loading = false;
          this.handleSuccess(`Sucesso ao realizar ${toastMessage} de ${mobileUserDto.fullName}.`);
        }).catch((error) => {
          this.loading = false;
          this.handleError(`Erro ao realizar ${toastMessage}.`, error);
        });
    },
    handleSuccess(message: string) {
      this.$emit('success', message);
      this.user.isBlocked = !this.user.isBlocked;
    },
    handleError(message: string, error: any) {
      this.$emit('error', message, error);
    }    
  }
});
