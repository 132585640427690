<template>
  <v-dialog v-model="dialog" width="unset" @keydown.esc="dialog = false">
    <v-card v-if="operation != null">
      <v-card-title class="blue-grey lighten-5">
        <v-layout row>
          <v-flex md6>
            <div class="title">{{(operation.pointOfSale) ? operation.pointOfSale.customerName : "Estoques"}}</div>
            <div class="info-subtitle">
              PDV {{(operation.pointOfSale) ? operation.pointOfSale.id : "-"}}<br>
              {{(operation.pointOfSale) ? operation.pointOfSale.localName : "-"}} / 
              {{(operation.pointOfSale) ? operation.pointOfSale.specificLocation : "-"}}
            </div>
          </v-flex>
          <v-flex md6 class="text-right">
            <div class="info-subtitle">
              Iniciado em {{ $moment(operation.dateStarted).format('DD/MM/YYYY HH:mm') }}<br>
              Finalizada em {{ $moment(operation.dateCompleted).format('DD/MM/YYYY HH:mm') }}<br>
              Tempo decorrido {{ operation.totalTime.includes(".") ? operation.totalTime.split(".")[0] : operation.totalTime  }} <br>
              Tipo: {{ translateOperationType(operation.type, operation.isBlindOperation) }}
              <span v-if="operation.type != 'Inventory'">
                <br>
                {{ operation.pickListId ? `Pick list ${operation.pickListId}` : 'Abastecimento completo' }}
              </span>
              <span v-if="operation.userEmail">
                <br>
                Usuário: {{ operation.userEmail }}
              </span>
            </div>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        :headers="createHeaders()"
        :items="operation.supplyItems"
        hide-default-footer
        disable-pagination 
      >
        <template #[`item.totalRemoved`]="{ item }">
          <div v-for="(action, index) in item.actions.filter(a => a.variation < 0)" :key="index">
        {{ -action.variation }} ({{ translateRemovalReason(action.reason) }})
          </div>
        </template>
        <template #[`item.productExpirationDate`]="{ value }">
          {{ value ? $moment.utc(value).format('DD/MM/YYYY') : '' }}
        </template>
        <template #[`item.finalQuantity`]="{ item }">
          <strong>{{ item.finalQuantity }}</strong>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          :disabled="operation.isCanceled"
          class="mx-2"
          color="error"
          @click.native="deleteOperation(operation)"
          :loading="loading.undoOperation"
        >
          <v-icon left>mdi-undo-variant</v-icon>Desfazer
        </v-btn>

        <v-btn 
          text color="primary" 
          @click.native="dialog = false"
        >Fechar</v-btn>

      </v-card-actions>
    </v-card>
    <feedback ref="feedback"></feedback>
  </v-dialog>
</template>

<script>
import OperationHelper from '@/views/operations/OperationCommon';
import Feedback from '../../components/Feedback.vue';

export default {
  components: { Feedback },
  props: {
    value: { type: Boolean, required: true },
    operation: { type: Object, default: null }
  },
  data() {
    return {
      loading: {
        undoOperation: false
      }
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    operation(op) {
      if (op != null) {
        op.supplyItems.forEach(item => {
          item.totalRemoved = item.actions.filter(a => a.variation < 0)
            .reduce((acc, action) => acc - action.variation, 0);
            
          if (this.operation.type === 'Inventory') {
            item.finalQuantity = (item.confirmedQuantity !== null ? item.confirmedQuantity : item.previousQuantity) - (item.totalRemoved || 0); 
          } else if (this.operation.type === 'Supply') {
            item.finalQuantity = (item.previousQuantity || 0) + (item.suppliedQuantity || 0) - (item.totalRemoved || 0);              
          } else if (this.operation.type === 'Combined') {
            item.finalQuantity = (item.confirmedQuantity !== null ? item.confirmedQuantity : item.previousQuantity) + (item.suppliedQuantity || 0) - (item.totalRemoved || 0);
          } else {
            item.finalQuantity = item.previousQuantity;
          }
        });
      }
    }
  },
  methods: {
    deleteOperation(operation) {
      let uuid = operation.uuid
      this.loading.undoOperation = true;
      this.axios
        .delete(`api/Inventory/Undo/Operation/${uuid}`)
        .then(() => {
          this.handleSuccess('Operação desfeita');
        }).catch((error) => this.handleError('Erro ao desafazer Operação', error))
          .then(() => {
            this.loading.undoOperation = false;
          });
    },
    handleSuccess(message) {
      this.$refs.feedback.handleSuccess(message);
    },
    handleError(message, error) {
      this.$refs.feedback.handleError(message, error);
    },
    createHeaders() {
      const headers = [
        { text: 'Código produto', align: 'left', sortable: true, value: 'productCode' },
        { text: 'Nome produto', align: 'left', sortable: true, value: 'productDescription' },
        { text: 'Capacidade', align: 'left', sortable: true, value: 'capacity' },
        { text: 'Qtd. anterior', align: 'left', sortable: true, value: 'previousQuantity' },
        { text: 'Data de validade', align: 'left', sortable: true, value: 'productExpirationDate' },
      ]
      if (this.operation.type === 'Inventory' || this.operation.type === 'Combined') {
        headers.push({ text: 'Qtd. confirmada', align: 'left', sortable: true, value: 'confirmedQuantity' });
      }
      if (this.operation.type === 'Supply' || this.operation.type === 'Combined') {
        headers.push({ text: 'A abastecer', align: 'left', sortable: true, value: 'quantityToSupply' });
        headers.push({ text: 'Qtd. abastecida', align: 'left', sortable: true, value: 'suppliedQuantity' });
      }
      if (this.operation.type === 'Picking') {
        headers.push({ text: 'Qtd. separada', align: 'left', sortable: true, value: 'suppliedQuantity' });
        return headers.filter(header => header.value !== 'capacity');
      }
      headers.push({ text: 'Remoções', align: 'left', sortable: true, value: 'totalRemoved' });
      headers.push({ text: 'Qtd. final', align: 'left', sortable: true, value: 'finalQuantity' });
      return headers;
    },
    translateOperationType: OperationHelper.translateOperationType,
    translateRemovalReason: OperationHelper.translateRemovalReason,
  }
}
</script>