export default {
    titlePickListCreationDialog: 'Generate pick lists',
    descriptionLabelPickListCreationDialog: 'Picklist description',
    categoryLabelPickListCreationDialog: 'Filter products by category',
    criticalLabelPickListCreationDialog: 'Include only products with critical stock',
    advancedSectionPickListCreationDialog: 'Advanced',
    secondaryLabelPickListCreationDialog: 'Mark pick-list as secondary',
    groupByCategoryLabelPickListCreationDialog: 'Generate a picklist by category',
    generatePickListsButton: 'Create',
    closeButtonTextPickListCreationDialog: 'Close',
    descriptionTooltipPickListCreationDialog: 'When grouped by category, each picklist will be named after the category name',
    groupByCategoryDisabledTooltip: 'To be able to generate a picklist by category, you must mark it as a secondary pick-list and filter by at least one category',
    pickListCreationError: 'Unable to generate pickList',
    allTheProductsPickList: 'All planogram products',
};