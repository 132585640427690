export default {
    titlePickListCreationDialog: 'Generar listas de selección',
    descriptionLabelPickListCreationDialog: 'Descripción de la lista de selección',
    categoryLabelPickListCreationDialog: 'Filtrar productos por categoría',
    criticalLabelPickListCreationDialog: 'Incluir solo productos con stock crítico',
    advancedSectionPickListCreationDialog: 'Avanzado',
    secondaryLabelPickListCreationDialog: 'Marcar lista de selección como secundaria',
    groupByCategoryLabelPickListCreationDialog: 'Generar una lista de selección por categoría',
    generatePickListsButton: 'Crear',
    closeButtonTextPickListCreationDialog: 'Cerrar',
    descriptionTooltipPickListCreationDialog: 'Cuando se agrupa por categoría, cada lista de selección tendrá como nombre el nombre de la categoría',
    groupByCategoryDisabledTooltip: 'Para poder generar una lista de selección por categoría, debe marcarla como lista de selección secundaria y filtrar por al menos una categoría',
    pickListCreationError: 'No se pudo generar la lista de selección',
    allTheProductsPickList: 'Todos los productos del planograma',
};
