export default {
    alerts: 'Alertas',
    connectivity: 'Conectividade',
    telemetry: 'Telemetria',
    fiscalScenarios: 'Cenários Fiscais',
    discounts: 'Descontos',
    fiscalNotes: 'Notas Fiscais (NFC-e)',
    planograms: 'Planogramas',
    salesReports: 'Relatórios de Vendas',
    dynamicSalesSummary: 'Resumo Dinâmico de Vendas',
    priceTables: 'Tabelas de Preços',
    transactions: 'Transações',
    replenishment: 'Abastecimentos',
    purchases: 'Compras',
    stocks: 'Estoques',
    pickLists: 'Pick Lists',
    products: 'Produtos',
    ruptureReports: 'Relatórios de Rupturas',
    operationalReports: 'Relatórios Operacionais',
    dynamicStockSummary: 'Resumo Dinâmico de Estoque',
    purchaseSuggestions: 'Sugestões de Compras',
    help: 'Ajuda',
    condominiumReports: 'Relatório dos Condomínios',
    dashboard: 'Dashboard',
    downloads: 'Downloads',
    releaseNotes: 'Notas de Versão',
    posRegistration: 'Cadastro PDVs',
    newTouchPay: 'Novo TouchPay',
    privateLabel: 'Private Label',
    users: 'Usuários',
    posView: 'Visão PDV',
    evaluations: 'Avaliações',
    orders: 'Encomendas',
    promotionalLogos: 'Logos Promocionais',
    pushNotifications: 'Notificações Push',
    appUsers: 'Usuários App',
    deviationAlerts: 'Alertas de Desvios',
    audit: 'Auditoria',
    accessControl: 'Controle de Acesso',
    myBills: 'Meus Boletos',
    activations: 'Ativações',
    clients: 'Clientes',
    pagSeguro: 'PagSeguro',
    swagger: 'Swagger',
    feedback: 'Feedbacks',
    missingProducts: 'Produtos não Encontrados',
  };
