export default {
    sortSearchTelemetry: 'Ordenar / Pesquisar',
    sortByTelemetry: 'Ordenar',
    ascendingTelemetry: 'Crescente',
    descendingTelemetry: 'Decrescente',
    searchTelemetry: 'Pesquisar',
    updateTelemetry: 'Atualizar',
    lastUpdateTelemetry: 'Última atualização:',
    planogramPendingTelemetry: 'Planograma pendente de ativação',
    planogramActiveTelemetry: 'Planograma ativo',
    noPlanogramTelemetry: 'Sem planograma cadastrado',
    remoteCreditTelemetry: 'Enviar crédito remoto',
    helpTelemetry: 'Ir para vídeo de ajuda',
    salesTodayTelemetry: 'Hoje',
    salesSevenDaysTelemetry: '7 dias',
    salesthirtyDaysTelemetry: '30 dias',
    transactionsTelemetry: 'Visualizar as transações de',
    lastTransactionTelemetry: 'Última venda há',
    salesHealthTelemetry: 'Desempenho atual de vendas:',
    poorTelemetry: 'Ruim',
    goodTelemetry: 'Ótimo',
    averageTelemetry: 'Na média',
    outOfServiceTelemetry: 'Fora de serviço: ',
    unknownReasonTelemetry: 'motivo desconhecido',
    screenOffTelemetry: 'tela desligada',
    cardReaderFailTelemetry: 'verificar leitor de cartão',
    disconnectedTelemetry: 'sem conexão com a internet',
    outOfServiceNoPowerTelemetry: 'falta de energia',
    outOfServiceMachineTelemetry: 'máquina com avaria',
    deadBatteryTelemetry: 'sem conexão por falta de energia',
    onlineTelemetry: 'Online',
    offlineTelemetry: 'Offline',
    alertTelemetry: 'Alerta',
    noLauncherTelemetry: 'Aplicativo não é launcher padrão',
    noPowerTelemetry: 'Sistema de pagamento não conectado à energia',
    maybeOutOfServiceTelemetry: 'Possível manutenção ou abastecimento da máquina',
    maybeDisconnectedTelemetry: 'Possível queda de conexão',
    maybeCardReaderFailTelemetry: 'Possível problema com o leitor de cartão',
    stockPercentageTelemetry: '% estoque',
    productsTelemetry: ' produtos',
    belowMinimumCriticalTelemetry: ' produtos abaixo do mínimo crítico',
    thereAreTelemetry: 'Há',
    toEditTelemetry: 'Editar',
    replenishmentTelemetry: 'Abastecer',
    restartTerminalTelemetry: 'Reiniciar terminal',
    pushNotificationTelemetry: 'Notificação push',
    unlockDoorTelemetry: 'Destravar uma trava',
    posTelemetry: 'PDV ',
    codeTelemetry: '/ Código',
    systemTelemetry: 'Sistema ',
    versionTelemetry: 'Versão: ',
    serialTelemetry: 'Serial: ',
    periodTelemetry: 'Período',
    quantityTelemetry: 'Qtd.',
    priceTelemetry: 'Valor',
    statusTelemetry: 'Status',
    clientNameTelemetry: 'Nome do cliente',
    stockTelemetry: 'Estoque',
    pointOfSaleTelemetry: 'Data de criação (ID do ponto de venda)',
    lastSaleTelemetry: 'Última venda',
    outdatedVersion: 'Versão desatualizada',
  };


