<template>
  <v-layout wrap>
    <v-flex xs12 class="mx-0">
      <div v-if="!isTokenActivated" class="mt-4">
        <v-alert color="orange lighten-1" dark> 
          <v-icon 
            large
            dark
          >mdi-alert-circle
          </v-icon>
         Esse ambiente não possui a sincronização de transações com a adquirente habilitada. 
         Dessa forma, pode haver transações com resultado divergente do extrato da adquirente. 
         Para habilitar a sincronização de transações, entre em contato com o nosso suporte.
        </v-alert>
      </div>
      <portal to="toolbarTitle"><suspicious-master-help></suspicious-master-help></portal>
        <v-tabs v-model="activeTab" grow background-color="grey lighten-4">
          <v-tab :key="0">Transações suspeitas</v-tab>
          <v-tab :key="1">Acessos suspeitos</v-tab>
          <v-tab :key="2">Alertas de vídeo</v-tab>
          <v-tab-item :key="0">
            <suspicious-transactions
              :active-tab="activeTab"
              @feedbackSent="showFeedbackMessage"
            ></suspicious-transactions>
          </v-tab-item>
          <v-tab-item :key="1">
            <suspicious-access
              :active-tab="activeTab"
              @feedbackSent="showFeedbackMessage"
            ></suspicious-access>
          </v-tab-item>
          <v-tab-item :key="2">
            <video-alerts
              :active-tab="activeTab"
              @feedbackSent="showFeedbackMessage"
            ></video-alerts>
          </v-tab-item>
        </v-tabs>
      <feedback ref="feedback"></feedback>
    </v-flex>
  </v-layout>
</template>

<script>
import VideoAlerts from "./VideoAlerts.vue";
import SuspiciousTransactions from "./SuspiciousTransactions.vue";
import SuspiciousMasterHelp from './helpers/SuspiciousMasterHelp.vue';
import SuspiciousAccess from './SuspiciousAccess.vue';

export default {
    data() {
      return {
        activeTab: 0,
        customers: [],
        isTokenActivated: true
      }
    },
    mounted() {
      this.axios.get("api/Customers/Active").then((response) => {
        this.customers = response.data;
      });

      this.axios.get("api/Suspicious/transaction-sync-status").then((response)=> {
        this.isTokenActivated = response.data.active;
        });
    },
    methods: {
      showFeedbackMessage(newFeedback) {
        if (newFeedback.type == 'error') {
          (typeof newFeedback.content) == 'string'
            ? this.$refs.feedback.handleError(newFeedback.content)
            : this.$refs.feedback.handleError('Erro', newFeedback.content);
        }
        else {
          this.$refs.feedback.handleSuccess(newFeedback.content);
        }
      }
    },
    components: {
        VideoAlerts,
        SuspiciousTransactions,
        SuspiciousMasterHelp,
        SuspiciousAccess,
    },
};
</script>