
import Vue from 'vue';
import InventoriesDropDown from '@/components/filters/InventoriesDropDown.vue';
import { Product, DataRequest, ProductData} from "@/types/Purchases"
import { formatDateTimeToDate } from '@/utility/TextFormatting'
import agent from '@/api/agent';
import DeletePurchaseXmlDialog from './DeletePurchaseXmlDialog.vue';
import i18n from '@/locales/i18n';

export default Vue.extend( {
    props: {
        invoice: {
            type: Object,
            required: true
        },
    },
    components: {
        InventoriesDropDown,
        DeletePurchaseXmlDialog
    },
    data() {
        return {
           headers: [
                { text: i18n.t('detailXmlDialogReceiptCode'), value: 'codeByUser' },
                { text: i18n.t('detailXmlDialogReceiptProduct'), value: 'descriptionByUser' },
                { text: i18n.t('detailXmlDialogReceiptQuantity'), value: 'quantity' },
                { text: i18n.t('detailXmlDialogReceiptPurchaseUnit'), value: 'unitPurchase' },
                { text: i18n.t('detailXmlDialogReceiptTotalUnit'), value: 'totalUnit' },
                { text: i18n.t('detailXmlDialogReceiptUnitPrice'), value: 'unitValue' },
                { text: i18n.t('detailXmlDialogReceiptTotalPrice'), value: 'totalValue' },
                { text: i18n.t('detailXmlDialogReceiptActions'), sortable: false, value: "actions" }
            ],
            confirmDeleteDialog : false as boolean,
        }
    },
    watch: {
        'invoice.inventoryId': function() {
            this.fillAllFields();
        }
    },
    methods: {
        createDataRequest() : DataRequest {
            return {
                nfeNumber: this.invoice.nfeNumber,
                inventoryId: this.invoice.inventoryId,
                dateEmitted: this.invoice.dateEmitted,
                Key: this.invoice.key,
                items: this.mapProducts(this.invoice.products),
            } as DataRequest;
        },

        mapProducts(products: Product[]): ProductData[] {
          return products
            .filter(product => product.quantityByUser != null && product.unitValue != null)
            .map((product) => ({
              productId: product.productId!,
              amount: product.quantityByUser! * product.quantity, 
              unitCost: product.unitValue / product.quantityByUser!,
            }));
        },
        insertPurchase(){
            const request = this.createDataRequest();
            agent.Purchases.createPurchase(request).then(() => {
                localStorage.removeItem('stepInvoice');
                localStorage.removeItem('invoiceImported');
                (this as any).$emit("complete", this.$t('detailXmlDialogReceiptSuccess'));
            }).catch((error : any) => {
                localStorage.removeItem('stepInvoice');
                localStorage.removeItem('invoiceImported');
                (this as any).$emit("error", this.$t('detailXmlDialogReceiptFailure'), error);
            });
        },
        closeConfirmationDialog(value : boolean, deleteOperation : boolean){
            this.confirmDeleteDialog = value;

            if(deleteOperation){
                localStorage.removeItem('stepInvoice');
                localStorage.removeItem('invoiceImported');
                (this as any).$emit("close");
            }
        },
        fillAllFields() : boolean{
            return (this.invoice.inventoryId != null && this.invoice.inventoryId != undefined && this.invoice.inventoryId != '' && this.invoice.products.length > 0);
        },
        unitTypeText(unit : string){
            switch (unit) {
                case "Unit":
                    return this.$t('detailXmlDialogReceiptUnit');
                case "Box":
                    return this.$t('detailXmlDialogReceiptBox');
                case "Package":
                    return this.$t('detailXmlDialogReceiptBundle');
                case "Bag":
                    return this.$t('detailXmlDialogReceiptPackage');
                default:
                    return this.$t('detailXmlDialogReceiptUnknown');
                }
        },
        editProduct(index : number){
            (this as any).$emit("editProduct", index);
        },
        formatDateTimeToDate,
    }
})
