
import Vue from 'vue';
import {formatDateTimeToDate, formatDateTimeToYmdDate} from "@/utility/TextFormatting";

export default Vue.extend({
  props: {
    intervalMaxRange: {type: Number, required: true},
    intervalStartRange: {type: Number, required: true},
    maxMonthsAllowed: {type: Number, required: false},
    resetDate: {type: Boolean, required: false},
    newMinDate: {type: String, required: false},
    newMaxDate: {type: String, required: false},
    minDateLabel: { type: String, default: "Data inicial"},
    maxDateLabel: { type: String, default: "Data final"}
  },
  data() {
    return {
      hasMinDate: null,
      hasMaxDate: null,
      minDate: "",
      maxDate: "",
      thresholdDate: ""
    };
  },
  mounted() {
    if (!this.newMaxDate || !this.newMinDate) {
      this.setDataPickerStartDate();
    }
    this.emitModificationToParent();
  },
  computed: {
    formattedMinDate: function (): string {
      return formatDateTimeToDate(this.minDate, true);
    },
    formattedMaxDate: function (): string {
      return formatDateTimeToDate(this.maxDate, true);
    },
  },
  watch: {
    resetDate(value: boolean) {
      if (value) {
        this.setDataPickerStartDate();
        this.emitModificationToParent();
      }
    },
    newMinDate: {
      handler(value: string) {
        this.minDate = value;
      },
      immediate: true
    },
    newMaxDate: {
      handler(value: string) {
        this.maxDate = value;
      },
      immediate: true
    },
  },
  methods: {
    setDataPickerStartDate() {
      const nowDate = new Date();
      const minDate = new Date(nowDate.getTime());

      minDate.setDate(minDate.getDate() - this.intervalStartRange);

      this.thresholdDate = (this.maxMonthsAllowed) ? formatDateTimeToYmdDate(this.subtractMonths(nowDate.getMonth(), this.maxMonthsAllowed)) : "";
      
      this.maxDate = formatDateTimeToYmdDate(nowDate);
      this.minDate = formatDateTimeToYmdDate(minDate);
    },
    subtractMonths(dateMonths: number, months: number) {
      const date = new Date();
      date.setMonth(dateMonths - months);
      return date;
    },
    clearMinDate() {
      this.minDate = '';
      this.formattedMinDate = '';
    },
    clearMaxDate() {
      this.maxDate = '';
      this.formattedMaxDate = '';
    },
    getAllowedMinDates(minDate: Date) {
      var allowedMinDate = true;
      if (this.maxDate !== "") {
        allowedMinDate = this.isDateRangeAllowed(
            new Date(minDate),
            new Date(this.maxDate)
        );
      }
      else if (this.thresholdDate !== "") {
          allowedMinDate = this.isMinDateRangeAllowed(new Date(minDate));
      }
      return allowedMinDate;
    },
    getAllowedMaxDates(maxDate: Date) {
      var allowedMinDate = true;
      if (this.minDate !== "") {
        allowedMinDate = this.isDateRangeAllowed(
            new Date(this.minDate),
            new Date(maxDate)
        );
      }
      else if (this.thresholdDate !== "") {
          allowedMinDate = this.isMinDateRangeAllowed(new Date(maxDate));
      }
      return allowedMinDate;
    },
    isDateRangeAllowed: function (min: any, max: any): boolean {
      return ((min >= new Date(this.thresholdDate) || this.thresholdDate == "") 
      && max >= min && Math.round(max - min) / (1000 * 60 * 60 * 24) < this.intervalMaxRange);
    },
    isMinDateRangeAllowed: function (min: any): boolean {
      return (min >= new Date(this.thresholdDate));
    },
    emitModificationToParent() {
      this.$emit("intervalDateUpdated", this.minDate, this.maxDate, this.resetDate);
    },
  }
})
;
