
import Vue from 'vue';
// eslint-disable-next-line no-unused-vars
import {PriceTable, PriceTableItem} from '@/types/PriceTable';
import {shouldRefreshData, toApiPagination} from "@/components/TableOptionsComparator";
import {formatDateTimeMinutes} from '@/utility/TextFormatting';
import download from '@/components/Download';
import PriceTableItemCreator from "@/views/priceTables/components/PriceTableItemCreator.vue";
import agent from "@/api/agent";
import PriceTableChangeTypeDialog from "@/views/priceTables/components/PriceTableChangeTypeDialog.vue";
import PriceTableItemsHelp from "@/views/priceTables/helpers/PriceTableItemsHelp.vue";
import PriceTableInventorySelector from "@/views/priceTables/components/PriceTableInventorySelector.vue";
import PriceTableCategoryMarkupSelector from './PriceTableCategoryMarkupSelector.vue';
import i18n from '@/locales/i18n';

export default Vue.extend({
  components: {
    PriceTableInventorySelector,
    PriceTableChangeTypeDialog,
    PriceTableItemCreator,
    PriceTableItemsHelp,
    PriceTableCategoryMarkupSelector
  },
  data: function () {
    return {
      priceTableId: parseInt(this.$route.params.id),
      priceTable: {} as PriceTable,
      priceTableItems: [] as PriceTableItem[] | undefined,
      isMarkupPriceTable: true,
      loading: {
        typeSwitch: false,
        priceTableItems: true,
        products: true,
        excel: false
      },
      inventorySelectorDialog: {
        enabled: false,
      },
      categoryMarkupSelectorDialog: {
        enabled: false,
      },
      changeTypeDialog: {
        enabled: false,
        targetType: '',
      },
      search: {
        valid: true,
        text: '' || null,
      },
      createMode: {
        enable: false,
      },
      products: [],
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['dateUpdated'],
        sortDesc: [true],
        mustSort: true
      },
    };
  },
  mounted() {
    this.getPriceTable();
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getPriceTableItems();
        }
      },
      deep: true,
    }
  },
  computed: {
    filteredHeaders: function (): any {
      return this.priceTable.type === "PriceOnly"
          ? this.headers.filter((header) => header.value !== "cost" && header.value !== 'markup')
          : this.headers;
    },
    headers() {
      return [
        {text: 'Código', align: 'left', value: 'productCode'},
        {text: 'Descrição', align: 'left', value: 'productDescription'},
        {text: 'Código de barra', align: 'left', value: 'productBarCode'},
        {text: 'Categoria', align: 'left', value: 'productCategoryName'},
        {text: `Custo(${(this as any).$currencyFormatter.getSign()})`, align: 'left', value: 'cost', width: 120},
        {text: 'Markup', align: 'left', value: 'markup', width: 120},
        {text: `Preço(${(this as any).$currencyFormatter.getSign()})`, align: 'left', value: 'price', width: 120},
        {text: 'Última Atualização', align: 'left', value: 'dateUpdated'},
        {text: 'Responsável', align: 'left', sortable: false, value: 'userEmail'},
        {text: 'Excluir', align: 'left', sortable: false, value: 'delete'}
      ]
    },
  },
  methods: {
    getPriceTable() {
      agent.PriceTables.details(this.priceTableId)
          .then(response => {
            this.priceTable = response;
            this.isMarkupPriceTable = this.priceTable.type === 'Markup';
            this.getPriceTableItems();
          })
          .catch((error) => {
            this.handleError(`Não foi possível carregar preços, tente novamente`, error);
          });
    },
    getPriceTableItems() {
      this.loading.priceTableItems = true;
      let params = toApiPagination(this.options, this.search.text);
      agent.PriceTables.listItems(this.priceTableId, params)
          .then(response => {
            this.priceTableItems = response.items;
            this.totalItems = response.totalItems;
          })
          .catch((error) => {
            this.handleError(`Não foi possível carregar preços, tente novamente`, error);
          })
          .finally(() => this.loading.priceTableItems = false);
    },
    updateItem(priceTableItem: PriceTableItem) {
      agent.PriceTables.updateItem(this.priceTableId, priceTableItem)
          .then(() => {
            priceTableItem.dateUpdated = new Date();
            priceTableItem.userEmail = (this as any).$auth.user().userEmail;
            this.handleSuccess(`Sucesso ao editar preço de ${priceTableItem.productDescription}.`);
          })
          .catch((error) => {
            this.handleError(`Não foi possível editar preço de ${priceTableItem.productDescription}`, error);
          });
    },
    removeItem(priceTableItem: PriceTableItem) {
      agent.PriceTables.deleteItem(this.priceTableId, priceTableItem.productId!)
          .then(() => {
            const entryIndex = this.priceTableItems!.indexOf(priceTableItem);
            this.priceTableItems!.splice(entryIndex, 1);
            if (this.priceTableItems!.length === 0) {
              this.options.page = 1;
              this.getPriceTableItems();
            } else {
              this.totalItems--;
            }
            this.handleSuccess(`Sucesso ao remover preço de ${priceTableItem.productDescription}.`);
          })
          .catch((error) => {
            this.handleError(`Não foi possível remover preço de ${priceTableItem.productDescription}`, error);
          });
    },
    onConfirmPriceTableTypeChange() {
      this.loading.typeSwitch = true;
      agent.PriceTables.changePriceTableType(this.priceTableId, this.acquireNewPriceTableType())
          .then(() => {
            this.priceTable.type = this.acquireNewPriceTableType();
            this.setItemsToPriceOnly();
            this.isMarkupPriceTable = !this.isMarkupPriceTable;
            this.handleSuccess(`Sucesso ao alterar tipo de tabela de preços`);
          })
          .catch((error) => {
            this.handleError(`Não foi possível alterar tabela de preços`, error);
          })
          .finally(() => this.loading.typeSwitch = false);
    },
    openPriceTableTypeChange() {
      this.changeTypeDialog.targetType = this.acquireNewPriceTableType();
      this.changeTypeDialog.enabled = true;
    },
    acquireNewPriceTableType() {
      return this.isMarkupPriceTable ? "PriceOnly" : "Markup";
    },
    calculatePrice(item: PriceTableItem) {
      if (item.cost && item.markup) {
        let price = item.cost! * item.markup!;
        item.price = this.roundToDecimalPlaces(price, 2);
      }
    },
    calculateMarkup(item: PriceTableItem) {
      if (item.price && item.cost) {
        let markup = item.price / item.cost!;
        item.markup = this.roundToDecimalPlaces(markup, 3);
      }
    },
    roundToDecimalPlaces(number: number, decimalPlaces: number) {
      const powerResult = 10 ** decimalPlaces;
      return Math.round((number + Number.EPSILON) * powerResult) / powerResult;
    },
    setItemsToPriceOnly() {
      this.priceTableItems?.forEach(pti => {
        pti.markup = undefined;
        pti.cost = undefined;
        pti.dateUpdated = new Date();
        pti.userEmail = (this as any).$auth.user().userEmail;
      });
    },
    onSuccessCreatingPrice(message: string) {
      this.handleSuccess(message);
      this.getPriceTableItems();
    },
    onSuccessSync(message: string) {
      this.getPriceTableItems();
      this.handleSuccess(message);
    },
    onSuccessUpdatingMarkup(message: string) {
      this.getPriceTableItems();
      this.handleSuccess(message);
    },
    setCreateMode: function () {
      this.createMode.enable = !this.createMode.enable;
    },
    goToPriceTables: function () {
      this.$router.push('/' + i18n.t('path.namePriceTables'));
    },
    handleSuccess(message: string) {
      (this.$refs.feedback as any).handleSuccess(message);
    },
    handleError(message: string, error: any) {
      (this.$refs.feedback as any).handleError(message, error);
    },
    getExcel: function () {
      this.loading.excel = true;

      const params = {
        sortOrder: this.options.sortBy,
        descending: this.options.sortDesc,
        search: this.search.text
      };
      this.axios
          .get(`api/PriceTables/${this.priceTableId}/Excel`, {
            params: params,
            responseType: 'blob'
          })
          .then((response) => {
            this.loading.excel = false;
            download(response);
          })
          .catch(() => {
            this.loading.excel = false;
          });
    },
    formatDateTimeMinutes,
  },
})
;
