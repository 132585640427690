
import i18n from '@/locales/i18n';
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Array, required: true },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    rules: { type: Array, default: () => [], required: false },
    filter: { type: Function, default: null },
    bold: { type: Boolean, default: false },
    counter: { type: Number, default: null },
    chips: { type: Boolean, default: false },
    shortenChipText: { type: Boolean, default: false },
    rounded: { type: Boolean, default: false },
    label: { type: String, default: i18n.t('pointsOfSale')}
  },
  computed: {
    posValue: {
      get(): number[] {
        return (this.value as number[]);
      },
      set() {}
    },
    computedRules(): ((value: any) => boolean|string)[] {
      let rules = [...this.rules] as ((value: any) => boolean|string)[];

      if(this.counter != null) {
        const maxLengthRule = (): boolean|string => {
          return (this.posValue).length <= this.counter ? true : `Selecione no máximo ${this.counter} Pontos de Venda`
        }
        rules.push(maxLengthRule);
      }

      return rules
    }
  },
  data() {
    return {
      pointsOfSale: [] as any[],
      loading: true,
    }
  },
  mounted() {
    (this.axios as any).get("api/PointsOfSale").then((response: any) => {
      this.loading = false;
      this.pointsOfSale = this.filter == null ? response.data : response.data.filter(this.filter);
      this.pointsOfSale.forEach(pos => {
        pos.searchText = `(PDV ${pos.id}) ${pos.localCustomerName} - ${pos.localName}` + 
          (pos.specificLocation != null ? ` - ${pos.specificLocation}` : '');
      });
    });
  },
})
