export default {
    dialogTitleSupply: 'Abastecimento',
    helpTitleSupply: 'Abastecimento do ponto de venda',
    supplyCompleteLabel: 'Abastecimento completo',
    planogramLabelSupply: 'planograma ',
    supplyPickListLabel: 'Abastecimento por pick list',
    closeLabelSupply: 'Fechar',
    supplyLabel: 'Abastecer',
    chooseSupplyTypeTooltip: 'Uma opção de abastecimento deve ser escolhida',
    noPickListTooltipSupply: 'Não há pick list pendente de abastecimento para esse ponto de venda',
    noPlanogramTooltipSupply: 'Não há planograma ativo ou pendente nesse ponto de venda',
    feedbackSuccessSupply: 'Ponto de venda abastecido!',
    feedbackErrorSupply: 'Erro ao abastecer ponto de venda',
    pickListSupply: 'Pick list ',
    pickListMainSupply: ' (principal)',
    pickListSecondarySupply: ' (secundária)',
  };
  