
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {type: Boolean, required: true},
    finished: {type: Boolean, required: true},
    description: {type: String, required: true},
    name: {type: String, required: true},
  },
  data() {
    return {
      submitting: false
    };
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    finished(value: boolean) {
      if (value) {
        this.submitting = false;
        this.dialog = false;
      }
    },
  },
  methods: {
    deleteItem() {
      this.submitting = true;
      this.$emit("removeItemConfirmation");
    }
  }
});
