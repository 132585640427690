
import {defineComponent} from 'vue'
import EditableImage from "@/components/EditableImage.vue";
import PointOfSalesSelectorDialog from "@/components/common/PointOfSalesSelectorDialog.vue";
import {PointOfSale} from "@/types/PointOfSale";
import {Image} from "@/types/Image";
import agent from "@/api/agent";
import BulkProgressDialog from "@/components/common/BulkProgressDialog.vue";

const requestInitialState = {
  posIds: [] as number[],
  path: null as string | null,
}

export default defineComponent({
  components: {BulkProgressDialog, PointOfSalesSelectorDialog, EditableImage},
  data() {
    return {
      suggestions: [] as Image[],
      loadingSuggestions: true,
      imageUrl: null as string | null,
      imagePath: null as string | null,
      created: false,
      imagePathToApply: null as string | null,
      pointOfSalesSelectorDialog: {
        enabled: false,
        finishActionName: "Atualizar logo"
      },
      request: {...requestInitialState},
      bulkProgressDialog: {
        enabled: false,
        progress: 0,
        infoMessages: []
      },
    }
  },
  mounted() {
    this.getImageSuggestions();
  },
  methods: {
    deleteImage(image: Image) {
      image.deleting = true;
      agent.Images.deleteLogoFromRepository({path: image.path})
        .then(() => {
          this.handleSuccess("Logo excluído com sucesso");
          this.getImageSuggestions();
        })
        .catch(error => {
          image.deleting = false;
          this.handleError("Não foi possível excluir logo", error);
        })
    },
    getImageSuggestions() {
      agent.Images.listSuggestionLogos()
        .then(response => {
          this.suggestions = response.map((image: any) => {
            return {
              path: image.path,
              url: image.url,
              deleting: false
            }
          });
          this.loadingSuggestions = false;
        })
    },
    onPointOfSalesSelection: async function (pointOfSales: PointOfSale[]) {
      this.bulkProgressDialog.progress = 0;
      this.bulkProgressDialog.enabled = true;
      this.request.posIds = pointOfSales.map(pos => pos.id);
      this.request.path = this.imagePathToApply;

      agent.Images.bulkLogoImageChange(this.request)
        .then((response) => {
          this.handleBulkResult(response.results,
            (entityId) => `Logo atualizado para o PDV ${entityId}`,
            (entityId, error) => `Não foi possível atualizar logo para o PDV ${entityId}: ${error}`);
        })
        .finally(() => {
          this.bulkProgressDialog.progress = 100;
        });
    },
    onImageClicked(image: any) {
      this.imageUrl = image.url;
      this.imagePath = image.path;
    },
    openPointOfSaleDialog(imageUrl: string) {
      this.imagePathToApply = imageUrl;
      this.pointOfSalesSelectorDialog.enabled = true;
    },
    handleSuccess(message: string) {
      (this.$refs.feedback as any).handleSuccess(message);
    },
    handleError(message: string, error: any) {
      (this.$refs.feedback as any).handleError(message, error);
    },
    handleBulkResult: function (bulkResult: any, successMessage: (entityId: string) => string, errorMessage: (entityId: string, error: string) => string) {
      this.bulkProgressDialog.infoMessages = bulkResult.map((r: any) => {
        return {
          entityId: r.entityId,
          type: r.success ? 'success' : 'error',
          visible: true,
          text: r.success ? successMessage(r.entityId) : errorMessage(r.entityId, r.error),
          divergentProducts: r.divergentProducts
        };
      });
    },
    cleanDropBox() {
      this.imageUrl = null;
      this.imagePath = null;
    },
    onImageUploaded() {
      this.cleanDropBox();
      this.getImageSuggestions();
    },
    onImageDeleted() {
      this.cleanDropBox();
      this.getImageSuggestions();
    },
  }
})
