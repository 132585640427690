export default {
    filter: 'Filtrar',
    period: 'Período',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha de fin',
    initialHour: 'Hora inicial',
    finalHour: 'Hora final',
    customer: 'Cliente',
    category: 'Categoría',
    statePointOfSale: 'Estado del punto de venta',
    location: 'Ubicación',
    locations: 'Ubicación',
    pointOfSale: 'Punto de venta',
    showZeroedPOS: 'Mostrar PDVs con saldo cero',
    update: 'Actualizar',
    clear: 'Limpiar',
    requiredRules: 'Este campo es obligatorio',
    noData: 'No hay datos disponibles',
    all: 'Todos',
    allF: 'Todas',
    pdv: 'PDV',
    categoryId: 'ID de la categoría',
    productId: 'ID del producto',
    selectALocation: 'Selecciona primero una ubicación', 	
  }
  