export default {
    itemDialogContent: 'Planogram items',
    itemDialogContentDescription: 'A planogram item represents a product at your point of sale, along with some rules associated with it. These rules include:',
    selectionNumberHelp:
        `Selection number: in the case of a machine, this indicates which spring or compartment contains the product.
        For micro-markets, you can indicate the shelf or product position. When you create a new item, the selection is filled in automatically,
        but can be changed. If you don't need to control the selection, as is usually the case for micro-markets,
        it is simpler to keep the value that is already filled in.`,  
    pricerHelp: `Price: the price to be charged. It can be different from the standard price defined in the product register.`,
    maximumCapacityHelp: `Maximum capacity: for vending machines only. Indicates the maximum capacity of this product in this selection.`,
    desiredQuantityHelp:
        `Desired quantity (par level): what is the desired quantity of this product at the time of supply. 
        This quantity is used to generate pick lists, and must be less than or equal to the maximum capacity if it is filled.`,
    criticalMinimumHelp: `Critical minimum: when the stock reaches this number, an alert will be displayed on the telemetry page.`,
    advancedOptionsHelp: `Advanced options (only for micro-markets):`,
    availabilityHelp: `Availability: configures whether the product will be available locally, on order, or both. Orders can be placed via the TouchPay Mobile app.`,
    sponsoredLinkHelp:
        `Sponsored link: can be used when there is a partnership with a supermarket, for example.
        When this field is filled in and the item is configured for ordering, when you click on the order in the app,
        the link is opened in the browser.`,
    selectionLabel: 'Selection',
    priceLabel: 'Price',
    maxCapacityLabel: 'Maximum capacity',
    desiredQuantityLabel: 'Desired quantity (par level)',
    criticalMinimumLabel: 'Critical minimum',
    availabilityLabel: 'Availability',
    sponsoredLinkLabel: 'Sponsored link',
    itemDialogContentClose: 'Close',
    itemDialogContentSave: 'Save',
    errorSavingItem: 'Error saving item',
    successItemCreated: 'Item created successfully',
    successItemUpdated: 'Item updated successfully',
    itemDialogContentLocal: 'Local',
    itemDialogContentOrder: 'Order',
    itemDialogContentLocalAndOrder: 'Local and order',
    itemDialogContentAddItem: 'Add item',
    itemDialogContentChangeItem: 'Change item',
    expandAdvanced: 'Advanced',
  };
  